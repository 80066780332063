import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";
import { ENDPOINTS, GENRES } from "../../../../utils/constants";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { createSlug, momentTime } from "../../../../utils/helpers";
import Loading from "../../../shared/ui/Loading";
import Seo from "../../../shared/ui/Seo";
import Tracks from "./Tracks";

const TrackList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [tracks, setTracks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);


  const getTracks = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.TRACKS;
      if (type) {
        url += `?genre=${type}&is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else {
        url += `?is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`;
        setIsFilterApplied(false); // filtrenin kaldırıldığını belirtiyoruz
      }
      const response = await get(url);
      const newTracks = response.results;

      if (isFilterApplied) {
        setTracks(newTracks);
      } else if (currentPage === 1) {
        setTracks(newTracks);
      } else {
        setTracks((prevData) => [...prevData, ...newTracks]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error('Şarkılar alınırken bir hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    // currentPage değiştiğinde, getAnnouncements() çağırırız
    if (currentPage !== 1) {
      getTracks();
    }
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    getTracks();
  }, [type]);
  return (
    <>
     <Seo 
        title="Şarkılar" 
        description="En güncel müzik ilanları burada. Siz de ilan oluşturabilir veya var olan ilanlara başvurabilirsiniz." 
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Şarkılar - muzisyen.app"
        ogDescription="En güncel müzik ilanlarına buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
     />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">Tüm Şarkılar</h1>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Tür
                </button>
                <div className="dropdown-menu">
                  <Link className="dropdown-item active" to={"/app/sarkilar"}>
                    Tümü
                  </Link>
                  {GENRES.map((item) => (
                    <Link
                      key={item?.name}
                      className="dropdown-item"
                      to={"/app/sarkilar?type=" + item?.name}
                    >
                      {item?.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
           
            <div className="tab-content">
              <div className="tab-pane active" id="track">
                <div className="row item-list item-list-by m-b">
                {tracks?.map((track, index) => (
                    <Tracks key={index} track={track} />
                  ))}
                </div>
                <div className="text-center">
                  {loading ? (
                    <Loading/>
                  ) : (
                    hasMore && (
                      <a onClick={handleLoadMore}>
                        Daha Fazla Göster
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackList;
