import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";
import Seo from "../../../shared/ui/Seo";
import Loading from "../../../shared/ui/Loading";

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const getNews = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.NEWS;
      url += `?limit=${itemsPerPage}&offset=${
        (currentPage - 1) * itemsPerPage
      }&is_published=1&ordering=-create_time`;
      const response = await get(url);
      const newNews = response.results;

      if (currentPage === 1) {
        setNews(newNews);
      } else {
        setNews((prevNews) => [...prevNews, ...newNews]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error("Haberler alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (currentPage !== 1) {
      getNews();
    }
  }, [currentPage]);
  
  useEffect(() => {
    getNews();
  }, []);
  return (
    <>
      <Seo
        title="Müzik haberleri"
        description="En güncel müzik haberlerini takip edin. Siz de haber oluşturabilir katkıda bulunabilirsiniz."
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Müzik haberleri - muzisyen.app"
        ogDescription="En güncel müzik haberlerine buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
      />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">Gündem</h1>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="track">
                <div className="row item-list item-list-by m-b">
                  {news.map((item) => (
                    <div className="col-xs-12" key={item.id}>
                      <div className="item r" data-id={item.id}>
                        <div className="item-media ">
                          <Link
                            to={`/app/haber-detay/${item.id}/${createSlug(
                              item?.title
                            )}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${item?.image?.image}')`,
                            }}
                          ></Link>
                        </div>
                        <div className="item-info">
                          <div className="item-overlay bottom text-right">
                            <LikeButton type="NEWS" id={item.id} />
                          </div>
                          <div className="item-title text-ellipsis">
                            <Link
                              to={`/app/haber-detay/${item.id}/${createSlug(
                                item?.title
                              )}`}
                            >
                              {item.title}
                            </Link>
                          </div>
                          <div className="item-meta-stats text-xs">
                            <Link
                              to={`/app/kullanici-detay/${item?.user?.username}`}
                              className="text-muted"
                            >
                              <i className="fa fa-user text-muted"></i>
                              {" " + item?.user?.username}
                            </Link>
                             
                          
                          </div>
                          <div className="item-meta text-sm text-muted">
                          <span className="item-meta-date text-xs">
                          <i className="fa fa-clock-o text-muted"></i>
                              {" " + new Date(item.create_time).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="item-except visible-list text-sm text-muted h-2x m-t-sm">
                            {item.short_description}
                          </div>
                          {/* <div className="item-action visible-list m-t-sm">
                            <a href="#" className="btn btn-xs white">
                              Edit
                            </a>
                            <a
                              href="#"
                              className="btn btn-xs white"
                              data-toggle="modal"
                              data-target="#delete-modal"
                            >
                              Delete
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  {loading ? (
                    <Loading />
                  ) : (
                    hasMore && <a onClick={handleLoadMore}>Daha Fazla Göster</a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
