import React from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
function MainFooter() {
  return (
    <div className="white dk pos-rlt">
      <div className="p-a-md">
        <div className="footer p-y-md text-primary-hover">
          <div className="row">
            <div className="col-md-3">
              <div className="clearfix m-b-lg">
                {/* brand */}
                <Link to="/" className="navbar-brand md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width={32}
                    height={32}
                  >
                    <circle
                      cx={24}
                      cy={24}
                      r={24}
                      fill="rgba(255,255,255,0.2)"
                    />
                    <circle
                      cx={24}
                      cy={24}
                      r={22}
                      fill="#1c202b"
                      className="brand-color"
                    />
                    <circle cx={24} cy={24} r={10} fill="#ffffff" />
                    <circle
                      cx={13}
                      cy={13}
                      r={2}
                      fill="#ffffff"
                      className="brand-animate"
                    />
                    <path d="M 14 24 L 24 24 L 14 44 Z" fill="#FFFFFF" />
                    <circle cx={24} cy={24} r={3} fill="#000000" />
                  </svg>
                  <img src="/images/logo.png" alt="." className="hide" />
                  <span className="hidden-folded inline">muzisyen.app</span>
                </Link>
                {/* / brand */}
              </div>
            </div>
            <div className="col-sm-2 col-xs-6">
              <h6 className="text-u-c m-b text-muted">Biz Kimiz?</h6>
              <div className="m-b-md">
                <ul className="nav l-h-2x _600">
                  <li className="nav-item">
                    <Link className="nav-link" to={"/hakkimizda"}>
                      Hakkımızda
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/iletisim"}>
                      İletişim
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"https://linkedin.com/muzisyenapp"}
                    >
                      Kariyer
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/kayit-ol">
                      Aramıza katıl
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-2 col-xs-6">
              <h6 className="text-u-c m-b text-muted">Hızlı Menü</h6>
              <div className="m-b-md">
                <ul className="nav l-h-2x _600">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Sıkça Sorulan Sorular
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/iletisim"}>
                      Yardım
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Gizlilik Sözleşmesi
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Kullanım Şartları
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-2 col-xs-6">
              <h6 className="text-u-c m-b text-muted">Takip Et</h6>
              <div className="m-b-md">
                <ul className="nav l-h-2x _600">
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://facebook.com/muzisyenapp">
                      Facebook
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://instagram.com/muzisyen.app">
                      Instagram
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://twitter.com/muzisyenapp" target="_blank">
                      Twitter
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://youtube.com/@muzisyenapp" target="_blank">
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-xs-6">
              <h6 className="text-u-c m-b text-muted">Mobile Uygulamalar</h6>
              <div className="btn-groups">
                <Link
                  to={"https://apps.apple.com/tr/app/muzisyen-app/id6450703048?l=tr"}
                  target="_blank"
                  className="btn btn-sm dark lt m-r-xs"
                  style={{ width: "135px" }}
                >
                  <span className="pull-left m-r-sm">
                    <i className="fa fa-apple fa-2x" />
                  </span>
                  <span className="clear text-left l-h-1x">
                    <span className="text-muted text-xxs">Download on the</span>
                    <b className="block m-b-xs">App Store</b>
                  </span>
                </Link>
                <Link
                  to={"https://play.google.com/store/apps/details?id=app.muzisyen"}
                  target="_blank"
                  className="btn btn-sm dark lt"
                  style={{ width: "133px" }}
                >
                  <span className="pull-left m-r-sm">
                    <i className="fa fa-play fa-2x" />
                  </span>
                  <span className="clear text-left l-h-1x">
                    <span className="text-muted text-xxs">Get it on</span>
                    <b className="block m-b-xs m-r-xs">Google Play</b>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="b b-b m-b m-t-lg" />
          <div className="row">
            <div className="col-sm-8">
              <a href="#" className="text-muted text-sm">
                muzisyen.app
              </a>
            </div>
            <div className="col-sm-4">
              <div className="text-sm-right text-xs-left">
                <small className="text-muted">
                  © Copyright. Tüm hakları saklıdır.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MainFooter;
