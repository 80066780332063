import moment from "moment";
import trLocale from 'moment/locale/tr';

const capitalizeString = (...str) => {

  return str
    .filter(item => item && item.length > 0)
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};

const momentTime = (time) => {
  return moment
    .utc(time)
    .subtract(0, 'hours') //sunucuda test et
    .local()
    .startOf("seconds")
    .fromNow();
};

const createSlug = (str) => {
  const trMap = {
      'ç':'c', 'ğ':'g', 'ş':'s', 'ü':'u', 'ı':'i',
      'ö':'o', 'Ç':'C', 'Ğ':'G', 'Ş':'S', 'Ü':'U',
      'İ':'I', 'Ö':'O', 'â':'a', 'Â':'A'
  };
  for(let key in trMap)
      str = str?.replace(new RegExp(key, 'g'), trMap[key]);
  return str?.toLowerCase()
      .replace(/ /g,'-')
      .replace(/[^\w-]+/g,'');
};

const checkInput = (input) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test(input)) {
    return 'email';
  } else {
    return 'username';
  }
}

export default capitalizeString;
export {momentTime};
export {createSlug};
export {checkInput};

