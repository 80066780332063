import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ENDPOINTS } from "../../../../utils/constants";
import { get, getById } from "../../../../utils/api";
import Comment from "../Shared/Comment";
import LikeButton from "../Shared/LikeButton";
import Seo from "../../../shared/ui/Seo";
import { createSlug } from "../../../../utils/helpers";

const StudioDetail = () => {
  const { id,slug } = useParams();
  const [studio, setStudio] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const getLikeCount = async () => {
    const response = await get(ENDPOINTS.STUDIO_LIKE + "/?studio__id=" + id);
    setLikeCount(response?.count);
  };
  const getStudio = async () => {
    const response = await getById(id, ENDPOINTS.STUDIOS);
    const responseSlug = createSlug(response?.name);
    if(responseSlug !== slug) {
      window.location.href = "/";
    }
    setStudio(response);
  };
  useEffect(() => {
    getStudio();
  }, [id,slug]);
  useEffect(() => {
    getLikeCount();
  }, [likeCount]);
  return (
    <>
    <Seo
        title={studio?.name}
        description={studio?.description}
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle={studio?.name}
        ogDescription={studio?.description}
        twitterCard="summary_large_image"
      />
      <div className="padding b-b">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">Stüdyo</h1>
        </div>
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w r">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{ backgroundImage: `url('${studio?.image?.image}')` }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <div className="page-title">
                {/* {announcement.map((a) => ( */}
                <h1 className="inline">{studio.name}</h1>
                {/* ))} */}
              </div>
              <p className="item-desc text-muted">
              <div
                    dangerouslySetInnerHTML={{ __html: studio.info_desc }}
                  />
               </p>
              <br/>
              <h6 className="item-desc text-muted">Ekipmanlar</h6>
              <p className="item-desc text-muted">
              <div
                    dangerouslySetInnerHTML={{ __html: studio.equipments }}
                  />
              </p>
              <div className="item-action m-b">
                <a
                  className="btn btn-icon white rounded btn-share pull-right"
                  data-toggle="modal"
                  data-target="#share-modal"
                >
                  <i className="fa fa-share-alt" />
                </a>

                <LikeButton type="STUDIO" id={id} onClick={getLikeCount}/>
                <span className="text-muted"> {likeCount}</span>
            
              </div>
              <div className="item-meta">
                <Link to={`/app/studyolar?city=`+studio?.city?.id} className="btn btn-xs rounded white">
                  {studio?.city?.name + " / "+ studio?.town?.name}
                </Link>
                <a href={`tel:${studio.phone}`} className="btn btn-xs rounded white">
                  {studio.phone}
                </a>
                <p className="item-desc text-muted m-t-1">
                  {studio.address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Comment type="STUDIO" id={id} />
    </>
  );
};

export default StudioDetail;
