import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { get } from "../../../utils/api";
import { DEFAULT_AVATAR, DEFAULT_AVATAR2, ENDPOINTS } from "../../../utils/constants";
import { Link } from "react-router-dom";
import { createSlug } from "../../../utils/helpers";

const AutocompleteLoader = () => (
  <ContentLoader
    speed={2}
    width={500}
    height={60}
    viewBox="0 0 500 60"
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
  >
    <rect x="203" y="22" rx="0" ry="0" width="4" height="3" />
    <rect x="15" y="10" rx="0" ry="0" width="71" height="40" />
    <rect x="96" y="20" rx="0" ry="0" width="169" height="8" />
    <rect x="96" y="35" rx="0" ry="0" width="92" height="6" />
  </ContentLoader>
);
function DashboardSearch() {
  const [search, setSearch] = useState("");
  const [result, setResult] = useState(false);
  const [resultNews, setResultNews] = useState(false);
  const [resultEvents, setResultEvents] = useState(false);
  const [resultStudios, setResultStudios] = useState(false);
  const [resultTracks, setResultTracks] = useState(false);
  const [resultProducts, setResultProducts] = useState(false);
  const [resultUsers, setResultUsers] = useState(false);
  const [resultQuestions, setResultQuestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef();

  const isTyping = search.replace(/\s+/, "").length > 2;

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setSearch("");
    }
  };

  const getResultItem = (item) => {
  };

  useEffect(() => {
    if (isTyping) {
      setLoading(true);
      const getData = setTimeout(async () => {
        await get(ENDPOINTS.ANNOUNCEMENTS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResult(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);

      const getNewsData = setTimeout(async () => {
        await get(ENDPOINTS.NEWS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResultNews(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);

      const getEventsData = setTimeout(async () => {
        await get(ENDPOINTS.EVENTS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResultEvents(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);

      const getStudiosData = setTimeout(async () => {
        await get(ENDPOINTS.STUDIOS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResultStudios(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);

      const getTracksData = setTimeout(async () => {
        await get(ENDPOINTS.TRACKS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResultTracks(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);
      const getProducts = setTimeout(async () => {
        await get(ENDPOINTS.PRODUCTS + `?search=${search}&is_published=1`)
          .then((response) => {
            const data = response.results;
            setResultProducts(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);
      const getUsers= setTimeout(async () => {
        await get(ENDPOINTS.USERS + `?search=${search}`)
          .then((response) => {
            const data = response.results;
            setResultUsers(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);
      const getQuestions= setTimeout(async () => {
        await get(ENDPOINTS.QUESTIONS + `?search=${search}`)
          .then((response) => {
            const data = response.results;
            setResultQuestions(data.length > 0 ? data : false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 500);
      return () => {
        clearTimeout(getData);
        clearTimeout(getNewsData);
        clearTimeout(getEventsData);
        clearTimeout(getStudiosData);
        clearTimeout(getTracksData);
        clearTimeout(getProducts);
        clearTimeout(getUsers);
        clearTimeout(getQuestions);
        setLoading(false);
      };
    } else {
      setResult(false);
    }
  }, [search]);

  return (
    <div
      className="modal white lt fade"
      id="search-modal"
      data-backdrop="false"
    >
      <a
        data-dismiss="modal"
        className="text-muted text-lg p-x modal-close-btn"
      >
        ×
      </a>
      <div className="row-col">
        <div className="p-a-lg h-v row-cell v-m">
          <div className="row">
            <div className="col-md-8 offset-md-2">
       
              <form className="m-b-md">
              <h5 className="text-center text-muted">Arama Yap</h5>
                <div className="input-group input-group-lg">
                  <input
                    type="text"
                    value={search}
                    className={
                      isTyping ? "form-control typing" : "form-control"
                    }
                    placeholder="Bir şeyler yazın.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
              {isTyping && (
                <div id="search-result" className="animated fadeIn">
                  {loading &&
                    new Array(5).fill().map(() => <AutocompleteLoader />)}
                  <p className="m-b-md">
                    <strong>"{search}" </strong>
                    <span className="text-muted">için bulunan sonuçlar: </span>
                  </p>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {result && loading === false && (
                          <h3 className="text-ellipsis">Bulunan İlanlar</h3>
                        )}
                        {result &&
                          loading === false &&
                          result.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/ilan-detay/${item?.id}/${createSlug(item?.title)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.image?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/ilan-detay/${item?.id}/${createSlug(item?.title)}`}>
                                      {item?.title}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <a
                                      href="artist.detail.html"
                                      className="text-muted text-ellipsis"
                                    >
                                      {item?.short_description}
                                    </a>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!result && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili ilan bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultNews && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Haberler</h3>
                        )}

                        {resultNews &&
                          loading === false &&
                          resultNews.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/haber-detay/${item?.id}/${createSlug(item?.title)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.image?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/haber-detay/${item?.id}/${createSlug(item?.title)}`}>
                                      {item?.title}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/haber-detay/${item?.id}/${createSlug(item?.title)}`}>
                                      {item?.short_description}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultNews && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili haber bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultEvents && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Etkinlikler</h3>
                        )}

                        {resultEvents &&
                          loading === false &&
                          resultEvents.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/etkinlik-detay/${item?.id}/${createSlug(item?.title)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.image?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/etkinlik-detay/${item?.id}/${createSlug(item?.title)}`}>
                                      {item?.title}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/etkinlik-detay/${item?.id}/${createSlug(item?.title)}`}>
                                      {item?.short_description}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultEvents && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili etkinlik bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultStudios && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Stüdyolar</h3>
                        )}
                        {resultStudios &&
                          loading === false &&
                          resultStudios.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/studyo-detay/${item?.id}/${createSlug(item?.name)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.image?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/studyo-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.name}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/studyo-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.info_desc}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultStudios && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili stüdyo bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultTracks && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Şarkılar</h3>
                        )}
                        {resultTracks &&
                          loading === false &&
                          resultTracks.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/sarki-detay/${item?.id}/${createSlug(item?.name)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.cover?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/sarki-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.name}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/sarki-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.description}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultTracks && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili şarkı bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultProducts && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Ürünler</h3>
                        )}
                        {resultProducts &&
                          loading === false &&
                          resultProducts.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/urun-detay/${item?.id}/${createSlug(item?.name)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.image?.image}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/urun-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.name}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/urun-detay/${item?.id}/${createSlug(item?.name)}`}>
                                      {item?.short_description}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultProducts && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili ürün bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultUsers && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Müzisyenler</h3>
                        )}
                        {resultUsers &&
                          loading === false &&
                          resultUsers.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/kullanici-detay/${item?.username}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${item?.avatar?.image || DEFAULT_AVATAR}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link  to={`/app/kullanici-detay/${item?.username}`}>
                                      {item?.first_name} {item?.last_name} ({item?.username})
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link  to={`/app/kullanici-detay/${item?.username}`}>
                                      {item?.biography}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultUsers && loading === false && (
                        <div className="result-not-found">
                          "{search}" adında bir müzisyen bulamadık!
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="row item-list item-list-sm item-list-by m-b">
                        {resultQuestions && loading === false && (
                          <h3 className="text-ellipsis">Bulunan Sorular</h3>
                        )}
                        {resultQuestions &&
                          loading === false &&
                          resultQuestions.map((item) => (
                            <div className="col-xs-12" key={item?.id}>
                              <div className="item r" data-id="item-5">
                                <div className="item-media ">
                                  <Link
                                    to={`/app/soru-cevap/${item?.id}/${createSlug(item?.subject)}`}
                                    className="item-media-content"
                                    style={{
                                      backgroundImage: `url('${DEFAULT_AVATAR2}')`,
                                    }}
                                  />
                                </div>
                                <div className="item-info">
                                  <div className="item-title text-ellipsis">
                                    <Link to={`/app/soru-cevap/${item?.id}/${createSlug(item?.subject)}`}>
                                      {item?.subject}
                                    </Link>
                                  </div>
                                  <div className="item-author text-sm text-ellipsis ">
                                    <Link to={`/app/soru-cevap/${item?.id}/${createSlug(item?.subject)}`}>
                                      {item?.question_text}
                                    </Link>
                                  </div>
                                  <div className="item-meta text-sm text-muted"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {!resultQuestions && loading === false && (
                        <div className="result-not-found">
                          "{search}" ile ilgili soru bulamadık!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* <div id="top-search" className="btn-groups">
                <strong className="text-muted">Top searches: </strong>
                <a href="#" className="btn btn-xs white">
                  Happy
                </a>
                <a href="#" className="btn btn-xs white">
                  Music
                </a>
                <a href="#" className="btn btn-xs white">
                  Weekend
                </a>
                <a href="#" className="btn btn-xs white">
                  Summer
                </a>
                <a href="#" className="btn btn-xs white">
                  Holiday
                </a>
                <a href="#" className="btn btn-xs white">
                  Blue
                </a>
                <a href="#" className="btn btn-xs white">
                  Soul
                </a>
                <a href="#" className="btn btn-xs white">
                  Calm
                </a>
                <a href="#" className="btn btn-xs white">
                  Nice
                </a>
                <a href="#" className="btn btn-xs white">
                  Home
                </a>
                <a href="#" className="btn btn-xs white">
                  SLeep
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DashboardSearch;
