import { useRoutes } from "react-router-dom/dist";
import Routes from "./Routes";
import { Toaster } from "react-hot-toast";
import GoogleAnalytics from "./components/shared/ui/GoogleAnalytics";
import { HelmetProvider, Helmet } from "react-helmet-async";

function App() {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <GoogleAnalytics />
        <Helmet>
          <title>muzisyen.app - Türkiye'nin en büyük müzisyen platformu</title>
          <meta name="description" content="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!" />
        </Helmet>
        {useRoutes(Routes)}
        <Toaster />
      </HelmetProvider>
    </>
  );
  
}

export default App;
