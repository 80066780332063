import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { createSlug, momentTime } from "../../../../utils/helpers";
import Loading from "../../../shared/ui/Loading";
import Seo from "../../../shared/ui/Seo";
import { fetchCities } from "../../../../services/shareService";

const Announcement = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const city = queryParams.get("city");
  const [announcementTypes, setAnnouncementType] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cities, setCities] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const getCities = async () => {
    const response = await fetchCities();
    setCities(response);
  };
  const getAnnouncementTypes = async () => {
    const response = await get(ENDPOINTS.ANNOUNCEMENT_TYPE);
    setAnnouncementType(response.results);
  };

  const getAnnouncements = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.ANNOUNCEMENTS;
      if (type) {
        url += `?announcement_type__id=${type}&is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else if (city) {
        url += `?user__city=${city}&is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else {
        url += `?is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`;
        setIsFilterApplied(false); // filtrenin kaldırıldığını belirtiyoruz
      }
      const response = await get(url);
      const newAnnouncements = response.results;

      if (isFilterApplied) {
        setAnnouncements(newAnnouncements);
      } else if (currentPage === 1) {
        setAnnouncements(newAnnouncements);
      } else {
        setAnnouncements((prevAnnouncements) => [...prevAnnouncements, ...newAnnouncements]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error('İlanlar alınırken bir hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    // currentPage değiştiğinde, getAnnouncements() çağırırız
    if (currentPage !== 1) {
      getAnnouncements();
    }
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    getAnnouncements();
    getAnnouncementTypes();
    getCities();
  }, [type, city]);
  return (
    <>
     <Seo 
        title="İlanlar" 
        description="En güncel müzik ilanları burada. Siz de ilan oluşturabilir veya var olan ilanlara başvurabilirsiniz." 
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Müzik İlanları - muzisyen.app"
        ogDescription="En güncel müzik ilanlarına buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
     />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">İlanlar</h1>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  İlan Tipi
                </button>
                <div className="dropdown-menu">
                  <Link className="dropdown-item active" to={"/app/ilanlar"}>
                    Tümü
                  </Link>
                  {announcementTypes.map((announcementType) => (
                    <Link
                      key={announcementType.id}
                      className="dropdown-item"
                      to={"/app/ilanlar?type=" + announcementType.id}
                    >
                      {announcementType.title}
                    </Link>
                  ))}
                </div>
              </div>
            <div className="dropdown inline" >
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Şehir
                </button>
                <div className="dropdown-menu" style={{overflowY:"scroll", maxHeight:500}}>
                  <Link className="dropdown-item active" to={"/app/ilanlar"}>
                    Tümü
                  </Link>
                  {cities.map((item) => (
                    <Link
                      key={item.id}
                      className="dropdown-item"
                      to={"/app/ilanlar?city=" + item.id}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="track">
                <div className="row item-list item-list-by m-b">
                  {announcements.map((announcement, index) => (
                    <div className="col-xs-12" key={index}>
                      <div className="item r" data-id={announcement.id}>
                        <div className="item-media ">
                          <Link
                            to={`/app/ilan-detay/${announcement.id}/${createSlug(announcement?.title)}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${announcement?.image?.image}')`,
                            }}
                          ></Link>
                        </div>
                        <div className="item-info">
                          <div className="item-overlay bottom text-right">
                            <LikeButton
                              id={announcement.id}
                              type="ANNOUNCEMENT"
                            />
                          </div>
                          <div className="item-title text-ellipsis">
                            <Link to={`/app/ilan-detay/${announcement.id}/${createSlug(announcement?.title)}`}>
                              {announcement.title}
                            </Link>
                          </div>

                          <div className="item-meta text-sm text-muted">
                            <span className="item-meta-category">
                              <Link
                                to={`/app/ilanlar?type=${announcement?.announcement_type?.id}`}
                                className="label"
                              >
                                {announcement?.announcement_type?.title}
                              </Link>
                            </span>
                            <span className="item-meta-date text-xs">
                              {" " + momentTime(announcement.create_time)}
                            </span>
                          </div>
                          <div className="item-meta-stats text-xs">
                            <Link
                              to={`/app/kullanici-detay/${announcement?.user?.username}`}
                              className="text-muted"
                            >
                              <i className="fa fa-user text-muted"></i>
                              {" " + announcement?.user?.username}
                            </Link>
                          </div>
                          <div className="item-except visible-list text-sm text-muted h-2x m-t-sm">
                            {announcement.short_description}
                          </div>
                          {/* <div className="item-action visible-list m-t-sm">
                            <a href="#" className="btn btn-xs white">
                              Edit
                            </a>
                            <a
                              href="#"
                              className="btn btn-xs white"
                              data-toggle="modal"
                              data-target="#delete-modal"
                            >
                              Delete
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  {loading ? (
                    <Loading/>
                  ) : (
                    hasMore && (
                      <a onClick={handleLoadMore}>
                        Daha Fazla Göster
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcement;
