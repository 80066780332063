export const COLORS = {
  PRIMARY: "#007bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#28a745",
  WARNING: "#ffc107",
  DANGER: "#dc3545",
  LIGHT: "#f8f9fa",
  DARK: "#343a40",
};

export const BASE_URL = process.env.REACT_APP_API_URL;

export const ROUTES_URL = {
  LOGIN: "/giris-yap",
  SIGNUP: "/kayit-ol",
  FORGOT_PASSWORD: "/sifremi-unuttum",
  RESET_PASSWORD: "/sifre-sifirla/:reset_token",
  VERIFY_EMAIL: "/email-dogrula/:token",
  ABOUT: "/hakkimizda",
  CONTACT: "/iletisim",
  DASHBOARD: "/app",
  ANNOUNCEMENTS: "ilanlar/:type?/:city?",
  TRACKS: "sarkilar/:type?",
  STUDIOS: "studyolar",
  STUDIO_DETAIL: "studyo-detay/:id/:slug?",
  ANNOUNCEMENT_DETAIL: "ilan-detay/:id/:slug?",
  ANNOUNCEMENT_ADD: "ilan-ekle",
  TRACK_ADD: "sarki-ekle",
  TRACK_DETAIL: "sarki-detay/:id/:slug?",
  EVENTS: "etkinlikler",
  PRODUCTS: "urunler",
  PRODUCT_ADD: "urun-ekle",
  PRODUCT_DETAIL: "urun-detay/:id/:slug?",
  EVENT_DETAIL: "etkinlik-detay/:id/:slug?",
  QA: "soru-cevap",
  QA_DETAIL: "soru-cevap/:id/:slug?",
  PROFILE: "hesabim",
  USER_DETAIL: "kullanici-detay/:username",
  USER_LIST: "muzisyenler",
  USER_MESSAGES:"mesajlarim",
  NEWS: "haberler",
  NEWS_ADD: "haber-ekle",
  NEWS_DETAIL: "haber-detay/:id/:slug?",
  PRIVACY_POLICY: "/gizlilik-politikasi",
  TERMS_OF_USE: "/kullanim-sartlari",
  SITEMAP:"/sitemap.xml",
};

export const ENDPOINTS = {
  USERS: "/users/",
  STUDIOS: "/studios",
  IMAGES: "/images",
  VIDEOS: "/videos",
  AUDIOS: "/audios",
  ANNOUNCEMENTS: "/announcement",
  ANNOUNCEMENT_TYPE: "/announcement-type",
  TRACKS: "/tracks",
  LOGIN: "/login",
  SIGNUP: "/signup",
  CITY: "/city",
  TOWN: "/town",
  NEWS: "/news",
  EVENTS: "/events",
  PRODUCTS: "/products",
  PRODUCT_CATEGORY: "/product-category",
  PRODUCT_LIKE: "/product-like",
  COMMENT_PRODUCT: "/comment-product",
  COMMENT_STUDIO: "/comment-studio",
  COMMENT_ANNOUNCEMENT: "/comment-announcement",
  COMMENT_TRACK: "/comment-track",
  COMMENT_NEWS: "/comment-news",
  STUDIO_LIKE: "/studio-like",
  ANNOUNCEMENT_LIKE: "/announcement-like",
  TRACK_LIKE: "/track-like",
  TRACK_LIKE_BEST: "/track-like/mostliked",
  NEWS_LIKE: "/news-like",
  USER_MESSAGE: "/message",
  CONTACT: "/contact",
  INSTRUMENTS: "/instruments/",
  GENRES: "/genres/",
  EXPERIENCES: "/user-experience/",
  EQUIPMENTS: "/equipments/",
  QUESTIONS: "/questions",
  ANSWERS: "/question-answers",
  QUESTION_CATEGORY: "/question-category",
  DOWNLOADER: "/downloader",
  // ve diğer API endpointleri...
};
export const SIDEBAR_TYPES = {
  TRACKS: ENDPOINTS.TRACKS,
  ANNOUNCEMENTS: ENDPOINTS.ANNOUNCEMENTS,
  STUDIOS: ENDPOINTS.STUDIOS,
  STUDIO_DETAIL: ENDPOINTS.STUDIOS + "/",
  ANNOUNCEMENT_DETAIL: ENDPOINTS.ANNOUNCEMENTS + "/",
  TRACK_DETAIL: ENDPOINTS.TRACKS + "/",
};
export const COMMENT_TYPES = {
  STUDIO: ENDPOINTS.COMMENT_STUDIO,
  ANNOUNCEMENT: ENDPOINTS.COMMENT_ANNOUNCEMENT,
  TRACKS: ENDPOINTS.COMMENT_TRACK,
  NEWS: ENDPOINTS.COMMENT_NEWS,
  PRODUCTS: ENDPOINTS.COMMENT_PRODUCT,
};
export const LIKE_TYPES = {
  STUDIO: ENDPOINTS.STUDIO_LIKE,
  ANNOUNCEMENT: ENDPOINTS.ANNOUNCEMENT_LIKE,
  TRACKS: ENDPOINTS.TRACK_LIKE,
  NEWS: ENDPOINTS.NEWS_LIKE,
  PRODUCTS: ENDPOINTS.PRODUCT_LIKE,
};
export const GENRES = [
  { id: 1, name: "Pop" },
  { id: 2, name: "Rock" },
  { id: 3, name: "Jazz" },
  { id: 4, name: "Blues" },
  { id: 5, name: "Hip Hop" },
  { id: 6, name: "Electronic" },
  { id: 7, name: "Country" },
  { id: 8, name: "R&B" },
  { id: 9, name: "Reggae" },
  { id: 10, name: "Classical" },
  { id: 11, name: "Metal" },
  { id: 12, name: "Folk" },
  { id: 13, name: "Latin" },
  { id: 14, name: "World" },
  { id: 15, name: "Other" },
  { id: 16, name: "Türkü" },
  { id: 17, name: "Accoustic" },
];
export const DEFAULT_AVATAR = "/images/a0.jpg";
export const DEFAULT_BACKGROUND = "/images/bgdefault.jpg";
export const DEFAULT_AVATAR2 = "/images/default.png";
export const DEFAULT_CHAT_AVATAR = "/images/avatar.png";
export const PUBLIC_KEY = "69mUIWBk6xp1Pq7xlVWz66RQMGTB63qRlpWDoFgo";
export const SECRET_KEY =
  "Jt20jj7Ri5Z5EImuEch3PEFX9Zfdr2dM2K7pvuT7NJ3ncFodZsqlPOfF7UiPswrY3zGAF7Un6er7XmhRrNasJVwk4m1NPkaabuF6vt1mSTTvJkFu1WNcbdqQyQgaa2fq";
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_UPLOAD_SIZE = 5242880; // 5 MB
export const RECAPTCHA_SITE_KEY = "6LeEP9IlAAAAAAJuqJjcNyr5uuxZEfMc6Ww_rPi-";
export const RECAPTCHA_SECRET_KEY = "6LeEP9IlAAAAANUx2qfLcdRiZtQbOsB9z-aN2AjI";
export default BASE_URL;
