import React from "react";

const VideoPlayer = ({source}) => {
  return (
    <div className="myvideo">
      <video src={source} autoPlay loop muted />
    </div>
  );
};

export default VideoPlayer;
