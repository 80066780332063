import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: 'STUDIOS',
}

const bestSidebarSlice = createSlice({
  name: "bestSidebar",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export default bestSidebarSlice.reducer;
export const { setType } = bestSidebarSlice.actions;
