import React from "react";
import Seo from "../../shared/ui/Seo";
function About() {
  return (
    <>
    <Seo title="Hakkımızda" description="Türkiye'nin en büyük müzik platformu muzisyen.app sitesinde sizde istediklerinizi bulabilirsiniz."/>
      <div className="row-col">
        <div className="col-sm-6 black lt">
          <div
            className="black cover cover-gd"
            style={{
              backgroundImage: 'url("/images/b7.jpg")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="p-a-lg text-center">
              <h3 className="display-3 m-y-lg">Hakkımızda</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row-col dark-white">
        <div className="col-md-2" />
        <div className="col-md-8">
          <div className="p-a-lg ">
            <h3 className="display-4 m-y-lg text-center">Biz Kimiz?</h3>
            <p className="btn-outline b-black">
              <p>Merhaba,</p>
              <p>
                M&uuml;zisyenler i&ccedil;in &ouml;zel ders ilanları, haberler
                ve duyurular paylaşılan bir web sitesi olarak hizmet veriyoruz.
                Amacımız, m&uuml;zik tutkunlarını bir araya getirmek,
                m&uuml;zisyenleri desteklemek ve m&uuml;zik
                sekt&ouml;r&uuml;ndeki gelişmeleri takip etmek i&ccedil;in bir
                platform sunmaktır.
              </p>
              <p>
                Web sitemiz, farklı m&uuml;zik t&uuml;rleri, enstr&uuml;manlar
                ve seviyelerde m&uuml;zik eğitimi almak isteyenler i&ccedil;in
                &ouml;zel ders ilanları paylaşmaktadır. Bu ilanlar, deneyimli
                m&uuml;zisyenlerin &ouml;ğretmenlik hizmetlerini sunduğu ve
                m&uuml;zik tutkunlarının kendilerini geliştirebilecekleri
                fırsatlar sunmaktadır.
              </p>
              <p>
                Ayrıca, m&uuml;zik d&uuml;nyasında yaşanan son gelişmeleri takip
                ederek, m&uuml;zisyenlerin ve m&uuml;zik severlerin bu
                değişimler hakkında bilgi sahibi olmalarına yardımcı oluyoruz.
                Konserler, alb&uuml;m &ccedil;ıkışları, m&uuml;zik
                &ouml;d&uuml;lleri ve daha bir&ccedil;ok konuda haberler ve
                duyurular paylaşıyoruz.
              </p>
              <p>
                M&uuml;zisyenlerin kendilerini tanıtabilecekleri bir platform da
                sunuyoruz. Bu sayede m&uuml;zisyenler, kendi şarkılarını
                paylaşabilirler, kendilerini tanıtabilirler ve hayranlarına
                doğrudan ulaşabilirler.
              </p>
              <p>
                Web sitemizde yer alan i&ccedil;eriklerin kalitesini ve
                g&uuml;ncelliğini korumak i&ccedil;in &ouml;zen
                g&ouml;steriyoruz. Sitemiz, m&uuml;zik tutkunlarının ve
                m&uuml;zisyenlerin bir araya geldiği, paylaşımlarını ve
                bilgilerini paylaştığı, g&uuml;venli bir platformdur.
              </p>
              <p>
                Siz de m&uuml;zik tutkunuysanız, m&uuml;zik sekt&ouml;r&uuml;nde
                bir yer edinmek istiyorsanız veya m&uuml;zik &ouml;ğrenmek
                istiyorsanız, web sitemize g&ouml;z atabilirsiniz. Her
                t&uuml;rden m&uuml;zikseverin ilgisini &ccedil;ekecek
                i&ccedil;erikler sunuyoruz.
              </p>
              <p>Saygılarımızla,</p>
              <p>muzisyen.app</p>
            </p>
          </div>
        </div>
        <div className="col-md-2" />
      </div>
      </>
  );
}
export default About;
