import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import LikeButton from "../../app/Shared/LikeButton";
import ActionButton from "../../app/Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";

const ProfileNews = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [news, setNews] = useState([]);
  const getNews = async () => {
    const response = await get(
      ENDPOINTS.NEWS + "?user__id=" + currentUser.id
    );
    setNews(response.results);
  };
  useEffect(() => {
    getNews();
  }, []);
  return (
    <>
      <div className="row m-b">
        {news.length > 0 ? ( news.map((item, index) => (
          <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
            <div className="item r" data-id="item-2" data-src="">
              <div className="item-media ">
                <Link
                  to={`/app/haber-detay/${item.id}/${createSlug(item?.title)}`}
                  className="item-media-content"
                  style={{ backgroundImage: `url('${item?.image?.image}')` }}
                />
              </div>
              <div className="item-info">
              <div className="item-overlay bottom text-right">
                   <ActionButton endpoint={ENDPOINTS.NEWS} id={item?.id} />
                  </div>
                <div className="item-title text-ellipsis">
                  <Link to={`/app/haber-detay/${item.id}/${createSlug(item?.title)}`}>
                    {item.title}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis hide">
                <Link to={`/app/haber-detay/${item.id}/${createSlug(item?.title)}`} className="text-muted">
                    {item.title}
                  </Link>
                </div>
                <div className="item-meta text-sm text-muted">
                    <span className="item-meta-stats text-xs ">
                      <i className="fa fa-user text-muted" />{" "}
                      {item?.user?.username} -{" "}
                      {item?.is_published === true ? (
                        <label className="label green">Yayınlanmış</label>
                      ) : (
                        <label className="label danger">Yayınlanmamış</label>
                      )}
                    </span>
                  </div>
                <div className="item-author text-sm text-ellipsis">
                  {item?.short_description}
                </div>
                
              </div>
            </div>
          </div>
        ))) : (<p className="text-muted">Hiç haber girilmemiş</p>)}
      </div>
    </>
  );
};

export default ProfileNews;
