import React, { useState } from "react";
import Seo from "../../shared/ui/Seo";
import ReCAPTCHA from "react-google-recaptcha";
import { ENDPOINTS, RECAPTCHA_SITE_KEY } from "../../../utils/constants";
import { post, postContact } from "../../../utils/api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Contact() {
  const [isVerified, setIsVerified] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const recaptchaRef = React.useRef();

  const handleRecaptchaVerify = (token) => {
    //todo: you do it make server side after
    setIsVerified(true);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleFormSubmit = async (e) => {
    setLoading(true);
    setIsVerified(false);
    e.preventDefault();
    if (isVerified) {
      const data = {
        name: name,
        from_email: email,
        message: message,
        subject: subject,
      };
      const response = await postContact(ENDPOINTS.CONTACT + "/", data);
      if (response?.status === 200) {
        setLoading(false);
        setIsVerified(true);
        navigate("/iletisim");
        toast.success("Mesajınız başarıyla gönderildi.");
        setName("");
        setEmail("");
        setMessage("");
        setSubject("");
        recaptchaRef.current.reset();
      }
    }
  };
  const handleRecaptchaExpire = () => {
    setIsVerified(false);
  };
  return (
    <>
      <Seo
        title="İletişim"
        description="Bizimle hemen iletişime geç! Fikirlerin bizim için önemli!"
      />
      <div className="row-col">
        <div className="col-sm-6 black lt">
          <div
            className="black cover cover-gd"
            style={{
              backgroundImage: 'url("/images/b7.jpg")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="p-a-lg text-center">
              <h3 className="display-3 m-y-lg">İletisim</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <h3 className="heading mb-4">Bize ulaşın</h3>
                  <p>
                    Fikirleriniz bizim için çok önemli, lütfen bize ulaşın. Her
                    türlü soru, öneri ve görüşlerinizi bekliyoruz.
                    <br />
                    <b>Email:</b>{" "}
                    <a href="mailto:info@muzisyen.app">info@muzisyen.app</a>
                  </p>
                  <p>
                    <img
                      src="/images/undraw-contact.svg"
                      alt="Image"
                      className="img-fluid"
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <h3 className="heading mb-4">İletişim Formu</h3>
                  <form className="mb-5" id="contactForm" name="contactForm">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          onChange={handleNameChange}
                          placeholder="Adınız"
                          value={name}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          onChange={handleEmailChange}
                          placeholder="Email adresiniz"
                          value={email}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          onChange={handleSubjectChange}
                          id="subject"
                          placeholder="Konu"
                          value={subject}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          onChange={handleMessageChange}
                          cols={30}
                          rows={7}
                          placeholder="Mesajınız"
                          defaultValue={""}
                          value={message}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={handleRecaptchaVerify}
                          onExpired={handleRecaptchaExpire}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <input
                          disabled={!isVerified}
                          type="button"
                          onClick={handleFormSubmit}
                          value={"Gönder"}
                          className="btn circle black m-b-lg p-x-md"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
