import React from 'react';

function Switcher(){
return (
    <div id="switcher">
    <div className="switcher white" id="sw-theme">
      <a href="#" data-ui-toggle-class="active" data-ui-target="#sw-theme" className="white sw-btn">
        <i className="fa fa-gear text-muted" />
      </a>
      <div className="box-header">
        <strong>Özelleştir</strong>
      </div>
      <div className="box-divider" />
      <div className="box-body">
   
        
        <p>Rengini Seç</p>
        <div data-target="bg" className="text-u-c text-center _600 clearfix">
          <label className="p-a col-xs-3 light pointer m-a-0">
            <input type="radio" name="theme" defaultValue hidden />
            <i className="active-checked fa fa-check" />
          </label>
          <label className="p-a col-xs-3 grey pointer m-a-0">
            <input type="radio" name="theme" defaultValue="grey" hidden />
            <i className="active-checked fa fa-check" />
          </label>
          <label className="p-a col-xs-3 dark pointer m-a-0">
            <input type="radio" name="theme" defaultValue="dark" hidden />
            <i className="active-checked fa fa-check" />
          </label>
          <label className="p-a col-xs-3 black pointer m-a-0">
            <input type="radio" name="theme" defaultValue="black" hidden />
            <i className="active-checked fa fa-check" />
          </label>
        </div>
      </div>
    </div>
  </div>
);
}
export default Switcher;