import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getById } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import Comment from "../Shared/Comment";
import Seo from "../../../shared/ui/Seo";
import { createSlug } from "../../../../utils/helpers";

const EventDetail = () => {
  const { id, slug } = useParams();
  const [event, setEvent] = useState([]);
  const getEvent = async () => {
    const response = await getById(id, ENDPOINTS.EVENTS);
    const responseSlug = createSlug(response?.title);
    if (responseSlug !== slug) {
      window.location.href = "/";
    }
    setEvent(response);
  };
  useEffect(() => {
    getEvent();
  }, [id, slug]);
  return (
    <>
      <Seo
        title={event?.title}
        description={event?.description}
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle={event?.title}
        ogDescription={event?.description}
        twitterCard="summary_large_image"
      />
      <div className="padding b-b">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">Ajanda & Etkinlik</h1>
        </div>
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w r">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{ backgroundImage: `url('${event?.image?.image}')` }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <div className="page-title">
                {/* {announcement.map((a) => ( */}
                <h3 className="inline">{event.title}</h3>
                {/* ))} */}
              </div>
              <p className="item-desc text-muted">
                {" "}
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: event?.description }}
                  />
                }
              </p>
              <div className="item-action m-b">
                <a
                  className="btn btn-icon white rounded btn-share pull-right"
                  data-toggle="modal"
                  data-target="#share-modal"
                >
                  <i className="fa fa-share-alt" />
                </a>
              </div>
              <div className="item-meta">
                <a className="btn btn-xs rounded white">{event.event_place}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Comment type="EVENTS" id={id} /> */}
    </>
  );
};

export default EventDetail;
