import { BASE_URL, PUBLIC_KEY ,SECRET_KEY} from '../utils/constants';
import { getTokenApi,} from '../utils/api';
import { fetchUserByUsername } from './userService';

export async function getToken(userName, password) {
  try {
    const response = await getTokenApi(`/o/token/`, `grant_type=password&username=${userName}&password=${password}`);
    const token = response.data;
    if(token){
      return token;
    }
    return null;
  } catch (error) {
    console.error('Error while getting token:', error);
    return null;
  }
};

// export async function getAuthenticatedUser() {

//   const defaultReturnObject = { authenticated: false, user: null };
//   if(localStorage.getItem('access_token') === null){
//     return defaultReturnObject;
//   }
//   else{
//     const data = {
//       authenticated:true,
//       user: {token: localStorage.getItem('access_token')}
//     }
//     const {authenticated, user} = data;
//     return { authenticated, user };
//   }
// };