import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get, getById } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import Comment from "../Shared/Comment";
import LikeButton from "../Shared/LikeButton";
import Seo from "../../../shared/ui/Seo";
import { createSlug } from "../../../../utils/helpers";

const AnnouncementDetail = () => {
  const { id, slug } = useParams();
  const [announcement, setAnnouncement] = useState([]);
  const [relatedAnnouncements, setRelatedAnnouncements] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const getLikeCount = async () => {
    const response = await get(
      ENDPOINTS.ANNOUNCEMENT_LIKE + "?announcement__id=" + id
    );
    setLikeCount(response?.count);
  };
  const getAnnouncements = async () => {
    const response = await getById(id, ENDPOINTS.ANNOUNCEMENTS);
    const responseSlug = createSlug(response?.announcement?.title);
    if (responseSlug !== slug) {
      window.location.href = "/";
    }
    setAnnouncement(response?.announcement);
    setRelatedAnnouncements(response?.related_announcements);
  };
  useEffect(() => {
    getAnnouncements();
    getLikeCount();
  }, [id, likeCount, slug]);
  return (
    <>
      <Seo
        title={announcement?.title}
        description={announcement?.description}
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle={announcement?.title}
        ogDescription={announcement?.description}
        twitterCard="summary_large_image"
      />
      <div className="padding b-b">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">İlan</h1>
        </div>
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w r">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{
                    backgroundImage: `url('${announcement?.image?.image}')`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <div className="page-title">
                {/* {announcement.map((a) => ( */}
                <h1 className="inline">{announcement?.title}</h1>
                {/* ))} */}
              </div>
              <p className="item-desc text-muted">
                {
                  <div
                    dangerouslySetInnerHTML={{
                      __html: announcement?.description,
                    }}
                  />
                }
              </p>
              <div className="item-action m-b">
                <a
                  className="btn btn-icon white rounded btn-share pull-right"
                  data-toggle="modal"
                  data-target="#share-modal"
                >
                  <i className="fa fa-share-alt" />
                </a>

                <LikeButton
                  type="ANNOUNCEMENT"
                  id={id}
                  onClick={getLikeCount}
                />
                <span className="text-muted"> {likeCount}</span>
              </div>
              <div className="item-meta">
                <Link
                  to={`/app/kullanici-detay/${announcement?.user?.username}`}
                  className="btn btn-xs rounded white"
                >
                  Ekleyen: {announcement?.user?.username}
                </Link>
                <Link
                  to={`/app/ilanlar?type=${announcement?.announcement_type?.id}`}
                  className="btn btn-xs rounded white"
                >
                  İlan Tipi: {announcement?.announcement_type?.title}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {relatedAnnouncements?.length > 0 ? (
          <div className="row-col">
            <div className="col-lg-9 no-border">
              <div className="m-t-3 no-border">
                <h5 className="m-b">Kullanıcının diğer ilanları</h5>
                <div className="row m-b">
                  {relatedAnnouncements?.map((item, index) => (
                    <div className="col-xs-3 col-sm-3 col-md-2" key={index}>
                      <div className="item r" data-id="item-12" data-src="">
                        <div className="item-media ">
                          <Link
                            to={`/app/ilan-detay/${
                              item.id
                            }/${createSlug(item?.title)}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${item?.image?.image}')`,
                            }}
                          />
                        </div>
                        <div className="item-info">
                          <div className="item-overlay bottom text-right">
                            <LikeButton type="ANNOUNCEMENT" id={item?.id} />
                          </div>
                          <div className="item-title text-ellipsis">
                            <Link
                              to={`/app/ilan-detay/${
                                item.id
                              }/${createSlug(item?.title)}`}
                            >
                              {item?.title}
                            </Link>
                          </div>
                          <div className="item-author text-sm text-ellipsis">
                            <Link
                              to={`/app/ilan-detay/${
                                item.id
                              }/${createSlug(item?.title)}`}
                              className="text-muted"
                            >
                              {item?.short_description}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Comment type="ANNOUNCEMENT" id={id} />
    </>
  );
};

export default AnnouncementDetail;
