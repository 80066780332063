import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";
import Seo from "../../../shared/ui/Seo";
import Loading from "../../../shared/ui/Loading";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const getEvents = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.EVENTS;
      url += `?limit=${itemsPerPage}&offset=${
        (currentPage - 1) * itemsPerPage
      }&is_published=1&ordering=-create_time`;
      const response = await get(url);
      const newEvents = response.results;

      if (currentPage === 1) {
        setEvents(newEvents);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...newEvents]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error("Etkinlikler alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (currentPage !== 1) {
      getEvents();
    }
  }, [currentPage]);
  
  useEffect(() => {
    getEvents();
  }, []);
  return (
    <>
    <Seo 
        title="Etkinlikler Konserler " 
        description="Müzik konserleri ve etkinliklerini takip edin. Siz de etkinlik oluşturabilir veya var olan etkinliklere bakabilirsiniz." 
        keywords="konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Etkinlikler Konserler - muzisyen.app"
        ogDescription="En güncel müzik ilanlarına buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
     />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">Ajanda & Etkinlikler</h1>
            </div>
            {/* <div className="nav-active-border b-primary bottom m-b-md m-t">
              <ul className="nav l-h-2x" data-ui-jp="taburl">
                {events.map((event) => (
                  <li className="nav-item m-r inline" key={event.id}>
                    <a
                      className="nav-link"
                      href="#track"
                      data-toggle="tab"
                      data-target="#track"
                    >
                      {event.genre}
                    </a>
                  </li>
                ))}
              </ul>
            </div> */}
            <div className="tab-content">
              <div className="tab-pane active" id="track">
                <div className="row item-list item-list-by m-b">
                  {events.map((event) => (
                    <div className="col-xs-12" key={event.id}>
                      <div className="item r" data-id={event.id}>
                        <div className="item-media ">
                          <Link
                            to={`/app/etkinlik-detay/${event.id}/${createSlug(event?.title)}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${event?.image?.image}')`,
                            }}
                          ></Link>
                        </div>
                        <div className="item-info">
                          <div className="item-title text-ellipsis">
                            <Link to={`/app/etkinlik-detay/${event.id}/${createSlug(event?.title)}`}>
                              {event.title}
                            </Link>
                          </div>

                          <div className="item-meta text-sm text-muted">
                            <span className="item-meta-category">
                              <a href="browse.html" className="label">
                              {event.event_place}
                              </a>
                            </span>
                            <span className="item-meta-date text-xs">
                              {new Date(
                                event.create_time
                              ).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="item-except visible-list text-sm text-muted h-2x m-t-sm">
                            {event.short_description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  {loading ? (
                    <Loading />
                  ) : (
                    hasMore && <a onClick={handleLoadMore}>Daha Fazla Göster</a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
