import { useFormik } from "formik";
import React from "react";
import { useAppSelector } from "../../../../store";
import { toast } from "react-hot-toast";
import { post } from "../../../../utils/api";
import error from "../../../../utils/error";
import { COMMENT_TYPES } from "../../../../utils/constants";
import { CommentSchema } from "../../../../validations/CommentValidation";
import { Link } from "react-router-dom";

const CommentForm = ({ id, type, onClick }) => {
    const currentUser = useAppSelector((state) => state.auth.currentUser);

  const formik = useFormik({
    initialValues: {
      comment_text: "",
    },
    validationSchema: CommentSchema,
    onSubmit: async (values) => {
      const toastId = toast.loading('Yükleniyor...');
      const insertData = {
        comment_text: values.comment_text,
        user: currentUser.id,
        is_featured: false
      };
      insertData[type?.toLowerCase()] = id; 
      const result = await post(COMMENT_TYPES[type] + "/", insertData);
      if (result.status === 201) {
        toast.success("Yorumunuz başarıyla eklendi.. Onaylandıktan sonra yayınlanacaktır.", { id: toastId, duration: 3000});
        formik.resetForm();
        onClick();
      }
    },
  });
  return (
    <>
      <h5 className="m-t-lg m-b">Yorum Yaz</h5>
      {currentUser ? (
        <>
   <form onSubmit={formik.handleSubmit}>
      
   <div className="form-group">
     <label>Yorum</label>
     <textarea
       className="form-control"
       rows={5}
       value={formik.values.comment_text}
       name="comment_text"
       onChange={formik.handleChange}
       onBlur={formik.handleBlur}
       placeholder="Bir yorum yazın..."
     />
     {error("comment_text", formik)}
   </div>
   <div className="form-group">
     <button type="submit" className="btn btn-sm white rounded">
       Gönder
     </button>
   </div>
 </form></>
      ) : (
      <p>Yorum yazmak için lütfen <Link to={"/giris-yap"}> giriş </Link> yapınız.</p>
      )
      }
   
    </>
  );
};

export default CommentForm;
