import React, { useEffect, useState } from "react";
import { momentTime } from "../../../../utils/helpers";
import { DEFAULT_AVATAR, DEFAULT_CHAT_AVATAR, ENDPOINTS } from "../../../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { get, post } from "../../../../utils/api";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";

const MessageDetail = ({ messages, username,avatar,updateMessages}) => {
  const [sortedMessages, setSortedMessages] = useState([]);
  const [sendMessage, setSendMessage] = useState("");
  const currentUser = useAppSelector((state) => state.auth.currentUser);
 
  useEffect(() => {
    // Sort messages by sending date
    const sorted = [...messages].sort(
      (a, b) => new Date(a.create_time) - new Date(b.create_time)
    );
    setSortedMessages(sorted);
  }, [messages]);

  const handleRefreshMessage = async () => {
    updateMessages();
  };
  const handleChangeMessage = (e) => {
    setSendMessage(e.target.value);
  };

  const handleSubmit = async () => {
    const user = await get(ENDPOINTS.USERS + "?user__username=" + username);
    if (user.results.count === 0) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Kullanıcı bulunamadı",
      });
      return false;
    }
    const insertData = {
      sender_user: currentUser.id,
      recipient_user: user.results[0].id,
      message: sendMessage,
      subject: "Mesaj",
    };
    const response = await post(ENDPOINTS.USER_MESSAGE + "/", insertData);
    if (response.status === 201) {
      toast.success("Mesaj gönderildi");
   
      setSendMessage("");

      updateMessages();
    } else {
      toast.error("Mesaj gönderilemedi");
    
    }
  };
  const handleKeyDownMessage = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <div className="chat-header clearfix">
        <div className="row">
          <div className="col-lg-6">
            <Link to={`/app/kullanici-detay/${username}`} data-toggle="modal" data-target="#view_info">
              <img
                src={avatar || DEFAULT_AVATAR}
                alt="avatar"
              />
            </Link>
            <div className="chat-about">
              <h6 className="m-b-0">{username}</h6>
              <small> Çevirimiçi</small>
            </div>
          </div>
          <div className="col-lg-6 hidden-sm text-right">
            <Link
              onClick={handleRefreshMessage}
              className="btn btn-outline-warning"
            >
              <i className="fa fa-refresh" />
            </Link>
            <Link
              to={`/app/kullanici-detay/${username}`}
              className="btn btn-outline-warning"
            >
              <i className="fa fa-user" />
            </Link>
          </div>
        </div>
      </div>
      <div className="chat-history">
        <ul className="m-b-0">
          {sortedMessages &&
            sortedMessages.map((message) => (
              <li className="clearfix" key={message?.id}>
                {message.sender_user.username === currentUser.username ? (
                  <div className="message-data">
                    <span className="message-data-time">
                      {" "}
                      {momentTime(message?.create_time)}
                      {" "}
                    </span>
                    <img
                      src={currentUser.avatar?.image || DEFAULT_AVATAR}
                      alt="avatar"
                    />
                  </div>
                ) : (
                  <>
                    <div className="message-data text-right">
                      <span className="message-data-time">
                        {momentTime(message?.create_time)}
                        {" "}
                      </span>
                      <img
                        src={
                          message.sender_user.avatar?.image ||
                          DEFAULT_AVATAR
                        }
                        alt="avatar"
                      />
                    </div>
                  </>
                )}
                <div
                  className={`message ${
                    message.sender_user.username === currentUser.username
                      ? "my-message"
                      : "other-message float-right"
                  }`}
                >
                  {message.message}
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="chat-message clearfix">
        <div className="input-group mb-0">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <input
                type="text"
                value={sendMessage}
                onChange={handleChangeMessage}
                onKeyDown={handleKeyDownMessage}
                className="form-control"
                placeholder="Cevap yazın.."
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageDetail;
