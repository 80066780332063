import classNames from "classnames";
import { useEffect, useState } from "react";
import { ENDPOINTS, SIDEBAR_TYPES } from "../../../../utils/constants";
import { get } from "../../../../utils/api";
import { Link } from "react-router-dom";
import Loading from "../../../shared/ui/Loading";
import { createSlug } from "../../../../utils/helpers";

const LastEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.EVENTS + "?ordering=-id&is_published=1&limit=3");
    setEvents(response);
    setLoading(false);
  };
  useEffect(() => {
    getData();

  }, []);

  return (
    <>
      <h6 className="text text-muted">Etkinlikler</h6>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="row item-list item-list-sm m-b">
            {events?.results?.map((item) => (
              <div className="col-xs-12" key={item?.id}>
                <div
                  className="item r"
                  data-id="item-6"
                >
                  <div className="item-media ">
                    <Link
                      to={`/app/etkinlik-detay/${item.id}/${createSlug(item?.title)}`}
                      className="item-media-content"
                      style={{
                        backgroundImage: `url('${item?.image?.image}')`,
                      }}
                    />
                  </div>
                  <div className="item-info">
                    <div className="item-title text-ellipsis">
                      <Link
                        to={`/app/etkinlik-detay/${item.id}/${createSlug(item?.title)}`}
                        style={{ textOverflow: "ellipsis" }}
                      >
                        {item?.title}
                      </Link>
                    </div>
                    <div className="item-author text-sm text-ellipsis ">
                      <Link
                        to={`/app/etkinlik-detay/${item.id}/${createSlug(item?.title)}`}
                        className="text-muted"
                        style={{ textOverflow: "ellipsis" }}
                      >
                        {item?.event_place}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default LastEvents;
