import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../shared/ui/Seo";
import VideoPlayer from "../../shared/ui/VideoPlayer";

function Home() {
  return (
    <>
      <Seo title="Anasayfa" description="müzisyenlerin sitesinin anasayfası" />
      <div className="row-col">
        <div className="col-sm-6">
          <div
            className="black cover cover-gd"
            style={{ backgroundImage: 'url("/images/b1.jpg")' }}
          >
            <div className="p-a-lg text-center">
              <h3 className="display-3 m-y-lg">
                Müzisyenlerin Buluşma Noktası
              </h3>
              <p className="text-muted text-md m-b-lg">
                Müziğe dair her şeyi bulabileceğiniz, yeni müzik arkadaşları
                bulabileceğiniz mükemmel bir platforma sizde katılın.
              </p>
              <Link to={"/kayit-ol"} className="btn circle white m-b-lg p-x-md">
                Aramıza Katılın
              </Link>
            </div>
          </div>
        </div>
      </div>
  
      <div className="row-col dark-white">
        <div className="col-md-3" />
        <div className="col-md-6">
          <div className="p-a-lg text-center">
            <h3 className="display-4 m-y-lg">
              Müzisyenler ve Müzikseverler İçin En İdeal Platform
            </h3>
            <p className="text-muted text-md m-b-lg">
              Müzik dünyası, her yerde farklı sesler, tarzlar ve yeteneklerle
              doludur. Platformumuz, tüm bunları bir araya getiriyor ve size
              müziği keşfetme ve yaratma fırsatı sunuyor.
            </p>
            <Link
              to={"/app"}
              className="btn circle btn-outline b-black m-b-lg p-x-md"
            >
              Müziğin Ritmine Kapılın
            </Link>
          </div>
        </div>
        <div className="col-md-3" />
      </div>
      <div
        className="row-col dark-white"
        data-stellar-background-ratio="0.5"
      >
        <div className="row-col">
          <VideoPlayer source="https://cdn.muzisyen.app/muzisyen.mp4" />
        </div>
      </div>
      <div
        className="black cover"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: 'url("/images/b10.jpg")' }}
      >
        <div className="row-col">
          <div className="col-md-4">
            <div className="p-a-lg text-center">
              <h4 className="m-y-lg">Neler var?</h4>
              <ul className="text-muted text-md m-b-lg list-unstyled">
                <li>
                  <Link to={"/app/ilanlar"}>Eleman ve grup arama ilanları</Link>
                </li>
                <li>
                  <Link to={"/app/ilanlar"}>
                    İkinci el ve sıfır enstrüman pazarı
                  </Link>
                </li>
                <li>
                  <Link to={"/app/ilanlar"}>
                    Özel ders verenler veya özel ders arayanlar
                  </Link>
                </li>
                <li>
                  <Link to={"/app/ilanlar"}>İş arayan ve iş verenler</Link>
                </li>
                <li>
                  <Link to={"/app/studyolar"}>Kayıt ve Prova Stüdyoları</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-a-lg text-center">
              <h4 className="m-y-lg">Ajanda ve etkinlikler</h4>
              <ul className="text-muted text-md m-b-lg list-unstyled">
                <li>
                  <Link to={"/app/etkinlikler"}>Konserler</Link>
                </li>
                <li>
                  <Link to={"/app/haberler"}>Müzik Haberleri</Link>
                </li>
                <li>
                  <Link to={"/app/etkinlikler"}>Atölyeler</Link>
                </li>
                <li>
                  <Link to={"/app/etkinlikler"}>Etkinlikler</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-a-lg text-center">
              <h4 className="m-y-lg">Müzik adına herşey</h4>
              <ul className="text-muted text-md m-b-lg list-unstyled">
                <li>
                  <Link to={"/app/haberler"}>Müzik haberleri</Link>
                </li>
                <li>
                  <Link to={"/app/haberler"}>Röportajlar</Link>
                </li>
                <li>
                  <Link to={"/app"}>Şarkı sözleri</Link>
                </li>
                <li>
                  <Link to={"/app"}>Yeni yetenekleri dinle / keşfet</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
