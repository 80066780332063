import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { get, post } from "../../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import Seo from "../../shared/ui/Seo";

const ForgotPassword = () => {
const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = async () => {
    const response = await post("/users/reset-password/", { email });
    if (response?.status === 200) {
        toast.success("Şifre sıfırlama linki e-posta adresinize gönderildi.");
        navigate("/giris-yap");
    }
    else {
        toast.error("E-posta adresi bulunamadı.");
    }
  };
  return (
    <>
    <Seo
        title="Şifremi unuttum" 
        description="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!" 
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Şifremi unuttum - muzisyen.app"
        ogDescription="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        twitterCard="summary_large_image"
     />
      <div className="text-center m-t-lg">
        <h3>Şifremi Unuttum</h3>
      </div>
      <div className="b-t">
        <div className="center-block w-xxl w-auto-xs p-y-md text-center">
          <div className="p-a-md">
            <div className="form-group">
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Email adresiniz"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-lg black p-x-lg"
            >
              Gönder
            </button>
            <div className="m-y">
              <Link to={"/giris-yap"} className="_600">
                Vazgeç
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
