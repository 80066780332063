import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ENDPOINTS } from "../../../../utils/constants";
import { get, getById } from "../../../../utils/api";
import Comment from "../Shared/Comment";
import { useAppSelector } from "../../../../store";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import TracksSmall from "./TracksSmall";
import UserTracks from "../Users/UserTracks";
import Seo from "../../../shared/ui/Seo";
import { createSlug } from "../../../../utils/helpers";

const TrackDetail = () => {
  const { id,slug} = useParams();
  const [userId, setUserId] = useState(0);
  const [track, setTrack] = useState([]);
  const [userTracks, setUserTracks] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const getLikeCount = async () => {
    const response = await get(ENDPOINTS.TRACK_LIKE + "?tracks__id=" + id);
    setLikeCount(response?.count);
  };
  const getTrack = async () => {
    const response = await getById(id, ENDPOINTS.TRACKS);
    const responseSlug = createSlug(response?.name);
    
    if(responseSlug !== slug) {
      window.location.href = "/";
    }
    setUserId(response?.user?.id);
    setTrack(response);
  };
  const getUserTracks = async () => {
    const response = await get(
      ENDPOINTS.TRACKS + "?user__id=" + userId + "&is_published=1"
    );
    setUserTracks(response.results);
  };
  useEffect(() => {
    getTrack();
    getUserTracks();
    getLikeCount();
  }, [id,slug,userId]);
  return (
    <>
    <Seo
        title={track?.name}
        description={track?.description}
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle={track?.name}
        ogDescription={track?.description}
        twitterCard="summary_large_image"
      />
      <div className="padding b-b">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">Keşfet</h1>
        </div>
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w r">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{ backgroundImage: `url('${track?.cover?.image}')` }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <div className="page-title">
                <h1 className="inline">{track?.name}</h1>
              </div>
              <p className="item-desc text-muted">{track?.description}</p>
              <div className="item-action m-b">
                <a
                  className="btn btn-icon white rounded btn-share pull-right"
                  data-toggle="modal"
                  data-target="#share-modal"
                >
                  <i className="fa fa-share-alt" />
                </a>

                <LikeButton type="TRACKS" id={id} onClick={getLikeCount}/> 
           
                <span className="text-muted"> {likeCount}</span>
              
              </div>
              <div className="item-meta">
                <a className="btn btn-xs rounded white">
                  {track.genre}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <h6 className="m-b">
              
                <Link
                  to={`/app/kullanici-detay/${track?.user?.username}`}
                  data-pjax
                  className="item-author _600"
                >
                  {track?.user?.username}
                </Link>
                <span className="text-muted"> kullanıcının tüm kayıtları</span>
              </h6>
              <div
                id="tracks"
                className="row item-list item-list-xs item-list-li m-b"
              >
                {userTracks.map((track, index) => (
                  <TracksSmall key={index} track={track} />
                ))}
               
              </div>
              {/* <h5 className="m-b">Kullanıcının en sevilen kayıtları</h5>
              <div className="row m-b">
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div
                    className="item r"
                    data-id="item-12"
                    data-src=""
                  >
                    <div className="item-media ">
                      <a
                        href="track.detail.html"
                        className="item-media-content"
                        style={{ backgroundImage: 'url("images/b11.jpg")' }}
                      />
                      <div className="item-overlay center">
                        <button className="btn-playpause">Play</button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div className="item-overlay bottom text-right">
                        <a href="#" className="btn-favorite">
                          <i className="fa fa-heart-o" />
                        </a>
                       <ActionButton/>
                      </div>
                      <div className="item-title text-ellipsis">
                        <a href="track.detail.html">Happy ending</a>
                      </div>
                      <div className="item-author text-sm text-ellipsis hide">
                        <a href="artist.detail.html" className="text-muted">
                          Postiljonen
                        </a>
                      </div>
                      <div className="item-meta text-sm text-muted">
                        <span className="item-meta-stats text-xs ">
                          <i className="fa fa-play text-muted" /> 860
                          <i className="fa fa-heart m-l-sm text-muted" /> 240
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div
                    className="item r"
                    data-id="item-5"
                    data-src="http://streaming.radionomy.com/JamendoLounge"
                  >
                    <div className="item-media ">
                      <a
                        href="track.detail.html"
                        className="item-media-content"
                        style={{ backgroundImage: 'url("images/b4.jpg")' }}
                      />
                      <div className="item-overlay center">
                        <button className="btn-playpause">Play</button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div className="item-overlay bottom text-right">
                        <a href="#" className="btn-favorite">
                          <i className="fa fa-heart-o" />
                        </a>
                      <ActionButton/>
                      </div>
                      <div className="item-title text-ellipsis">
                        <a href="track.detail.html">Live Radio</a>
                      </div>
                      <div className="item-author text-sm text-ellipsis hide">
                        <a href="artist.detail.html" className="text-muted">
                          Radionomy
                        </a>
                      </div>
                      <div className="item-meta text-sm text-muted">
                        <span className="item-meta-stats text-xs ">
                          <i className="fa fa-play text-muted" /> 3340
                          <i className="fa fa-heart m-l-sm text-muted" /> 100
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div
                    className="item r"
                    data-id="item-1"
                    data-src="http://api.soundcloud.com/tracks/269944843/stream?client_id=a10d44d431ad52868f1bce6d36f5234c"
                  >
                    <div className="item-media ">
                      <a
                        href="track.detail.html"
                        className="item-media-content"
                        style={{ backgroundImage: 'url("images/b0.jpg")' }}
                      />
                      <div className="item-overlay center">
                        <button className="btn-playpause">Play</button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div className="item-overlay bottom text-right">
                        <a href="#" className="btn-favorite">
                          <i className="fa fa-heart-o" />
                        </a>
                       <ActionButton/>
                      </div>
                      <div className="item-title text-ellipsis">
                        <a href="track.detail.html">Pull Up</a>
                      </div>
                      <div className="item-author text-sm text-ellipsis hide">
                        <a href="artist.detail.html" className="text-muted">
                          Summerella
                        </a>
                      </div>
                      <div className="item-meta text-sm text-muted">
                        <span className="item-meta-stats text-xs ">
                          <i className="fa fa-play text-muted" /> 3200
                          <i className="fa fa-heart m-l-sm text-muted" /> 210
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div
                    className="item r"
                    data-id="item-8"
                    data-src="http://api.soundcloud.com/tracks/236288744/stream?client_id=a10d44d431ad52868f1bce6d36f5234c"
                  >
                    <div className="item-media ">
                      <a
                        href="track.detail.html"
                        className="item-media-content"
                        style={{ backgroundImage: 'url("images/b7.jpg")' }}
                      />
                      <div className="item-overlay center">
                        <button className="btn-playpause">Play</button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div className="item-overlay bottom text-right">
                        <a href="#" className="btn-favorite">
                          <i className="fa fa-heart-o" />
                        </a>
                       <ActionButton/>
                      </div>
                      <div className="item-title text-ellipsis">
                        <a href="track.detail.html">Simple Place To Be</a>
                      </div>
                      <div className="item-author text-sm text-ellipsis hide">
                        <a href="artist.detail.html" className="text-muted">
                          RYD
                        </a>
                      </div>
                      <div className="item-meta text-sm text-muted">
                        <span className="item-meta-stats text-xs ">
                          <i className="fa fa-play text-muted" /> 400
                          <i className="fa fa-heart m-l-sm text-muted" /> 220
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <Comment type="TRACKS" id={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackDetail;
