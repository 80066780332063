import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import LikeButton from "../../app/Shared/LikeButton";
import ActionButton from "../../app/Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";

const ProfileProducts = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    const response = await get(
      ENDPOINTS.PRODUCTS + "?user__id=" + currentUser?.id
    );
    setProducts(response.results);
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <div className="row m-b">
        {products.length > 0 ? (
          products.map((item, index) => (
            <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
              <div className="item r" data-id="item-2" data-src="">
                <div className="item-media ">
                  <Link
                    to={`/app/urun-detay/${item.id}/${createSlug(item?.name)}`}
                    className="item-media-content"
                    style={{ backgroundImage: `url('${item?.image?.image}')` }}
                  />
                </div>
                <div className="item-info">
                <div className="item-overlay bottom text-right">
                   <ActionButton endpoint={ENDPOINTS.PRODUCTS} id={item?.id} />
                  </div>
                  <div className="item-title text-ellipsis">
                    <Link
                      to={`/app/urun-detay/${item.id}/${createSlug(
                        item?.name
                      )}`}
                    >
                      {item.name}
                    </Link>
                  </div>
                  <div className="item-author text-sm text-ellipsis hide">
                    <Link
                      to={`/app/urun-detay/${item.id}/${createSlug(
                        item?.name
                      )}`}
                      className="text-muted"
                    >
                      {item.name}
                    </Link>
                  </div>
                  <div className="item-meta text-sm text-muted">
                    <span className="item-meta-stats text-xs ">
                      <i className="fa fa-user text-muted" />{" "}
                      {item?.user?.username} -{" "}
                      {item?.is_published === true ? (
                        <label className="label green">Yayınlanmış</label>
                      ) : (
                        <label className="label danger">Yayınlanmamış</label>
                      )}
                    </span>
                  </div>
                  <div className="item-author text-sm text-ellipsis">
                    {item?.short_description}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-muted">Hiç ürün girilmemiş</p>
        )}
      </div>
    </>
  );
};

export default ProfileProducts;
