import React, { useEffect, useState } from "react";
import Seo from "../../../shared/ui/Seo";
import { ENDPOINTS } from "../../../../utils/constants";
import { get, post } from "../../../../utils/api";
import { Link } from "react-router-dom";
import { createSlug, momentTime } from "../../../../utils/helpers";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../store";

const Questions = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const getQuestionCategories = async () => {
    const response = await get(ENDPOINTS.QUESTION_CATEGORY);
    setQuestionCategories(response.results);
  };
  const getQuestions = async () => {
    const response = await get(
      ENDPOINTS.QUESTIONS +
        "?status=Açık&is_published=1&ordering=-create_time&limit=100"
    );
    setTotalQuestions(response.count);
    setQuestions(response.results);
  };
  const submitNewQuestion = async () => {
    Swal.fire({
      width: 600,
      title: "Soru Ekle",
      html: `<input id="swal-input1" class="form-control" placeholder="Soru Başlığı" required>
      <select id="swal-input2" class="form-control">
      ${questionCategories.map((category) => {
        return `<option value="${category.id}">${category.name}</option>`;
      })}
      </select>
      <textarea id="swal-input3" class="form-control" placeholder="Soru Detayı" required></textarea>`,
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonText: "Ekle",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const subject = document.getElementById("swal-input1").value;
        const category = document.getElementById("swal-input2").value;
        const question_text = document.getElementById("swal-input3").value;
        if (!subject || !category || !question_text) {
          Swal.showValidationMessage("Lütfen tüm alanları doldurun.");
          return false;
        }
        var formData = new FormData();
        formData.append("subject", subject);
        formData.append("category", category);
        formData.append("question_text", question_text);
        formData.append("status", "Açık");
        formData.append("user", currentUser?.id);
        const result = await post(ENDPOINTS.QUESTIONS + "/", formData);
        if (result?.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sorunuz başarıyla eklendi. Sorunuz onaylandıktan sonra yayınlanacaktır.",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Sorunuz eklenirken bir hata oluştu.",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      },
    });
  };
  useEffect(() => {
    getQuestionCategories();
    getQuestions();
  }, []);

  return (
    <>
      <Seo
        title="Soru Cevap aklına takılan soruyu sor"
        description="En güncel müzik ilanları burada. Siz de ilan oluşturabilir veya var olan ilanlara başvurabilirsiniz."
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Müzik İlanları - muzisyen.app"
        ogDescription="En güncel müzik ilanlarına buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
      />
      <div className="row-col">
        <div className="padding">
          <div className="page-title m-b">
            <h1 className="inline m-a-0">Soru Cevap</h1>
            <div className="dropdown inline">
              <a
                onClick={submitNewQuestion}
                className="btn btn-sm primary h4 m-y-0 v-b m-l-1 text-sm text-white"
              >
                <i className="fa fa-plus" /> Yeni Soru Ekle
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="wrapper wrapper-content">
                <div className="ibox-content m-b-sm border-bottom">
                  <div className="p-xs">
                    <div className="pull-left m-r-md">
                      <i className="fa fa-music text-navy mid-icon" />
                    </div>
                    <h4>Aklına takılan soruyu sor, cevabını bul</h4>
                    <span>
                      Müzisyenler burada bilgi alışverişi yapıyor. Sende aklına
                      takılanı sor veya cevabını bildiğin sorulara cevap ver. Şu
                      ana kadar toplam <b>{totalQuestions}</b> soru soruldu.
                    </span>
                  </div>
                </div>
                <div className="ibox-content forum-container">
                  {questionCategories.map((category) => (
                    <>
                      <div className="forum-title">
                        <div className="pull-right forum-desc">
                          <small className="text-muted">
                            {category?.questions_count} adet soru
                          </small>
                        </div>
                        <div className="forum-icon">
                          <i className={`fa fa-${category?.icon_name}`} />
                        </div>
                        <h4>{category?.name}</h4>
                        <div className="forum-sub-title text-ellipsis text-muted font-italic">
                          {category?.description}
                        </div>
                      </div>
                      {questions.filter((a) => a?.category?.id === category?.id)
                        .length > 0 ? (
                        questions
                          .filter((a) => a?.category?.id === category?.id)
                          .map((question) => (
                            <div className="forum-item">
                              <div className="row">
                                <div className="col-md-9">
                                  <div className="forum-icon2">
                                    <i className="fa fa-share" />
                                  </div>
                                  <Link
                                    to={`/app/soru-cevap/${
                                      question.id
                                    }/${createSlug(question?.subject)}`}
                                    className="forum-item-title"
                                  >
                                    {question?.subject}{" "}
                                    {question?.status === "Açık" ? (
                                      <span className="label label-sm primary pos-rlt m-r-xs">
                                        Açık
                                      </span>
                                    ) : (
                                      <span className="label label-sm danger pos-rlt m-r-xs">
                                        {question?.status}
                                      </span>
                                    )}
                                  </Link>
                                  <div className="forum-sub-title text-ellipsis">
                                    {question?.question_text}
                                  </div>
                                </div>
                                <div className="col-md-1 forum-info">
                                  <span className="forum-username">
                                    {momentTime(question?.create_time)}
                                  </span>
                                  <div>
                                    <small>eklendi</small>
                                  </div>
                                </div>
                                <div className="col-md-1 forum-info">
                                  <span className="forum-username">
                                    <Link
                                      to={`/app/kullanici-detay/${question?.user?.username}`}
                                    >
                                      {question?.user?.username}
                                    </Link>
                                  </span>
                                  <div>
                                    <small>Ekleyen</small>
                                  </div>
                                </div>
                                <div className="col-md-1 forum-info">
                                  <span className="views-number">
                                    {question?.answer_count}
                                  </span>
                                  <div>
                                    <small>Cevap</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="m-l-3 text-muted">
                          "Henüz soru girilmemiş"
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
