import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { get, patch, post } from "../../../../utils/api";
import Loading from "../../../shared/ui/Loading";
import BASE_URL, { DEFAULT_AVATAR } from "../../../../utils/constants";
import MessageDetail from "./MessageDetail";
import Seo from "../../../shared/ui/Seo";

const Messages = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);

  const getMessages = async () => {
    setLoading(true);
    const receivedMessages = await get(
      "message?recipient_user__id=" + currentUser.id
    );
    const sendMessages = await get("message?sender_user__id=" + currentUser.id);

    const grouped = {};

    receivedMessages.results.forEach((item) => {
      const username = item.sender_user.username;
      if (!grouped[username]) {
        grouped[username] = [];
      }
      grouped[username].push(item);
    });

    sendMessages.results.forEach((item) => {
      const username = item.recipient_user.username;
      if (!grouped[username]) {
        grouped[username] = [];
      }
      grouped[username].push(item);
    });

    // Sort messages by sending date
    Object.values(grouped).forEach((messages) => {
      messages.sort(
        (a, b) => new Date(a.create_time) - new Date(b.create_time)
      );
    });
    setGroupedMessages(grouped);
    setLoading(false);

    return grouped[username] || null; // Güncellenmiş mesajları döndürüyoruz
  };

  const updateMessages = async () => {
    const updatedMessages = await getMessages();
    setSelectedMessage(updatedMessages);
  };
  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    if (!selectedMessage) {
      const sortedUsernames = Object.keys(groupedMessages).sort(
        (usernameA, usernameB) => {
          const lastMessageTimeA =
            groupedMessages[usernameA][groupedMessages[usernameA].length - 1]
              .create_time;
          const lastMessageTimeB =
            groupedMessages[usernameB][groupedMessages[usernameB].length - 1]
              .create_time;
          return new Date(lastMessageTimeB) - new Date(lastMessageTimeA);
        }
      );

      if (sortedUsernames.length > 0) {
        const firstUsername = sortedUsernames[0];
        setSelectedMessage(groupedMessages[firstUsername]);
        setUsername(firstUsername);
      }
    }
  }, [groupedMessages]);

  const handleMessageClick = async (messages, username) => {
    setSelectedMessage(messages);
    setUsername(username);
    handleMessagesRead();
  };
  const handleMessagesRead = async () => {
    const selectedMessageIds = selectedMessage.map((message) => message.id);
    for (let i = 0; i < selectedMessageIds.length; i++) {
      const response = await patch("message/" + selectedMessageIds[i] + "/", {
        is_read: true,
      });
      if (response.status === 200) {
      }
    }
  };

  const getAvatarImage = (messages) => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.sender_user.username === currentUser.username) {
      return lastMessage.recipient_user.avatar?.image || DEFAULT_AVATAR;
    } else {
      return lastMessage.sender_user.avatar?.image || DEFAULT_AVATAR;
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Seo
            title="Mesajlarım"
            description="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
            keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
            ogTitle="Gelen mesajlarım - muzisyen.app"
            ogDescription="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
            twitterCard="summary_large_image"
          />
          <div className="row-col">
            <div className="padding">
              <div className="page-title m-b">
                <h1 className="inline m-a-0">Mesajlarım</h1>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className="card chat-app">
                    <div id="plist" className="people-list">
                      <div className="input-group">
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          Gelen Mesajlar
                        </p>
                        <hr />
                      </div>
                      <ul className="list-unstyled chat-list mt-2 mb-0">
                        {groupedMessages
                          ? Object.entries(groupedMessages)
                              .sort(([, messagesA], [, messagesB]) => {
                                const lastMessageTimeA =
                                  messagesA[messagesA.length - 1].create_time;
                                const lastMessageTimeB =
                                  messagesB[messagesB.length - 1].create_time;
                                return (
                                  new Date(lastMessageTimeB) -
                                  new Date(lastMessageTimeA)
                                );
                              })
                              .map(([username, messages]) => (
                                <li
                                  className="clearfix"
                                  key={username}
                                  onClick={() =>
                                    handleMessageClick(messages, username)
                                  }
                                >
                                  <img
                                    src={getAvatarImage(messages)}
                                    alt="avatar"
                                  />
                                  <div className="about">
                                    <div className="name text-ellipsis">
                                      {username}
                                    </div>
                                    <div className="status text-ellipsis">
                                      {messages[messages.length - 1]?.message}
                                    </div>
                                  </div>
                                </li>
                              ))
                          : "Hiç mesaj gelmemiş."}
                      </ul>
                    </div>
                    <div className="chat">
                      {selectedMessage && (
                        <MessageDetail
                          messages={selectedMessage}
                          username={username}
                          avatar={getAvatarImage(selectedMessage)}
                          updateMessages={updateMessages}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Messages;
