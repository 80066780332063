import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { patch } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";

const ActionButton = ({ endpoint, id }) => {
  const [display, setDisplay] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisplay(!display);
  };
  const handleItemEdit = (e) => {
    setDisplay(false);
  };
  const handleItemDelete = async (e) => {
    setDisplay(false);
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, yayından kaldır!",
      cancelButtonText: "Hayır, iptal et!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var formData = new FormData();
        formData.append("is_published", 0);
        const result = await patch(endpoint + "/" + id + "/", formData);
        console.log("result", result);
        if (result?.status === 200) {
          Swal.fire({
            title: "Silindi!",
            text: "İçerik yayından kaldırıldı. Eğer tekrar yayına almak isterseniz lütfen bize info@muzisyen.app üzerinden yazın.",
            icon: "success",
            confirmButtonText: "Tamam",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire("Hata!", "Bir hata oluştu. Lütfen bize info@muzisyen.app üzerinden yazın. Veya daha sonra tekrar deneyin", "error");
        }
      }
    });
  };
  return (
    <>
      <a onClick={handleSubmit}>
        <i className="fa fa-ellipsis-h" />
      </a>
      {display ? (
        <div className="actionButton pull-right black lt">
          {/* <a className="dropdown-item" onClick={handleItemEdit}>
            <i className="fa fa-plus fa-fw text-left" /> Düzenle
          </a> */}
          <a className="dropdown-item" onClick={handleItemDelete}>
            <i className="fa fa-remove fa-fw text-left" /> Yayından Kaldır
          </a>
          {/* <div className="dropdown-divider" />
          <a
            className="dropdown-item btn-share"
            data-toggle="modal"
            data-target="#share-modal"
          >
            <i className="fa fa-share-alt fa-fw text-left " /> Paylaş
          </a> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ActionButton;
