import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { post } from "../../../utils/api";

const VerifyEmail = () => {
  const { token } = useParams();
  const [status, setStatus] = useState(false);
  const verifyEmail = async () => {
    const response = await post("users/verify-email/", { token });
    setStatus(response?.status === 200);
  };
  useEffect(() => {
    verifyEmail();
  }, [token]);
  return (
    <>
      <div className="text-center m-t-lg">
        <h3>Hesabını Onayla</h3>
      </div>
      <div className="b-t">
        <div className="center-block w-xxl w-auto-xs p-y-md text-center">
          <div className="p-a-md">
            <div>
             { status ? (
              <Link
                to="/giris-yap"
                className="btn btn-block indigo text-white m-b-sm"
              >
                <i className="fa fa-check pull-left" />
                Hesabınız onaylandı giriş yapabilirsiniz.
              </Link>
              ) : (
              <Link to="/" className="btn btn-block red text-white">
                <i className="fa fa-remove pull-left" />
                Hesabınız onaylanamadı.
              </Link>
              )}
            </div>
            <div>
              <br />
              <Link to="/giris-yap" className="text-primary _600">
                Giriş sayfasına git
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
