import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { ENDPOINTS } from "../../../../utils/constants";
import { get, post } from "../../../../utils/api";
import {
  fetchCities,
  fetchTownsByCity,
} from "../../../../services/shareService";
import error from "../../../../utils/error";
import { ProductSchema } from "../../../../validations/ProductValidation";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ProductAdd = () => {
  const editorConfiguration = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading3', view: 'h3', title: 'Başlık 1', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Başlık 2', class: 'ck-heading_heading4' }
        ]
    }
  };
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [categories, setCategories] = useState([
    { name: "", description: "", image: null, parent_category: null },
  ]);
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  const getCategories = async () => {
    const response = await get(ENDPOINTS.PRODUCT_CATEGORY);
    setCategories(response.results);
  };
  const handleParentCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setSelectedParentCategory(selectedCategoryId);
  
    const parentCategory = categories.find(
      (category) => category.id === parseInt(selectedCategoryId)
    );
    if (parentCategory) {
      setSubCategories(parentCategory.children);
      if (parentCategory.children.length === 0) { // Eğer alt kategori yoksa, formik state'ini güncelle
        formik.setFieldValue("category", selectedCategoryId);
      }
    } else {
      setSubCategories([]);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: null,
      category: null,
      description: null,
      short_description: null,
      image: null,
      price: "",
      status: "",
      city: "",
      town: "",
      keywords: "",
      youtube_link: "",
      gallery: [],
    },
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      const toastId = toast.loading("Yükleniyor...");
      try {
        setLoading(true);
        const formData = new FormData();
        // Image ekleme
        if (values.image) {
          const formDataImage = new FormData();
          formDataImage.append("image", values.image);
          const imageResponse = await post(
            ENDPOINTS.IMAGES + "/",
            formDataImage
          );
          if (imageResponse.status === 201) {
            formData.append("image", imageResponse.data.id);
          }
        }

        // Galeri resimlerini ekleme
        if (values.gallery) {
          for (const file of values.gallery) {
            const formDataGalleryImage = new FormData();
            formDataGalleryImage.append("image", file);
            const galleryResponse = await post(
              ENDPOINTS.IMAGES + "/",
              formDataGalleryImage
            );
            if (galleryResponse.status === 201) {
              formData.append("gallery", galleryResponse.data.id.toString()); // ID'yi string olarak ekleyin.
            }
          }
        }
        formData.append("user", currentUser?.id);
        formData.append("name", values.name);
        formData.append("category", parseInt(values.category, 10));
        formData.append("description", values.description);
        formData.append("short_description", values.short_description);
        formData.append("price", values.price);
        formData.append("status", values.status);
        formData.append("city", values.city);
        formData.append("town", values.town);
        formData.append("keywords", values.keywords);
        formData.append("youtube_link", values.youtube_link);

        const result = await post(ENDPOINTS.PRODUCTS + "/", formData); // ENDPOINTS.PRODUCTS doğru endpoint olmalıdır.
        if (result && result.status === 201) {
          toast.success("Ürün başarıyla eklendi. Onaylandıktan sonra yayınlanacaktır..", { id: toastId });
          navigate("/app/urunler");
        }
      } catch (e) {
        toast.error("Bir hata oluştu.", { id: toastId });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImagePreview = (files) => {
    const imageFiles = Array.from(files);
    const images = imageFiles.map((file) => URL.createObjectURL(file));
    setSelectedImages(images);
  };
  const [towns, setTowns] = useState([]);
  const [cities, setCity] = useState([]);
  const getCities = async () => {
    const cities = await fetchCities();
    setCity(cities);
  };

  const getTownsByCity = async (selectedCity) => {
    if (selectedCity) {
      const _towns = await fetchTownsByCity(selectedCity);
      if (towns.length > 0) {
        formik.setFieldValue("town", "");
      }
      setTowns(_towns.results);
    }
  };
  const statusOptions = [
    { value: "Yeni", label: "Yeni" },
    { value: "Yeni Gibi", label: "Yeni Gibi" },
    { value: "İyi", label: "İyi" },
    { value: "Fena değil", label: "Fena değil" },
  ];
  useEffect(() => {
    getCities();
    getCategories();
  }, []);

  useEffect(() => {
    if (formik.values.city) {
      getTownsByCity(formik.values.city);
    } else {
      formik.setFieldValue("town", "");
    }
  }, [formik.values.city]);

  return (
    <div className="padding p-b-0">
      <div className="page-title m-b">
        <h1 className="inline m-a-0">Ürün Ekle</h1>
      </div>
      <div className="row row-sm item-masonry item-info-overlay">
        <div className="col-sm-9 m-b-sm">
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            {/* Ürün adı */}
            {/* ... Diğer form alanları aynı şekilde devam eder... */}

            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Üst Kategori
              </div>
              <div className="col-sm-9">
                <select
                  name="parent_category"
                  value={selectedParentCategory}
                  onChange={handleParentCategoryChange}
                  className="form-control"
                >
                  <option value="">Seçiniz</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
      
                </select>
                {error("parent_category", formik)}
              </div>
            </div>

            {subCategories.length > 0 && (
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Alt Kategori
                </div>
                <div className="col-sm-9">
                  <select
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    className="form-control"
                  >
                    <option value="">Seçiniz</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    ))}
                       
                  </select>
                </div>
              </div>
            )}
   
            {/* Ürün Adı */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">Adı</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  name="name"
                  className="form-control"
                  placeholder="Ürün adı giriniz ..."
                />
                            {error("name", formik)}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">Kısa açıklama</div>
              <div className="col-sm-9">
                <textarea
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.short_description}
                  name="short_description"
                  className="form-control"
                  placeholder="Kısa açıklama giriniz ..."
                ></textarea>
                            {error("short_description", formik)}
              </div>
            </div>
            {/* Açıklama */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Açıklama
              </div>
              <div className="col-sm-9">
              <CKEditor
                    editor={ClassicEditor}
                    config={ editorConfiguration }
                    data="<p>Birşeyler yazın</p>"
                    onReady={(editor) => {
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("description", data);
                    }}
                    onBlur={(event, editor) => {
                    }}
                    name="description"
                  />
                            {error("description", formik)}
              </div>
            </div>

            {/* Fiyat */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Fiyat
              </div>
              <div className="col-sm-9">
                <input
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                  name="price"
                  className="form-control"
                  placeholder="TL"
                />
                           {error("price", formik)}
              </div>
            </div>
 

            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Şehir
              </div>
              <div className="col-sm-9">
                <select
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  className="form-control"
                  onBlur={formik.handleBlur}
                >
                  <option value="">Şehir Seçin</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {error("city", formik)}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">İlçe</div>
              <div className="col-sm-9">
                <select
                  name="town"
                  value={formik.values.town}
                  onChange={formik.handleChange}
                  disabled={!formik.values.city}
                  className="form-control"
                  onBlur={formik.handleBlur}
                >
                  <option value="">İlçe Seçin</option>
                  {towns.map((town) => (
                    <option key={town.id} value={town.id}>
                      {town.name}
                    </option>
                  ))}
                </select>
                {error("town", formik)}
              </div>
            </div>

            {/* Durum */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Durum
              </div>
              <div className="col-sm-9">
                <div className="btn-group" role="group">
                  {statusOptions.map((option) => (
                    <label
                      key={option.value}
                      className={`btn btn-outline b-primary text-primary ${
                        formik.values.status === option.value ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="status"
                        value={option.value}
                        checked={formik.values.status === option.value}
                        onChange={() =>
                          formik.setFieldValue("status", option.value)
                        }
                        style={{ display: "none" }}
                      />
                      {option.label}
                    </label>
                  ))}
                              {error("status", formik)}
                </div>
              </div>
            </div>

            {/* Youtube Link */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Youtube Link
              </div>
              <div className="col-sm-9">
                <input
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.youtube_link}
                  name="youtube_link"
                  className="form-control"
                  placeholder="(Opsiyonel) Youtube link giriniz. İnceleme videosu için"
                />
              </div>
            </div>

            {/* Resim */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Resim
              </div>
              <div className="col-sm-9">
                <input
                  accept="image/*"
                  type="file"
                  name="image"
                  onChange={(event) => {
                    formik.setFieldValue("image", event.currentTarget.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                              {error("image", formik)}
              </div>

            </div>

            {/* Galeri */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Galeri
              </div>
              <div className="col-sm-9">
                <input
                  accept="image/*"
                  type="file"
                  name="gallery"
                  onChange={(event) => {
                    formik.setFieldValue("gallery", event.currentTarget.files);
                    handleImagePreview(event.currentTarget.files);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  multiple
                />
                <small className="form-text text-muted">
                  Birden fazla dosya seçmek için Ctrl tuşuna basılı tutarak
                  seçim yapabilirsiniz.
                </small>
              </div>
            </div>
            {selectedImages.length > 0 && (
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Resim Önizleme
                </div>
                <div className="col-sm-9">
                  {selectedImages.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`preview ${index}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Anahtar Kelimeler */}
            <div className="form-group row">
              <div className="col-sm-3 form-control-label text-muted">
                Anahtar Kelimeler
              </div>
              <div className="col-sm-9">
                <input
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.keywords}
                  name="keywords"
                  className="form-control"
                  placeholder="Anahtar Kelimeleri Virgül ile Ayırın (örn: anahtar kelime, anahtar kelime, anahtar kelime)"
                />
                          {error("keywords", formik)}
              </div>
    
            </div>
  
            {/* Submit Button */}
            <button
              disabled={loading}
              className="btn btn-md rounded primary"
              type="submit"
            >
              Ürün Ekle
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductAdd;
