import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { get, post } from "../../../../utils/api";
import { useAppSelector } from "../../../../store";
import error from "../../../../utils/error";
import { ENDPOINTS } from "../../../../utils/constants";
import { AnnouncementSchema } from "../../../../validations/AnnouncementValidation";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import Seo from "../../../shared/ui/Seo";

const AnnouncementAdd = () => {
  const navigate = useNavigate();
  const editorConfiguration = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading3', view: 'h3', title: 'Başlık 1', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Başlık 2', class: 'ck-heading_heading4' }
        ]
    }
  };
  const [loading, setLoading] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [announcementTypes, setAnnouncementType] = useState([]);
  const getAnnouncementTypes = async () => {
    const response = await get(ENDPOINTS.ANNOUNCEMENT_TYPE);
    setAnnouncementType(response.results);
  };
  useEffect(() => {
    getAnnouncementTypes();
  }, []);
  const formik = useFormik({
    initialValues: {
      user: currentUser.id,
      title: "",
      announcement_type: 0,
      description: "",
      image: null,
      photoGalleries: "",
      short_description: "",
    },
    validationSchema: AnnouncementSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const toastId = toast.loading("Yükleniyor...");
      const formData = new FormData();
      const formDataImage = new FormData();
      formData.append("title", values.title);
      formData.append("announcement_type", values.announcement_type);
      formData.append("description", values.description);
      formData.append("short_description", values.short_description);
      formData.append("user", currentUser.id);
      formDataImage.append("image", values.image);
      const response = await post(ENDPOINTS.IMAGES + "/", formDataImage);
      if(response.status === 201){
        formData.append("image", response.data.id);
      }
      else{
        toast.error("Resim yüklenemedi", { id: toastId });
        return false;
      }
      const result = await post(ENDPOINTS.ANNOUNCEMENTS + "/", formData);
      if (result.status === 201) {
        toast.success("Başarıyla eklendi, onaylandıktan sonra yayına alınacak.", { id: toastId });
        navigate("/app/ilanlar");
      }
      else{
        toast.error("Hata oluştu", { id: toastId });
      }
    },
  });
  return (
    <>
    <Seo
            title="İlan Ekle"
            description="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
            keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
            ogTitle="İlan Ekle - muzisyen.app"
            ogDescription="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
            twitterCard="summary_large_image"
          />
      <div className="padding p-b-0">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">İlan Ekle</h1>
        </div>
        <div className="row row-sm item-masonry item-info-overlay">
          <div className="col-sm-9 m-b-sm">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">Başlık</div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name="title"
                    className="form-control"
                  />
                  {error("title", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">İlan Tipi</div>
                <div className="col-sm-9">
                  <select
                    name="announcement_type"
                    value={formik.values.announcement_type}
                    onChange={formik.handleChange}
                    className="form-control"
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Seçiniz</option>
                    {announcementTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  {error("announcement_type", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">Kısa açıklama</div>
                <div className="col-sm-9">
                  <textarea
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.short_description}
                    name="short_description"
                    className="form-control">
                    </textarea>
                  {error("short_description", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">İçerik</div>
                <div className="col-sm-9">
                  <CKEditor
                    editor={ClassicEditor}
                    config={ editorConfiguration }
                    data="<p>Birşeyler yazın</p>"
                    onReady={(editor) => {
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("description", data);
                    }}
                    onBlur={(event, editor) => {
                    }}
                    name="description"
                  />
                  {/* <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    name="description"
                    className="form-control"
                  /> */}
                  {error("description", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">Resim</div>
                <div className="col-sm-9">
                  <input
                    accept="image/*"
                    type="file"
                    name="image"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "image",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  {error("image", formik)}
                </div>
              </div>

              <button className="btn btn-md rounded primary" type="submit" disabled={loading}>
                Ekle
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementAdd;
