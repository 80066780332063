// Önce gerekli kütüphaneleri içe aktarın
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const GoogleAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Sayfanın yolunu ayarla
    ReactGA.pageview(location.pathname); // Sayfa görüntülemesini gönder
  }, [location.pathname]);

  return null; // Bu bileşen hiçbir şey render etmez
};

export default GoogleAnalytics;