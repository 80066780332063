import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';


const Seo = ({ 
    title = '', 
    description = '', 
    keywords = '',
    ogTitle = '',
    ogDescription = '',
    twitterCard = 'summary_large_image',
  }) => {
    const location = useLocation();
    const currentUrl = window.location.origin + location.pathname;
    const ogImage = 'https://muzisyen.app/images/logo.png';
      return (
          <Helmet>
              <title>{title} - muzisyen.app - Türkiye'nin en büyük müzik sitesi</title>
              {description && <meta name="description" content={description} />}
              {keywords && <meta name="keywords" content={keywords} />}
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <meta http-equiv="X-UA-Compatible" content="ie=edge" />
              {<link rel="canonical" href={currentUrl} />}
              
              {/* Open Graph / Facebook */}
              {<meta property="og:url" content={currentUrl} />}
              <meta property="og:type" content="website" />
              {ogTitle && <meta property="og:title" content={ogTitle} />}
              {ogDescription && <meta property="og:description" content={ogDescription} />}
              {<meta property="og:image" content={ogImage} />}
              
              {/* Twitter */}
              <meta property="twitter:card" content={twitterCard} />
              {<meta property="twitter:url" content={currentUrl} />}
              {ogTitle && <meta property="twitter:title" content={ogTitle} />}
              {ogDescription && <meta property="twitter:description" content={ogDescription} />}
              {<meta property="twitter:image" content={ogImage} />}
          </Helmet>
      );
  };
  
  export default Seo;

  