import { useEffect, useMemo, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import classNames from "classnames";
import capitalizeString from "../../../../utils/helpers";
import UserTracks from "./UserTracks";
import UserAnnouncement from "./UserAnnouncement";
import UserNews from "./UserNews";
import { get } from "../../../../utils/api";
import { DEFAULT_AVATAR, ENDPOINTS } from "../../../../utils/constants";
import SendMessage from "./SendMessage";
import { useAppSelector } from "../../../../store";
import UserProducts from "./UserProducts";

const User = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState([]);
  const tabs = useMemo(
    () => [
      {
        key: "track",
        title: "Şarkıları",
        body: <UserTracks />,
      },
      {
        key: "announcement",
        title: "İlanları",
        body: <UserAnnouncement />,
      },
      {
        key: "news",
        title: "Haberleri",
        body: <UserNews />,
      },
      {
        key: "products",
        title: "Ürünleri",
        body: <UserProducts />,
      },
    ],
    []
  );
  const getUser = async () => {
    const response = await get(
      ENDPOINTS.USERS + "?user__username=" + params.username
    );
    if (response.count === 0) {
      return (window.location.href = "/");
    }
    setUser(response.results[0]);
  };
  const activeTabKey = useMemo(() => {
    return searchParams.get("tab") || tabs?.[0]?.key;
  }, [searchParams]);

  const setActiveTabKey = (tab) => {
    setSearchParams((x) => ({ ...x, tab }));
  };
  useEffect(() => {
    if (params.username === currentUser?.username) {
      navigate("/app/hesabim");
    }
    getUser();
  }, []);
  return (
    <>
      <div className="padding b-b">
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w rounded">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{
                    backgroundImage: `url('${
                      user?.avatar?.image || DEFAULT_AVATAR
                    }')`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <h1 className="page-title">
                <span className="h1 _800">
                  {capitalizeString(user?.first_name) +
                    " " +
                    capitalizeString(user?.last_name)}
                </span>
              </h1>
              <p
                className="item-desc text-ellipsis text-muted"
                data-ui-toggle-class="text-ellipsis"
              >
                {user?.biography}
              </p>
              <p>
                {user?.instrument?.length > 0 ? (
                  <>
                    <span style={{ fontSize: 14 }}>Çaldığı enstrumanlar</span>
                    <p>
                      {user?.instrument?.map((instrument) => (
                        <span className="label label-sm dark m-r-xs">
                          {instrument.name}
                        </span>
                      ))}
                    </p>
                  </>
                ) : (
                  ""
                )}
                {user?.genres?.length > 0 ? (
                  <>
                    <span style={{ fontSize: 14 }}>
                      İlgilendiği müzik türleri
                    </span>
                    <p>
                      {user?.genres?.map((genre) => (
                        <span className="label label-sm dark m-r-xs">
                          {genre.name}
                        </span>
                      ))}
                    </p>
                  </>
                ) : (
                  ""
                )}
                {user?.user_experience && (
                  <>
                    Deneyim:{" "}
                    <span className="label label-md yellow m-r-xs">
                      {" "}
                      {user?.user_experience?.name}
                    </span>
                  </>
                )}
              </p>
              <div className="block clearfix m-b">
                <div className="row-col m-b">
                  <div className="col-xs">
                    {user?.facebook_url && (
                      <Link
                        target="_blank"
                        to={"https://facebook.com/" + user?.facebook_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-facebook" />
                        <i className="fa fa-facebook indigo" />
                      </Link>
                    )}
                    {user?.instagram_url && (
                      <Link
                        target="_blank"
                        to={"https://instagram.com/" + user?.instagram_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-instagram" />
                        <i className="fa fa-instagram black" />
                      </Link>
                    )}
                    {user?.twitter_url && (
                      <Link
                        target={"blank"}
                        to={"https://twitter.com/" + user?.twitter_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-twitter" />
                        <i className="fa fa-twitter light-blue" />
                      </Link>
                    )}
                    {user?.youtube_url && (
                      <Link
                        target={"blank"}
                        to={"https://youtube.com/" + user?.youtube_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-youtube" />
                        <i className="fa fa-youtube red" />
                      </Link>
                    )}
                    {user?.spotify_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.spotify_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-spotify" />
                        <i className="fa fa-spotify green" />
                      </Link>
                    )}
                    {user?.applemusic_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.applemusic_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-apple" />
                        <i className="fa fa-apple black" />
                      </Link>
                    )}
                    {user?.soundcloud_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.soundcloud_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-soundcloud" />
                        <i className="fa fa-soundcloud orange" />
                      </Link>
                    )}
                    {user?.website_url && (
                      <Link
                        target="_blank"
                        to={user?.website_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-link" />
                        <i className="fa fa-link grey" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {user?.city?.name && user?.town?.name && (
                <div class="item-meta m-b">
                  <a class="btn btn-xs rounded white">
                    {user?.town?.name + " / " + user?.city?.name}
                  </a>
                </div>
              )}

              <div className="block clearfix m-b">
                <div className="row-col m-b">
                  <div className="col-xs">
                    <SendMessage send={params.username} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding p-y-0 m-b-md">
            <div className="nav-active-border b-primary bottom m-b-md m-t">
              <ul className="nav l-h-2x" data-ui-jp="taburl">
                {tabs.map((tab) => (
                  <li key={tab.key} className="nav-item m-r inline">
                    <span
                      className={classNames("nav-link", {
                        active: activeTabKey === tab.key,
                      })}
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveTabKey(tab.key)}
                    >
                      {tab.title}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tab-content">
              {tabs.map((tab) => (
                <div
                  key={tab.key}
                  className={classNames("tab-pane", {
                    active: activeTabKey === tab.key,
                  })}
                >
                  {tab.body}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
