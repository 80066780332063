import React from "react";
import Seo from "../../shared/ui/Seo";
function PrivacyPolicy() {
  return (
    <>
      <Seo
        title="Hakkımızda"
        description="Türkiye'nin en büyük müzik platformu muzisyen.app sitesinde sizde istediklerinizi bulabilirsiniz."
      />
      <div className="row-col">
        <div className="col-sm-6 black lt">
          <div
            className="black cover cover-gd"
            style={{
              backgroundImage: 'url("/images/b7.jpg")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="p-a-lg text-center">
              <h3 className="display-3 m-y-lg">Gizlilik Politikamız</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row-col dark-white">
        <div className="col-md-2" />
        <div className="col-md-8">
          <div className="p-a-lg ">
            <p className="btn-outline b-black">
              muzisyen.app sitesi olarak kişisel gizlilik haklarınıza saygı
              duyuyor ve sitemizde geçirdiğiniz süre zarfında bunu sağlamak için
              çaba sarfediyoruz. Kişisel bilgilerinizin güvenliği ile ilgili
              açıklamalar aşağıda açıklanmış ve bilginize sunulmuştur.
              <br />
              <br />
              Kayıt Dosyaları
              <br />
              Birçok standard web sunucusunda olduğu gibi muzisyen.app‘da
              istatistiksel amaçlı log dosyaları kaydı tutmaktadır. Bu dosyalar;
              ip adresiniz, internet servis sağlayıcınız, tarayıcınızın
              özellikleri, işletim sisteminiz ve siteye giriş-çıkış sayfalarınız
              gibi standard bilgileri içermektedir. Log dosyaları kesinlikle
              istatistiksel amaçlar dışında kullanılmamakta ve mahremiyetinizi
              ihlal etmemektedir.Ip adresiniz ve diğer bilgiler, şahsi
              bilgileriniz ile ilişkilendirilmemektedir.
              <br />
              <br />
              Reklamlar
              <br />
              Sitemizde dışarıdan şirketlerin reklamlarını yayınlamaktayız
              (Google, v.s.). Bu reklamlar çerez (cookıes) içerebilir ve bu
              sirketler tarafından çerez bilgileri toplanabilir ve bizim bu
              bilgiye ulaşmamız mümkün değildir. Biz Google Adsense, vb.
              şirketler ile çalışmaktayız lütfen onların ilgili sayfalarından
              gizlilik sözleşmelerini okuyunuz.
              <br />
              <br />
              Çerezler (Cookies)
              <br />
              "Cookie – Çerez" kelimesi web sayfası sunucusunun sizin
              bilgisayarınızın hard diskine yerleştirdiği ufak bir text
              dosyasını tanımlamak için kullanılmaktadır. Sitemizin bazı
              bölümlerinde kullanıcı kolaylığı sağlamak için çerez kullanılıyor
              olabilir. Ayrıca sitede mevcut bulunan reklamlar aracılığıyla,
              reklam verilerinin toplanması için cookie ve web beacon
              kullanılıyor olabilir. Bu tamamen sizin izninizle gerçekleşiyor
              olup, isteğiniz dahilinde internet tarayıcınızın ayarlarını
              değiştirerek bunu engellemeniz mümkündür.
              <br />
              <br />
              Dış Bağlantılar
              <br />
              muzisyen.app sitesi, sayfalarından farklı internet adreslerine
              bağlantı vermektedir. muzisyen.app link verdiği, banner tanıtımını
              yaptığı sitelerin içeriklerinden veya gizlilik prensiplerinden
              sorumlu değildir. Burada bahsedilen bağlantı verme işlemi, hukuki
              olarak “atıfta bulunma” olarak değerlendirilmektedir.
              <br />
              <br />
              İletişim
              <br />
              muzisyen.app sitesinde uygulanan gizlilik politikası ile ilgili;
              her türlü soru, görüş ve düşüncelerinizi bize info@muzisyen.app
              adresinden iletebilirsiniz.
              <br />
            </p>
          </div>
        </div>
        <div className="col-md-2" />
      </div>
    </>
  );
}
export default PrivacyPolicy;
