import Yup from "./validations"
var phoneRegEx = /^0[5-9][0-9]{2}[ .-]?[0-9]{3}[ .-]?[0-9]{2}[ .-]?[0-9]{2}$/;
export const ProfileSchema = Yup.object().shape({
    town: Yup.string().required(),
    city: Yup.string().required(),
    user_experience: Yup.string().required(),
    biography: Yup.string().required(),
    phone: Yup.string().optional().matches(phoneRegEx, 'Geçersiz telefon numarası'),
    instrument: Yup.array().required(),
    genres: Yup.array().required(),
});