import React, { useEffect, useState } from "react";
import { setAccessToken, setCurrentUser } from "../../../store/authSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import store, { useAppSelector } from "../../../store";
import capitalizeString from "../../../utils/helpers";
import { toast } from "react-hot-toast";
import { DEFAULT_AVATAR, ENDPOINTS } from "../../../utils/constants";
import { get } from "../../../utils/api";
function DashboardSidebar() {
  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [messageCount, setMessageCount] = useState(0);
  const getMessageCount = async () => {
    const result = await get(
      ENDPOINTS.USER_MESSAGE +
        "/?recipient_user__id=" +
        currentUser.id +
        "&is_read=false"
    );
    if (result) {
      setMessageCount(result.count);
    }
  };
  useEffect(() => {
    getMessageCount();
  }, [currentUser]);
  const Logout = () => {
    dispatch(setAccessToken(null));
    dispatch(setCurrentUser(null));
    window.location.href = "/";
  };
  return (
    <div id="aside" className="app-aside modal fade nav-dropdown">
      <div className="left navside grey dk" data-layout="column">
        <div className="navbar no-radius">
          <Link to="/app" className="navbar-brand md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width={32}
              height={32}
            >
              <circle cx={24} cy={24} r={24} fill="rgba(255,255,255,0.2)" />
              <circle
                cx={24}
                cy={24}
                r={22}
                fill="#1c202b"
                className="brand-color"
              />
              <circle cx={24} cy={24} r={10} fill="#ffffff" />
              <circle
                cx={13}
                cy={13}
                r={2}
                fill="#ffffff"
                className="brand-animate"
              />
              <path d="M 14 24 L 24 24 L 14 44 Z" fill="#FFFFFF" />
              <circle cx={24} cy={24} r={3} fill="#000000" />
            </svg>
            <img src="/images/logo.png" alt="." className="hide" />
            <span className="hidden-folded inline">muzisyen</span>
          </Link>
        </div>
        <div data-flex className="hide-scroll">
          <nav className="scroll nav-stacked nav-active-primary">
            <ul className="nav" data-ui-nav>
              <li className="nav-header hidden-folded">
                <span className="text-xs text-muted">Hızlı Erişim</span>
              </li>
              <li>
                <Link to="/app">
                  <span className="nav-icon">
                    <i className="material-icons">play_circle_outline</i>
                  </span>
                  <span className="nav-text">Keşfet</span>
                </Link>
              </li>
              <li>
                <Link to="/app/ilanlar">
                  <span className="nav-icon">
                    <i className="material-icons">sort</i>
                  </span>
                  <span className="nav-text">İlanlar</span>
                </Link>
              </li>
              <li>
                <Link to="/app/urunler">
                  <span className="nav-icon">
                    <i className="material-icons">shopping_basket</i>
                  </span>
                  <span className="nav-text">Ürünler</span>
                </Link>
              </li>
              <li>
                <Link to="/app/studyolar">
                  <span className="nav-icon">
                    <i className="material-icons">mic</i>
                  </span>
                  <span className="nav-text">Stüdyolar</span>
                </Link>
              </li>
              <li>
                <Link to="/app/sarkilar">
                  <span className="nav-icon">
                    <i className="material-icons">audiotrack</i>
                  </span>
                  <span className="nav-text">Şarkılar</span>
                </Link>
              </li>
              <li>
                <Link to="/app/soru-cevap">
                  <span className="nav-icon">
                    <i className="material-icons">question_answer</i>
                  </span>
                  <span className="nav-text">Soru Cevap</span>
                </Link>
              </li>
              <li>
                <Link to="/app/muzisyenler">
                  <span className="nav-icon">
                    <i className="material-icons">group</i>
                  </span>
                  <span className="nav-text">Müzisyenler</span>
                </Link>
              </li>
              <li>
                <Link to="/app/haberler">
                  <span className="nav-icon">
                    <i className="material-icons">event_note</i>
                  </span>
                  <span className="nav-text">Gündem</span>
                </Link>
              </li>
              <li>
                <Link to="/app/etkinlikler">
                  <span className="nav-icon">
                    <i className="material-icons">perm_contact_calendar</i>
                  </span>
                  <span className="nav-text">Ajanda</span>
                </Link>
              </li>
              {currentUser && (
                <li>
                  <a data-toggle="modal" data-target="#search-modal">
                    <span className="nav-icon">
                      <i className="material-icons">search</i>
                    </span>
                    <span className="nav-text">Arama</span>
                  </a>
                </li>
              )}
              {currentUser && (
                <>
                  <li className="nav-header hidden-folded m-t">
                    <span className="text-xs text-muted">Hesabım</span>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/hesabim",
                        search: "?tab=profile",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      <span className="nav-text">Hesabım</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/app/mesajlarim"}>
                      <span className="nav-label">
                        <b className="label">{messageCount}</b>
                      </span>
                      <span className="nav-icon">
                        <i className="material-icons">chat</i>
                      </span>
                      <span className="nav-text">Mesajlarım</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/hesabim",
                        search: "?tab=announcement",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">sort</i>
                      </span>
                      <span className="nav-text">İlanlarım</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/hesabim",
                        search: "?tab=track",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">queue_music</i>
                      </span>
                      <span className="nav-text">Şarkılarım</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/hesabim",
                        search: "?tab=like",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">favorite_border</i>
                      </span>
                      <span className="nav-text">Beğendiklerim</span>
                    </Link>
                  </li>

                  <li className="nav-header hidden-folded m-t">
                    <span className="text-xs text-muted">İçerik Ekle</span>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/ilan-ekle",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">add</i>
                      </span>
                      <span className="nav-text">İlan Ekle</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/urun-ekle",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">add_shopping_cart</i>
                      </span>
                      <span className="nav-text">Ürün Ekle</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/sarki-ekle",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">queue_music</i>
                      </span>
                      <span className="nav-text">Şarkı Ekle</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/haber-ekle",
                      }}
                    >
                      <span className="nav-icon">
                        <i className="material-icons">event_note</i>
                      </span>
                      <span className="nav-text">Haber Ekle</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
        {currentUser && (
          <div data-flex-no-shrink>
            <div className="nav-fold dropup">
              <a data-toggle="dropdown">
                <span className="pull-left">
                  <img
                    src={currentUser?.avatar?.image || DEFAULT_AVATAR}
                    alt="..."
                    className="w-32 img-circle"
                  />
                </span>
                <span className="clear hidden-folded p-x p-y-xs">
                  <span className="block _500 text-ellipsis">
                    {capitalizeString(
                      currentUser?.first_name,
                      currentUser?.last_name
                    )}
                  </span>
                </span>
              </a>
              <div className="dropdown-menu w dropdown-menu-scale ">
                <Link
                  to={{
                    pathname: "/app/hesabim",
                    search: "?tab=profile",
                  }}
                  className="dropdown-item"
                >
                  <span>Hesabım</span>
                </Link>
                <Link
                  to={{
                    pathname: "/app/hesabim",
                    search: "?tab=announcement",
                  }}
                  className="dropdown-item"
                >
                  <span>İlanlarım</span>
                </Link>
                <Link
                  to={{
                    pathname: "/app/hesabim",
                    search: "?tab=track",
                  }}
                  className="dropdown-item"
                >
                  <span>Şarkılarım</span>
                </Link>
                <Link
                  to={{
                    pathname: "/app/hesabim",
                    search: "?tab=like",
                  }}
                  className="dropdown-item"
                >
                  <span>Beğendiklerim</span>
                </Link>
                <div className="dropdown-divider" />
                <Link to={"/iletisim"} className="dropdown-item">
                  Yardım?
                </Link>
                <Link className="dropdown-item" onClick={Logout}>
                  Çıkış Yap
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardSidebar;
