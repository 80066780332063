import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { get } from "../../../../utils/api";
import BASE_URL, { ENDPOINTS } from "../../../../utils/constants";
import Tracks from "../../app/Tracks/Tracks";
import ActionButton from "../../app/Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";

const ProfileLike = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [announcements, setAnnouncements] = useState([]);
  const [news, setNews] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [studios, setStudios] = useState([]);
  const [products, setProducts] = useState([]);
  const getAnnouncements = async () => {
    const response = await get(
      ENDPOINTS.ANNOUNCEMENT_LIKE + "?user__id=" + currentUser.id
    );
    setAnnouncements(response.results);
  };
  const getNews = async () => {
    const response = await get(
      ENDPOINTS.NEWS_LIKE + "?user__id=" + currentUser.id
    );
    setNews(response.results);
  };
  const getTracks = async () => {
    const response = await get(
      ENDPOINTS.TRACK_LIKE + "?user__id=" + currentUser.id
    );
    setTracks(response.results);
  };
  const getStudios = async () => {
    const response = await get(
      ENDPOINTS.STUDIO_LIKE + "?user__id=" + currentUser.id
    );
    setStudios(response.results);
  };
  const getProducts = async () => {
    const response = await get(
      ENDPOINTS.PRODUCT_LIKE + "?user__id=" + currentUser.id
    );
    setProducts(response.results);
  };
  useEffect(() => {
    getAnnouncements();
    getNews();
    getTracks();
    getStudios();
    getProducts();
  }, []);
  return (
    <>
      <div className="row m-b">
        <div className="m-l">
          <h6>Şarkılar</h6>
        </div>
        {tracks.map((track, index) => (
          <Tracks track={track?.tracks} key={index} />
        ))}
      </div>
      <div className="row m-b">
        <div className="m-l">
          <h6>Stüdyolar</h6>
        </div>
        {studios.map((studio, index) => (
          <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
            <div className="item r">
              <div className="item-media ">
                <Link
                  to={`/app/studyo-detay/${studio?.id}/${createSlug(studio?.studios?.name)}`}
                  className="item-media-content"
                  style={{ backgroundImage: `url('${studio?.studios?.image?.image}')` }}
                />
              </div>
              <div className="item-info">
                
                <div className="item-title text-ellipsis">
                  <Link to={`/app/studyo-detay/${studio?.id}//${createSlug(studio?.studios?.name)}`}>{studio?.studios?.name}</Link>
                </div>
                <div className="item-meta text-sm text-muted">
                  <span className="item-meta-stats text-xs ">
                    <i className="fa fa-location-arrow" />{" "}
                    {studio?.studios?.town?.name+" / "+studio?.studios?.city?.name}
                    
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row m-b">
        <div className="m-l">
          <h6>İlanlar</h6>
        </div>
        {announcements.map((announcement, index) => (
          <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
            <div className="item r" data-id="item-2" data-src="">
              <div className="item-media ">
                <Link
                  to={`/app/ilan-detay/${announcement?.announcement?.id}/${createSlug(announcement?.announcement?.title)}`}
                  className="item-media-content"
                  style={{
                    backgroundImage: `url('${announcement?.announcement?.image?.image}')`,
                  }}
                />
              </div>
              <div className="item-info">
            
                <div className="item-title text-ellipsis">
                  <Link
                    to={`/app/ilan-detay/${announcement?.announcement?.id}/${createSlug(announcement?.announcement?.title)}`}
                  >
                    {announcement?.announcement?.title}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis hide">
                  <Link to={`/app/ilan-detay/${announcement?.announcement?.id}/${createSlug(announcement?.announcement?.title)}`} className="text-muted">
                    {announcement?.announcement?.title}
                  </Link>
                </div>
                <div className="item-meta text-sm text-muted">
                  <span className="item-meta-stats text-xs ">
                    <i className="fa fa-user text-muted" />{" "}
                    {announcement?.announcement?.user?.username}
                    
                  </span>
                </div>
                <div className="item-author text-sm text-ellipsis">
                  {announcement?.announcement?.short_description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row m-b">
        <div className="m-l">
          <h6>Haberler</h6>
        </div>
        {news.map((item, index) => (
          <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
            <div className="item r" data-id="item-2" data-src="">
              <div className="item-media ">
                <Link
                  to={`/app/haber-detay/${item.id}/${createSlug(item?.news?.title)}`}
                  className="item-media-content"
                  style={{ backgroundImage: `url('${item?.news?.image?.image}')` }}
                />
              </div>
              <div className="item-info">
               
                <div className="item-title text-ellipsis">
                  <Link to={`/app/haber-detay/${item?.news?.id}/${createSlug(item?.news?.title)}`}>
                    {item?.news?.title}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis hide">
                  <Link to={`/app/haber-detay/${item?.news?.id}/${createSlug(item?.news?.title)}`} className="text-muted">
                    {item?.news?.short_description}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis">
                  {item?.news?.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row m-b">
        <div className="m-l">
          <h6>Ürünler</h6>
        </div>
        {products.map((item, index) => (
          <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
            <div className="item r" data-id="item-2" data-src="">
              <div className="item-media ">
                <Link
                  to={`/app/urun-detay/${item.id}/${createSlug(item?.products?.name)}`}
                  className="item-media-content"
                  style={{ backgroundImage: `url('${item?.products?.image?.image}')` }}
                />
              </div>
              <div className="item-info">
                <div className="item-title text-ellipsis">
                  <Link to={`/app/urun-detay/${item?.products?.id}/${createSlug(item?.products?.name)}`}>
                    {item?.products?.name}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis hide">
                  <Link to={`/app/urun-detay/${item?.products?.id}/${createSlug(item?.products?.name)}`} className="text-muted">
                    {item?.products?.description}
                  </Link>
                </div>
                <div className="item-author text-sm text-ellipsis">
                  {item?.products?.status} / {item?.products?.price} TL / {item?.products?.city?.name},{item?.products?.town?.name}
                </div>
                <div className="item-meta text-sm text-muted">
                  <span className="item-meta-stats text-xs ">
                    <i className="fa fa-user text-muted" />{" "}
                    {item?.products?.user?.username}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default ProfileLike;
