import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: '',
    id:0,
}

const commentSlice = createSlice({
  name: "bestSidebar",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export default commentSlice.reducer;
export const { setType, setId } = commentSlice.actions;
