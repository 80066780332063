import React from "react";
import BestSidebar from "../../pages/app/Sidebars/BestSidebar";
import { Link, useLocation } from "react-router-dom";
import LastEvents from "../../pages/app/Sidebars/LastEvents";
import Adsense from "../../pages/app/Sidebars/Adsense";
import NewTracks from "../../pages/app/Sidebars/NewTracks";
import BestTracks from "../../pages/app/Sidebars/BestTracks";
import LastProducts from "../../pages/app/Sidebars/LastProducts";
import { useAppSelector } from "../../../store";
function DashboardRightSidebar() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
   return (
    <div className="col-lg-3 w-xxl w-auto-md">
      <div
        className="padding"
        style={{ bottom: "60px" }}
        data-ui-jp="stick_in_parent"
      >
        {currentUser && (
          <BestTracks />
        )}
        
        <BestSidebar type="TRACKS" />
        <LastProducts />
        <LastEvents />
        <h6 className="text text-muted">Mobil Uygulamalar</h6>
        <div className="btn-groups">
          <a
          target="_blank"
            href="https://apps.apple.com/tr/app/muzisyen-app/id6450703048?l=tr"
            className="btn btn-sm dark lt m-r-xs"
            style={{ width: "135px" }}
          >
            <span className="pull-left m-r-sm">
              <i className="fa fa-apple fa-2x" />
            </span>
            <span className="clear text-left l-h-1x">
              <span className="text-muted text-xxs">Download on the</span>
              <b className="block m-b-xs">App Store</b>
            </span>
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.muzisyen" target="_blank" className="btn btn-sm dark lt" style={{ width: "133px" }}>
            <span className="pull-left m-r-sm">
              <i className="fa fa-play fa-2x" />
            </span>
            <span className="clear text-left l-h-1x">
              <span className="text-muted text-xxs">Get it on</span>
              <b className="block m-b-xs m-r-xs">Google Play</b>
            </span>
          </a>
        </div>
        <div className="b-b m-y" />
        <div className="nav text-sm _600">
          <Link to="/hakkimizda" className="nav-link text-muted m-r-xs">
            Hakkımızda
          </Link>
          <Link to="/iletisim" className="nav-link text-muted m-r-xs">
            İletişim
          </Link>
          <Link to="/kullanim-sartlari" className="nav-link text-muted m-r-xs">
            Kullanım Şartları
          </Link>
          <Link to="/gizlilik-politikasi" className="nav-link text-muted m-r-xs">
            Gizlilik Politikası
          </Link>
        </div>
        <p className="text-muted text-xs p-b-lg">
          © Copyright {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}
export default DashboardRightSidebar;
