import Yup from "./validations"
export const ProductSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().min(25).required(),
    status: Yup.string().required(),
    image: Yup.string().required(),
    price: Yup.number().required(),
    city: Yup.string().required(),
    town: Yup.string().required(),
    keywords: Yup.string().required(),
});