import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get, getById } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import Comment from "../Shared/Comment";
import LikeButton from "../Shared/LikeButton";
import Seo from "../../../shared/ui/Seo";
import { createSlug, momentTime } from "../../../../utils/helpers";
import YouTubeEmbed from "../../../shared/ui/YoutubeEmbed";

const ProductDetail = () => {
  const { id, slug } = useParams();
  const [products, setProducts] = useState([]);
  const [releatedProducts, setRelatedProducts] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const getLikeCount = async () => {
    const response = await get(ENDPOINTS.PRODUCT_LIKE + "?products__id=" + id);
    setLikeCount(response?.count);
  };
  const getProducts = async () => {
    const response = await getById(id, ENDPOINTS.PRODUCTS);
    const responseSlug = createSlug(response?.products?.name);
    if (responseSlug !== slug) {
      window.location.href = "/";
    }
    setProducts(response?.products);
    setRelatedProducts(response?.related_products);
  };
  useEffect(() => {
    getProducts();
    getLikeCount();
  }, [id, likeCount, slug]);
  return (
    <>
      <Seo
        title={products?.name}
        description={products?.description}
        keywords={products?.keywords}
        ogTitle={products?.name}
        ogDescription={products?.description}
        twitterCard="summary_large_image"
      />
      <div className="padding b-b">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">Ürün Detayı</h1>
        </div>
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w r">
              <div className="item-media">
                <div
                  className="item-media-content"
                  style={{
                    backgroundImage: `url('${products?.image?.image}')`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <div className="page-title">
                {/* {announcement.map((a) => ( */}
                <h1 className="inline">{products?.name}</h1>
                {/* ))} */}
              </div>
              <p className="item-desc text-muted">
                {
                  <div
                    dangerouslySetInnerHTML={{
                      __html: products?.description,
                    }}
                  />
                }
              </p>
              <div>
              {products?.gallery?.map((item, index) => (
                <img
                  src={item?.image}
                  alt=""
                  className="img-thumbnail"
                  style={{ cursor: "pointer", width: "100px" }}
                  data-toggle="modal"
                  data-target="#imageModal"
                  onClick={() => setSelectedImage(item?.image)}
                  key={index}
                />
              ))}
              <div
                className="modal fade animate " data-backdrop="false"
                id="imageModal"
                tabIndex="-1"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content fade-down">
                    <div className="modal-body">
                      <img src={selectedImage} alt="" className="img-fluid" />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                </div>
              </div></div>
              {products?.youtube_link && (
                <div className="m-t-1">
                  <b>İnceleme videosu</b>
                  <YouTubeEmbed youtubeLink={products?.youtube_link} />
                </div>
              )}
              <div className="item-action m-b">
                <a
                  className="btn btn-icon white rounded btn-share pull-right"
                  data-toggle="modal"
                  data-target="#share-modal"
                >
                  <i className="fa fa-share-alt" />
                </a>

                <LikeButton type="PRODUCTS" id={id} onClick={getLikeCount} />
                <span className="text-muted"> {likeCount}</span>
              </div>
              <div className="item-meta">
                <Link
                  to={`/app/kullanici-detay/${products?.user?.username}`}
                  className="btn btn-xs rounded yellow"
                >
                  Ekleyen: {products?.user?.username}
                </Link>
                <Link
                  to={`/app/urunler?category=${products?.category?.id}`}
                  className="btn btn-xs rounded white"
                >
                  Ürün Kategorisi: {products?.category?.full_category_name}
                </Link>
                <Link
                  to={`/app/urunler?city=${products?.city?.id}`}
                  className="btn btn-xs rounded white m-l-sm"
                >
                  {products?.city?.name} / {products?.town?.name}
                </Link>
              </div>
              <br />
              <div className="item-meta">
                <Link to="" className="btn btn-xs rounded white">
                  {momentTime(products?.create_time)}
                </Link>
                <Link
                  to={`/app/urunler?city=${products?.city?.id}`}
                  className="btn btn-xs rounded black m-l-sm"
                >
                  Ürünün durumu: {products?.status}
                </Link>
                <Link
                  to={`/app/urunler?city=${products?.city?.id}`}
                  className="btn btn-xs rounded red m-l-sm"
                >
                  Fiyat: {products?.price} TL
                </Link>
              </div>
            </div>
          </div>
        </div>
        {releatedProducts?.length > 0 ? (
          <div className="row-col">
            <div className="col-lg-9 no-border">
              <div className="m-t-3 no-border">
                <h5 className="m-b">Kullanıcının diğer ürünleri</h5>
                <div className="row m-b">
                  {releatedProducts?.map((item, index) => (
                    <div className="col-xs-3 col-sm-3 col-md-2" key={index}>
                      <div className="item r" data-id="item-12" data-src="">
                        <div className="item-media ">
                          <Link
                            to={`/app/urun-detay/${item.id}/${createSlug(
                              item?.name
                            )}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${item?.image?.image}')`,
                            }}
                          />
                        </div>
                        <div className="item-info">
                          <div className="item-overlay bottom text-right">
                            <LikeButton type="PRODUCTS" id={item?.id} />
                          </div>
                          <div className="item-title text-ellipsis">
                            <Link
                              to={`/app/urun-detay/${item.id}/${createSlug(
                                item?.name
                              )}`}
                            >
                              {item?.name}
                            </Link>
                          </div>
                          <div className="item-author text-sm text-ellipsis">
                            <Link
                              to={`/app/urun-detay/${item.id}/${createSlug(
                                item?.name
                              )}`}
                              className="text-muted"
                            >
                             <i className="fa fa-try"></i> {item?.price} TL
                            </Link>
                            <Link
                              to={`/app/urun-detay/${item.id}/${createSlug(
                                item?.name
                              )}`}
                              className="text-muted m-l-sm"
                            >
                             <i className="fa fa-certificate"></i> { item?.status}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Comment type="PRODUCTS" id={id} />
    </>
  );
};

export default ProductDetail;
