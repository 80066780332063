import React, { useEffect, useState } from "react";
import { del, get, post } from "../../../../utils/api";
import { COMMENT_TYPES, LIKE_TYPES } from "../../../../utils/constants";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../../../store";

const LikeButton = ({ id, type, onClick = null }) => {
  
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [hasValue, setHasValue] = useState(false);
  const [currentValue, setCurrentValue] = useState({});
  const getLike = async () => {
    if (id && type) {
      const hasValue = await get(
        LIKE_TYPES[type] +
          "/?user__id=" +
          currentUser?.id +
          "&" +
          type.toLowerCase() +
          "__id=" +
          id
      );
      if (hasValue.results.length > 0) {
        setHasValue(true);
        setCurrentValue(hasValue.results[0]);
      } else {
        setHasValue(false);
      }
    }
  };
  const postData = async () => {
    setLoading(true);
    const insertData = {
      user: currentUser?.id,
      [type?.toLowerCase()]: id,
    };

    const result = await post(LIKE_TYPES[type] + "/", insertData);
    if (result.status === 201) {
      toast.success("Beğendim");
      setHasValue(true);
    } else {
      toast.error("Hata :(");
    }
    setLoading(false);
  };
  const unLike = async () => {
    setLoading(true);
    const result = await del(LIKE_TYPES[type], currentValue.id);
    if (result.status === 204) {
      toast.success("Beğenmekten vazgeçtim");
      setHasValue(false);
    } else {
      toast.error("Hata :(");
    }
    setLoading(false);
  };
  async function handleSubmit() {
    if (hasValue) {
      await unLike();
    } else {
      await postData();
    }
    if (onClick) {
      onClick();
    }
  }
  useEffect(() => {
    getLike();
  }, [hasValue]);
  return (
    <a onClick={handleSubmit} className="btn-favorite">
      {hasValue ? (
        <i className="fa fa-heart"></i>
      ) : (
        <i className="fa fa-heart-o"></i>
      )}
    </a>
  );
};

export default LikeButton;
