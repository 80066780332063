import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import Tracks from "../../app/Tracks/Tracks";
import { useParams } from "react-router-dom";

const UserTracks = () => {
  const params = useParams();
  const [tracks, setTracks] = useState([]);
  const getTracks = async () => {
    const response = await get(
      ENDPOINTS.TRACKS + "?user__user__username=" + params.username + "&is_published=1"
    );
    setTracks(response.results);
  };
  useEffect(() => {
    getTracks();
  }, [params]);
  return (
    <>
      <div className="row item-list item-list-by m-b">
        {tracks.length > 0 ? (
          tracks.map((track, index) => (
            <Tracks key={index} track={track} />
          ))

        ):(<p className="text-muted">Hiç şarkı girilmemiş</p>)}
        
      </div>
    </>
  );
};

export default UserTracks;
