import React, { useEffect, useState } from "react";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { createSlug, momentTime } from "../../../../utils/helpers";
import Loading from "../../../shared/ui/Loading";
import { fetchCities } from "../../../../services/shareService";

const Products = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [products, setProducts] = useState([]);
  const category = queryParams.get("category");
  const city = queryParams.get("city");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const getCategories = async () => {
    const response = await get(ENDPOINTS.PRODUCT_CATEGORY);
    setCategories(response.results);
  };
  const getCities = async () => {
    const response = await fetchCities();
    setCities(response);
  };
  const getProducts = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.PRODUCTS;
      if (category) {
        url += `?category__id=${category}&is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else if (city) {
        url += `?city__id=${city}&is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else {
        url += `?is_published=1&ordering=-create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(false); // filtrenin kaldırıldığını belirtiyoruz
      }
      const response = await get(url);
      const newData = response.results;

      if (isFilterApplied) {
        setProducts(newData);
      } else if (currentPage === 1) {
        setProducts(newData);
      } else {
        setProducts((prevData) => [...prevData, ...newData]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error("Ürünler alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (currentPage !== 1) {
      getProducts();
    }
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    getProducts();
    getCategories();
    getCities();
  }, [category, city]);
  return (
    <>
      <div className="row-col">
        <div className="padding p-b-0">
          <div className="page-title m-b">
            <h1 className="inline m-a-0">Ürünler</h1>
            <div className="dropdown inline">
  <button
    className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
    data-toggle="dropdown"
  >
    Kategori
  </button>
  <div className="dropdown-menu">
    <Link className="dropdown-item active" to={"/app/urunler"}>
      Tümü
    </Link>
    {categories.map((item) => (
      <div className="dropdown-submenu" key={item.id}>
        <Link
          className={`dropdown-item ${item.children && item.children.length > 0 ? 'dropdown-toggle' : ''}`}
          to={"/app/urunler?category=" + item?.id}
        >
          {item?.name}
        </Link>
        {item.children && item.children.length > 0 && (
          <div className="dropdown-menu">
            {item.children.map((subItem) => (
              <div className="dropdown-submenu" key={subItem.id}>
                <Link
                  className={`dropdown-item ${subItem.children && subItem.children.length > 0 ? 'dropdown-toggle' : ''}`}
                  to={"/app/urunler?category=" + subItem?.id}
                >
                  {subItem?.name}
                </Link>
                {subItem.children && subItem.children.length > 0 && (
                  <div className="dropdown-menu">
                    {subItem.children.map((subSubItem) => (
                      <Link
                        className="dropdown-item"
                        key={subSubItem.id}
                        to={"/app/urunler?category=" + subSubItem?.id}
                      >
                        {subSubItem?.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
</div>
            <div className="dropdown inline">
              <button
                className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                data-toggle="dropdown"
              >
                Şehir
              </button>
              <div
                className="dropdown-menu"
                style={{ overflowY: "scroll", maxHeight: 500 }}
              >
                <Link className="dropdown-item active" to={"/app/urunler"}>
                  Tümü
                </Link>
                {cities.map((item) => (
                  <Link
                    key={item.id}
                    className="dropdown-item"
                    to={"/app/urunler?city=" + item.id}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="row row-sm item-masonry item-info-overlay">
            <div className="row">
              {products.length > 0 ? (
                products.map((product, index) => (
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3" key={index}>
                    <div className="box">
                      <div className="item">
                        <div className="item-overlay active p-a">
                          <span className="pull-right label dark-white text-color">
                            <i className="fa fa-location-arrow" />{" "}
                            {product.city?.name}, {product.town?.name}
                          </span>
                          <Link
                            className="pull-left text-u-c label label-md info m-r-xs"
                            to={`/app/urun-detay/${product.id}/${createSlug(
                              product?.name
                            )}`}
                          >
                            {product?.category?.full_category_name}
                          </Link>
                        </div>
                        <img
                          src={product?.image?.image}
                          alt="."
                          className="w-full custom-img"
                        />
                      </div>
                      <div className="p-l-1 p-r-1 p-b-1">
                        <div className="text-muted m-b-xs">
                          <span className="m-r">
                            {momentTime(product?.create_time)}
                          </span>
                          <a href="#" className="m-r">
                            <i className="fa fa-try" /> {product.price} TL
                          </a>
                          <a href="#" className="m-r">
                            <i className="fa fa-user" />{" "}
                            {product?.user?.username}
                          </a>
                        </div>
                        <div className="m-b h-1x">
                          <Link
                            to={`/app/urun-detay/${product.id}/${createSlug(
                              product?.name
                            )}`}
                            className="_800"
                          >
                            {product?.name}
                          </Link>
                        </div>
                        <p className="h-1x">{product?.short_description}</p>
                        <div>
                          <Link
                            to={`/app/urun-detay/${product.id}/${createSlug(
                              product?.name
                            )}`}
                            className="btn btn-xs white"
                          >
                            Ürünü İncele
                            <i className="fa fa-angle-right m-l-sm" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-xs-4 col-sm-4 col-md-3"> Kayıt bulunamadı.</div>
              )}
            </div>
            <div className="text-center">
              {loading ? (
                <Loading />
              ) : (
                hasMore && <a onClick={handleLoadMore}>Daha Fazla Göster</a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
