import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../store";
import capitalizeString from "../../../../utils/helpers";
import ProfileForm from "./ProfileForm";
import ProfileTracks from "./ProfileTracks";
import ProfileAnnouncement from "./ProfileAnnouncement";
import ProfileLike from "./ProfileLike";
import ProfileNews from "./ProfileNews";
import { patch, post } from "../../../../utils/api";
import { setCurrentUser } from "../../../../store/authSlice";
import { toast } from "react-hot-toast";
import { DEFAULT_AVATAR, ENDPOINTS } from "../../../../utils/constants";
import Loading from "../../../shared/ui/Loading";
import Switcher from "../../../shared/layouts/Switcher";
import ProfileProducts from "./ProfileProducts";

const Profile = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setLoading(true);
      const formData = new FormData();
      const formDataImage = new FormData();
      formDataImage.append("image", file);
      const response = await post(ENDPOINTS.IMAGES + "/", formDataImage);
      if (response.status === 201) {
        formData.append("avatar", response.data.id);
      } else {
        toast.error("Resim yüklenemedi");
        setLoading(false);
        return false;
      }

      const result = await patch("/users/" + currentUser.id + "/", formData);
      if (result.status === 200) {
        setLoading(false);
        dispatch(setCurrentUser(result.data));
        toast.success("Profil resmi güncellendi");
      } else {
        setLoading(false);
        toast.error("Profil resmi güncellenirken bir hata oluştu");
      }
    }
  };

  const tabs = useMemo(
    () => [
      {
        key: "track",
        title: "Şarkılarım",
        body: <ProfileTracks />,
      },
      {
        key: "announcement",
        title: "İlanlarım",
        body: <ProfileAnnouncement />,
      },
      {
        key: "news",
        title: "Haberlerim",
        body: <ProfileNews />,
      },
      {
        key: "products",
        title: "Ürünlerim",
        body: <ProfileProducts />,
      },
      {
        key: "like",
        title: "Beğendiklerim",
        body: <ProfileLike />,
      },
      {
        key: "profile",
        title: "Hesabım",
        body: <ProfileForm />,
      },
    ],
    []
  );

  const activeTabKey = useMemo(() => {
    return searchParams.get("tab") || tabs?.[0]?.key;
  }, [searchParams]);

  const setActiveTabKey = (tab) => {
    setSearchParams((x) => ({ ...x, tab }));
  };

  return (
    <>
      <div className="padding b-b">
        <div className="row-col">
          <div className="col-sm w w-auto-xs m-b">
            <div className="item w rounded">
              <div className="item-media">
                {loading ? (
                  <Loading />
                ) : (
                  <div
                    className="item-media-content"
                    style={{
                      backgroundImage: `url('${
                        currentUser?.avatar?.image || DEFAULT_AVATAR
                      }')`,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="p-l-md no-padding-xs">
              <h1 className="page-title">
                <span className="h1 _800">
                  {capitalizeString(currentUser?.first_name) +
                    " " +
                    capitalizeString(currentUser?.last_name)}
                </span>
              </h1>

              <p
                className="item-desc text-ellipsis text-muted"
                data-ui-toggle-class="text-ellipsis"
              >
                {currentUser?.biography}
              </p>
              <p>
                {currentUser?.instrument?.length > 0 ? (
                  <>
                    <span style={{ fontSize: 14 }}>Çaldığı enstrumanlar</span>
                    <p>
                      {currentUser?.instrument?.map((instrument) => (
                        <span className="label label-sm dark m-r-xs">
                          {instrument.name}
                        </span>
                      ))}
                    </p>
                  </>
                ) : (
                  ""
                )}
                {currentUser?.genres?.length > 0 ? (
                  <>
                    <span style={{ fontSize: 14 }}>
                      İlgilendiği müzik türleri
                    </span>
                    <p>
                      {currentUser?.genres?.map((genre) => (
                        <span className="label label-sm dark m-r-xs">
                          {genre.name}
                        </span>
                      ))}
                    </p>
                  </>
                ) : (
                  ""
                )}
                 {currentUser?.user_experience && (
                  <>
                    Deneyim: <span className="label label-md yellow m-r-xs"> {currentUser?.user_experience?.name}</span>
                  </>
                )}
              </p>
              <div className="item-action m-b">
                <label
                  htmlFor="avatar"
                  className="btn btn-sm rounded primary"
                  style={{ marginBottom: "0px" }}
                >
                  Profil Resmi Yükle
                </label>
                <input
                  id="avatar"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(event) => (event.target.value = null)}
                />

                <Link
                  to="/app/hesabim?tab=profile"
                  className="btn btn-sm rounded white"
                >
                  Profili Düzenle
                </Link>
              </div>
              <div class="item-meta m-b">
                <a class="btn btn-xs rounded white">
                  {currentUser?.town?.name + " / " + currentUser?.city?.name}
                </a>
              </div>
              <div className="block clearfix m-b">
                <div className="row-col m-b">
                  <div className="col-xs">
                    {currentUser?.facebook_url && (
                      <Link
                        target="_blank"
                        to={"https://facebook.com/" + currentUser?.facebook_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-facebook" />
                        <i className="fa fa-facebook indigo" />
                      </Link>
                    )}
                    {currentUser?.instagram_url && (
                      <Link
                        target="_blank"
                        to={
                          "https://instagram.com/" + currentUser?.instagram_url
                        }
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-instagram" />
                        <i className="fa fa-instagram black" />
                      </Link>
                    )}

                    {currentUser?.twitter_url && (
                      <Link
                        target={"blank"}
                        to={"https://twitter.com/" + currentUser?.twitter_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-twitter" />
                        <i className="fa fa-twitter light-blue" />
                      </Link>
                    )}
                    {currentUser?.youtube_url && (
                      <Link
                        target={"blank"}
                        to={"https://youtube.com/" + currentUser?.youtube_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-youtube" />
                        <i className="fa fa-youtube red" />
                      </Link>
                    )}
                    {currentUser?.spotify_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.spotify_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-spotify" />
                        <i className="fa fa-spotify green" />
                      </Link>
                    )}
                    {currentUser?.applemusic_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.applemusic_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-apple" />
                        <i className="fa fa-apple black" />
                      </Link>
                    )}
                    {currentUser?.soundcloud_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.soundcloud_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-soundcloud" />
                        <i className="fa fa-soundcloud orange" />
                      </Link>
                    )}
                    {currentUser?.website_url && (
                      <Link
                        target="_blank"
                        to={currentUser?.website_url}
                        className="btn btn-icon btn-social rounded white btn-sm"
                      >
                        <i className="fa fa-link" />
                        <i className="fa fa-link grey" />
                      </Link>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding p-y-0 m-b-md">
            <div className="nav-active-border b-primary bottom m-b-md m-t">
              <ul className="nav l-h-2x" data-ui-jp="taburl">
                {tabs.map((tab) => (
                  <li key={tab.key} className="nav-item m-r inline">
                    <span
                      className={classNames("nav-link", {
                        active: activeTabKey === tab.key,
                      })}
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveTabKey(tab.key)}
                    >
                      {tab.title}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tab-content">
              {tabs.map((tab) => (
                <div
                  key={tab.key}
                  className={classNames("tab-pane", {
                    active: activeTabKey === tab.key,
                  })}
                >
                  {tab.body}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default Profile;
