import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setCurrentUser } from "../../../../store/authSlice";
import { get, patch } from "../../../../utils/api";
import {
  fetchCities,
  fetchTownsByCity,
} from "../../../../services/shareService";
import { useFormik } from "formik";
import error from "../../../../utils/error";
import { toast } from "react-hot-toast";
import { ProfileSchema } from "../../../../validations/ProfileValidation";
import { ENDPOINTS } from "../../../../utils/constants";

const ProfileForm = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [instruments, setInstruments] = useState([]);
  const [userExperience, setUserExperience] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState(
    currentUser?.instrument ? currentUser.instrument.map(inst => inst.id) : []
  );
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState(
    currentUser?.genres ? currentUser.genres.map(genre => genre.id) : []
  );
  const formik = useFormik({
    initialValues: {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      biography: currentUser?.biography,
      city: currentUser?.city?.id,
      town: currentUser?.town?.id,
      phone: currentUser?.phone,
      facebook_url: currentUser?.facebook_url,
      twitter_url: currentUser?.twitter_url,
      instagram_url: currentUser?.instagram_url,
      youtube_url: currentUser?.youtube_url,
      website_url: currentUser?.website_url,
      spotify_url: currentUser?.spotify_url,
      applemusic_url: currentUser?.applemusic_url,
      soundcloud_url: currentUser?.soundcloud_url,
      email: currentUser?.email,
      username: currentUser?.username,
      instrument: currentUser?.instrument,
      genres: currentUser?.genres,
      user_experience: currentUser?.user_experience?.id,
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      const toastId = toast.loading("Yükleniyor...");
      // Güncellenecek veri
      const updatedData = {
        biography: values.biography,
        town: parseInt(values.town),
        city: parseInt(values.city),
        phone: values.phone,
        facebook_url: values.facebook_url,
        twitter_url: values.twitter_url,
        instagram_url: values.instagram_url,
        youtube_url: values.youtube_url,
        website_url: values.website_url,
        spotify_url: values.spotify_url,
        applemusic_url: values.applemusic_url,
        soundcloud_url: values.soundcloud_url,
        instrument: selectedInstruments.map((instrument) => parseInt(instrument)),
        genres: selectedGenres.map((genre) => parseInt(genre)),
        user_experience: parseInt(values.user_experience),
      };
      const result = await patch("/users/" + currentUser.id + "/", updatedData);
      if (result.status === 200) {
        dispatch(setCurrentUser(result.data));
        toast.success("Profiliniz güncellendi", { id: toastId });
      }
    },
  });

  const [towns, setTowns] = useState([]);
  const [cities, setCity] = useState([]);
  const getCities = async () => {
    const cities = await fetchCities();
    setCity(cities);
  };

  const getTownsByCity = async (selectedCity) => {
    if (selectedCity) {
      const _towns = await fetchTownsByCity(selectedCity);
      if (towns.length > 0) {
        formik.setFieldValue("town", "");
      }
      setTowns(_towns.results);
    }
  };
  const getExperiences = async () => {
    const data = await get(ENDPOINTS.EXPERIENCES);
    setUserExperience(data.results);
  };
  const getInstruments = async () => {
    const data = await get(ENDPOINTS.INSTRUMENTS);
    setInstruments(data.results);
  };

  const getGenres = async () => {
    const data = await get(ENDPOINTS.GENRES);
    setGenres(data.results);
  };

  // const getEquiments = async () => {
  //   const data = await get(ENDPOINTS.EQUIPMENTS);
  //   setEquipments(data.results);
  // };


  useEffect(() => {
    getCities();
    getGenres();
    getExperiences();
    getInstruments();
  }, []);

  useEffect(() => {
    if (formik.values.city) {
      getTownsByCity(formik.values.city);
    } else {
      formik.setFieldValue("town", "");
    }
  }, [formik.values.city]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Email</div>
          <div className="col-sm-9">
            <input
              type="text"
              disabled
              value={formik.values?.email}
              name="email"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">
            Kullanıcı adı
          </div>
          <div className="col-sm-9">
            <input
              type="text"
              disabled
              value={formik.values?.username}
              name="username"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Ad</div>
          <div className="col-sm-9">
            <input
              type="text"
              disabled
              value={formik.values?.first_name}
              name="first_name"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Soyad</div>
          <div className="col-sm-9">
            <input
              type="text"
              disabled
              value={formik.values?.last_name}
              name="last_name"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Hakkımda</div>
          <div className="col-sm-9">
            <textarea
              value={formik.values.biography}
              placeholder="Kendinizi kısaca tanıtın"
              name="biography"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
              style={{ height: "100px" }}
            ></textarea>
            {error("biography", formik)}
          </div>
        </div>
        
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">
            Çaldığınız Enstrümanlar
          </div>
          <div className="col-sm-9">
            <select
              name="instrument"
              value={selectedInstruments}
              onChange={(e) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                setSelectedInstruments(selectedOptions);
              }}
              multiple
              className="form-control select-multiple"
            >
              {instruments.map((instrument) => (
                <option key={instrument?.id} value={instrument?.id}>
                  {instrument?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">
            Sevdiğiniz Müzik Türleri
          </div>
          <div className="col-sm-9">
            <select
              name="genres"
              value={selectedGenres}
              onChange={(e) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                setSelectedGenres(selectedOptions);
              }}
              multiple
              className="form-control select-multiple"
            >
              {genres.map((genre) => (
                <option key={genre?.id} value={genre?.id}>
                  {genre?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">
           Deneyim, tecrübe seviyeniz
          </div>
          <div className="col-sm-9">
            <select
              name="user_experience"
              value={formik.values.user_experience}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            >
              <option value="">Deneyim Seçin</option>
              {userExperience && userExperience.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
            {error("user_experience", formik)}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Şehir</div>
          <div className="col-sm-9">
            <select
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              className="form-control"
              onBlur={formik.handleBlur}
            >
              <option value="">Şehir Seçin</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            {error("city", formik)}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">İlçe</div>
          <div className="col-sm-9">
            <select
              name="town"
              value={formik.values.town}
              onChange={formik.handleChange}
              disabled={!formik.values.city}
              className="form-control"
              onBlur={formik.handleBlur}
            >
              <option value="">İlçe Seçin</option>
              {towns.map((town) => (
                <option key={town.id} value={town.id}>
                  {town.name}
                </option>
              ))}
            </select>
            {error("town", formik)}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Telefon</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.phone}
              onBlur={formik.handleBlur}
              name="phone"
              onChange={formik.handleChange}
              className="form-control"
              placeholder="05555555555"
            />
            {error("phone", formik)}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Website</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.website_url}
              name="website_url"
              onChange={formik.handleChange}
              placeholder="http://"
              className="form-control"
              onBlur={formik.handleBlur}
            />
            {error("website_url", formik)}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Facebook</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.facebook_url}
              name="facebook_url"
              onChange={formik.handleChange}
              placeholder="@"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Twitter</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.twitter_url}
              name="twitter_url"
              onChange={formik.handleChange}
              placeholder="@"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">
            Instagram
          </div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.instagram_url}
              name="instagram_url"
              onChange={formik.handleChange}
              placeholder="@"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Youtube</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.youtube_url}
              name="youtube_url"
              onChange={formik.handleChange}
              placeholder="http://"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Spotify</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.spotify_url}
              name="spotify_url"
              onChange={formik.handleChange}
              placeholder="http://"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Apple Music</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.applemusic_url}
              name="applemusic_url"
              onChange={formik.handleChange}
              placeholder="http://"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">SoundCloud</div>
          <div className="col-sm-9">
            <input
              type="text"
              value={formik.values.soundcloud_url}
              name="soundcloud_url"
              onChange={formik.handleChange}
              placeholder="http://"
              className="form-control"
            />
          </div>
        </div>
        {/* <div className="form-group row">
          <div className="col-sm-3 form-control-label text-muted">Ekipman</div>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-12">
                <select
                  name="equipment"
                  value={selectedEquipments}
                  onChange={(e) => {
                    const selectedOptions = Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    );
                    setSelectedEquipments(selectedOptions);
                  }}
                  multiple
                  className="form-control select-multiple"
                >
                  {equipments.map((equipment) => (
                    <option key={equipment.id} value={equipment.id}>
                      {equipment.name} - {equipment.brand_name} -{" "}
                      {equipment.model}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-sm rounded primary mt-2"
                  onClick={() => setShowEquipmentForm(!showEquipmentForm)}
                >
                  {showEquipmentForm ? "Kapat" : "Yeni Ekipman Ekle"}
                </button>
              </div>
              <div class="col-12">
                {showEquipmentForm && (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="İsim"
                        value={newEquipment.name}
                        onChange={(e) =>
                          setNewEquipment({
                            ...newEquipment,
                            name: e.target.value,
                          })
                        }
                        className="form-control mb-2"
                      />
                      <input
                        type="text"
                        name="brand_name"
                        placeholder="Marka Adı"
                        value={newEquipment.brand_name}
                        onChange={(e) =>
                          setNewEquipment({
                            ...newEquipment,
                            brand_name: e.target.value,
                          })
                        }
                        className="form-control mb-2"
                      />
                      <textarea
                        name="description"
                        placeholder="Açıklama"
                        value={newEquipment.description}
                        onChange={(e) =>
                          setNewEquipment({
                            ...newEquipment,
                            description: e.target.value,
                          })
                        }
                        className="form-control mb-2"
                      />
                      <input
                        type="text"
                        name="model"
                        placeholder="Model"
                        value={newEquipment.model}
                        onChange={(e) =>
                          setNewEquipment({
                            ...newEquipment,
                            model: e.target.value,
                          })
                        }
                        className="form-control mb-2"
                      />
                      <input
                        type="file"
                        name="image"
                        onChange={(e) =>
                          setNewEquipment({
                            ...newEquipment,
                            image: e.target.files[0],
                          })
                        }
                        className="form-control mb-2"
                      />
                      <button
                        type="button"
                        onClick={handleNewEquipmentSubmit}
                        className="btn btn-sm rounded primary mt-2"
                      >
                       Yeni Ekipman Ekle
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}

        <button className="btn btn-md rounded primary" type="submit">
          Bilgileri Güncelle
        </button>
      </form>
    </>
  );
};

export default ProfileForm;
