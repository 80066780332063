import React, { useEffect, useState } from "react";
import {
  DEFAULT_AVATAR,
  DEFAULT_AVATAR2,
  DEFAULT_CHAT_AVATAR,
  ENDPOINTS,
} from "../../../../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { fetchCities } from "../../../../services/shareService";
import Seo from "../../../shared/ui/Seo";
import { get } from "../../../../utils/api";
import Loading from "../../../shared/ui/Loading";
import SendMessage from "./SendMessage";
import { current } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../../store";

const UserList = () => {
  const currentUser = useAppSelector((state) => state?.auth?.currentUser);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const experience = queryParams.get("experience");
  const city = queryParams.get("city");
  const genre = queryParams.get("genre");
  const instrument = queryParams.get("instrument");
  const [userExperience, setUserExperience] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(13);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cities, setCities] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [genres, setGenres] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const getUsers = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.USERS;
      if (experience) {
        url += `?user_experience__id=${experience}&ordering=create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else if (city) {
        url += `?city__id=${city}&ordering=create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else if (genre) {
        url += `?genres__id=${genre}&ordering=create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      }
      else if (instrument) {
        url += `?instrument__id=${instrument}&ordering=create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      }
      else {
        url += `?ordering=create_time&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`;
        setIsFilterApplied(false); // filtrenin kaldırıldığını belirtiyoruz
      }
      console.log("url", url);
      const response = await get(url);
      console.log("response", response);
      const newData = response.results;

      if (isFilterApplied) {
        setUsers(newData);
      } else if (currentPage === 1) {
        setUsers(newData);
      } else {
        setUsers((prevData) => [...prevData, ...newData]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error("Kullanıcılar alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const getUserExperience = async () => {
    const response = await get(ENDPOINTS.EXPERIENCES);
    console.log("ex", response);
    setUserExperience(response.results);
  };
  const getGenres = async () => {
    const response = await get(ENDPOINTS.GENRES);
    setGenres(response.results);
  };
  const getInstruments = async () => {
    const response = await get(ENDPOINTS.INSTRUMENTS);
    setInstruments(response.results);
  };
  
  const getCities = async () => {
    const response = await fetchCities();
    setCities(response);
  };
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (currentPage !== 1) {
      getUsers();
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getUsers();
    getUserExperience();
    getCities();
    getGenres();
    getInstruments();
  }, [experience, city,genre,instrument]);

  return (
    <>
      <Seo
        title="Müzisyenler"
        description="Aradığın müzisyene kolayca ulaşın"
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Müzisyenler - muzisyen.app"
        ogDescription="Aradığınız müzisyene kolayca ulaşın."
        twitterCard="summary_large_image"
      />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">Müzisyenler</h1>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Çaldığı enstrumanlar
                </button>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item active"
                    to={"/app/muzisyenler"}
                  >
                    Tümü
                  </Link>
                  {instruments &&
                    instruments.map((item) => (
                      <Link
                        key={item.id}
                        className="dropdown-item"
                        to={"/app/muzisyenler?instrument=" + item.id}
                      >
                        {item?.name}
                      </Link>
                    ))}
                </div>
              </div>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Sevdiği Müzik Türü
                </button>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item active"
                    to={"/app/muzisyenler"}
                  >
                    Tümü
                  </Link>
                  {genres &&
                    genres.map((item) => (
                      <Link
                        key={item.id}
                        className="dropdown-item"
                        to={"/app/muzisyenler?genre=" + item.id}
                      >
                        {item?.name}
                      </Link>
                    ))}
                </div>
              </div>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Deneyim
                </button>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item active"
                    to={"/app/muzisyenler"}
                  >
                    Tümü
                  </Link>
                  {userExperience &&
                    userExperience.map((item) => (
                      <Link
                        key={item.id}
                        className="dropdown-item"
                        to={"/app/muzisyenler?experience=" + item.id}
                      >
                        {item?.name}
                      </Link>
                    ))}
                </div>
              </div>
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Şehir
                </button>
                <div
                  className="dropdown-menu"
                  style={{ overflowY: "scroll", maxHeight: 500 }}
                >
                  <Link
                    className="dropdown-item active"
                    to={"/app/muzisyenler"}
                  >
                    Tümü
                  </Link>
                  {cities &&
                    cities.map((item) => (
                      <Link
                        key={item.id}
                        className="dropdown-item"
                        to={"/app/muzisyenler?city=" + item.id}
                      >
                        {item.name}
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="row row-lg display-flex">
              {users &&
                users
                  .filter((a) => a.username !== currentUser?.username)
                  .map(
                    (user, index) => (
                      (
                        <div
                          className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                          key={user.id}
                        >
                          <div className="box  h-100">
                            <div className="item">
                              <div className="item-bg">
                                <img
                                  src={user?.avatar?.image || DEFAULT_AVATAR}
                                  alt="."
                                  className="blur-1"
                                />
                              </div>
                              <div className="p-a-lg pos-rlt text-center">
                                <img
                                  src={user?.avatar?.image || DEFAULT_AVATAR2}
                                  alt="."
                                  className="img-circle w-56"
                                  style={{ marginBottom: "-7rem" }}
                                />
                              </div>
                            </div>
                            <div className="p-a text-center">
                              <Link
                                to={"/app/kullanici-detay/" + user?.username}
                                className="text-md m-t block"
                              >
                                {user?.username}
                              </Link>
                              <p>
                                <small className="text-ellipsis">
                                  {user?.biography || "..."}
                                </small>
                              </p>
                              <div className="text-xs">
                                <em>
                                  Çaldığı enstrumanlar:{" "}
                                  <strong>
                                    {user?.instrument
                                      ?.map((item) => item.name)
                                      .join(", ").slice(0, 30) || "Belirtilmemiş"}
                                  </strong>
                                  , İlgilendiği müzikler:{" "}
                                  <strong>
                                    {user?.genres
                                      ?.map((item) => item.name)
                                      .join(", ").slice(0, 30) || "Belirtilmemiş"}
                                  </strong>
                                </em>
                              </div>
                            </div>
                            <div class="row no-gutter b-t">
                              <div class="col-xs-6 b-r">
                                <Link
                                  to={"/app/kullanici-detay/" + user?.username}
                                  class="p-a block text-center"
                                >
                                  <i class="material-icons md-24 text-muted m-v-sm inline">
                                    account_box_outline
                                  </i>
                                  <i class="material-icons md-24 text-danger m-v-sm none">
                                    account_box
                                  </i>
                                  <span class="block">Profil</span>
                                </Link>
                              </div>

                              <div class="col-xs-6">
                                <a class="p-a block text-center">
                                  <i class="material-icons md-24 text-muted m-v-sm inline">
                                    chat_bubble_outline
                                  </i>
                                  <i class="material-icons md-24 text-danger m-v-sm none">
                                    chat_bubble
                                  </i>
                                  <span class="block">
                                    <SendMessage
                                      send={user?.username}
                                      type="basic"
                                    />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
            </div>
            <div className="text-center">
              {loading ? (
                <Loading />
              ) : (
                hasMore && <a onClick={handleLoadMore}>Daha Fazla Göster</a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
