import React from "react";

function MainSlider(){
<div
className="owl-carousel black owl-theme owl-dots-bottom-center"
data-ui-jp="owlCarousel"
data-ui-options="{items: 1,loop: true,autoplay: true,nav: true}">
<div className="row-col">
  <div className="col-lg-2" />
  <div className="col-lg-8 text-center">
    <div className="p-a-lg">
      <h2 className="display-4 m-y-lg">
        A simple, fast and responsive music template
      </h2>
      <h6 className="text-muted m-b-lg">HTML5 Music Template</h6>
      <a
        href="home.html"
        className="btn circle btn-outline b-primary m-b-lg p-x-md"
      >
        Get it now
      </a>
    </div>
  </div>
  <div className="col-lg-2" />
</div>
<div className="row-col">
  <div className="col-lg-2" />
  <div className="col-lg-8 text-center">
    <div className="p-a-lg">
      <h2 className="display-4 m-y-lg">
        Bootstrap 4 CSS framework
      </h2>
      <h6 className="text-muted m-b-lg">Responsive layout</h6>
      <a
        href="player.html"
        className="btn circle btn-outline b-primary m-b-lg p-x-md"
      >
        View App
      </a>
    </div>
  </div>
  <div className="col-lg-2" />
</div>
<div className="row-col">
  <div className="col-lg-2" />
  <div className="col-lg-8 text-center">
    <div className="p-a-lg">
      <h2 className="display-4 m-y-lg">
        Ajax powered page switch with great experience
      </h2>
      <h6 className="text-muted m-b-lg">
        No refresh page when switching pages
      </h6>
      <a
        href="rtl.html"
        className="btn circle btn-outline b-primary m-b-lg p-x-md"
      >
        Get RTL
      </a>
    </div>
  </div>
  <div className="col-lg-2" />
</div>
</div>
}
export default MainSlider;