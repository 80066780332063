import classNames from "classnames";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setTrack, toggle } from "../../../../store/playerSlice";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { useEffect, useState } from "react";
import formatTime from "../../../../utils/formatTime";
import { createSlug } from "../../../../utils/helpers";

const TracksSmall = ({ track }) => {
  const [duration, setDuration] = useState("00:00");
  const {
    isPlaying: trackIsPlaying,
    track: playerTrack,
    trackLoading,
  } = useAppSelector((state) => state.player);
  const dispatch = useAppDispatch();

  const isPlaying = trackIsPlaying && playerTrack?.id === track.id;

  const loading = trackLoading && playerTrack?.id === track.id;

  const handleClick = () => {
    dispatch(setTrack(track));
    if (playerTrack?.id === track.id) {
      dispatch(toggle());
    }
  };
  useEffect(() => {
    const audio = new Audio();
    if (track?.youtube_url === null)
      audio.src = track?.source?.audio;
    else
      audio.src= track?.youtube_url;

    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });

    return () => {
      audio.removeEventListener('loadedmetadata', () => {
        setDuration(audio.duration);
      });
    };
  }, [track?.source?.audio]);
  return (
    <>
      <div className="col-xs-12">
        <div className="item r" data-id="item-7" data-src="">
          <div className="item-media ">
            <Link
              to={`/app/sarki-detay/${track.id}/${createSlug(track?.name)}`}
              className="item-media-content"
              style={{ backgroundImage: `url('${track?.cover?.image}')` }}
            />
            <div className="item-overlay center">
              <button
                className={classNames("btn-playpause ", {
                  "is-playing": isPlaying,
                  "is-buffering": loading,
                })}
                onClick={handleClick}
              >
                Play
              </button>
            </div>
          </div>
          <div className="item-info">
            <div className="item-overlay bottom text-right">
              {/* <LikeButton type="TRACKS" id={track?.id} /> */}
            </div>
            <div className="item-title text-ellipsis">
              <Link to={`/app/sarki-detay/${track.id}/${createSlug(track?.name)}`}>{track.name}</Link>
            </div>
            <div className="item-author text-sm text-ellipsis">
              <Link
                to={`/app/kullanici-detay/${track?.user?.username}`}
                className="text-muted"
              >
                {track?.user?.username}
              </Link>
            </div>
            <div className="item-meta text-sm text-muted">
              <span className="item-meta-right">{duration !== "00:00" ? formatTime(duration) : "00:00"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TracksSmall;
