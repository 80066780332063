import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  track: false,
  isPlaying: false,
  trackLoading: false,
  isMute:true,
};

const slice = createSlice({
  name: "player",
  initialState,
  reducers: {
    play: (state, action) => {
      state.isPlaying = true;
    },
    pause: (state, action) => {
      state.isPlaying = false;
    },
    toggle: (state, action) => {
      state.isPlaying = !state.isPlaying;
    },
    toggleMute: (state, action) => {
      state.isMute = !state.isMute;
    },
    setTrack: (state, action) => {
      state.track = action.payload;
    },
    setTrackLoading: (state, action) => {
      state.trackLoading = action.payload;
    },
  },
});

export default slice.reducer;
export const { play, pause, toggle,toggleMute, setTrack, setTrackLoading} = slice.actions;
