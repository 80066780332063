import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import Loading from "../../../shared/ui/Loading";
import { createSlug } from "../../../../utils/helpers";
import Seo from "../../../shared/ui/Seo";
import { fetchCities } from "../../../../services/shareService";

const Studios = () => {
  const [studios, setStudios] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const city = queryParams.get("city");

  const getCities = async () => {
    const response = await fetchCities();
    setCities(response);
  };

  const getStudios = async () => {
    setLoading(true);
    try {
      let url = ENDPOINTS.STUDIOS;
      if (city) {
        url += `?city__id=${city}&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&is_published=1&ordering=-create_time`;
        setIsFilterApplied(true); // filtrenin uygulandığını belirtiyoruz
      } else {
        url += `?limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&is_published=1&ordering=-create_time`;
        setIsFilterApplied(false); // filtrenin kaldırıldığını belirtiyoruz
      }
      const response = await get(url);
      const newStudios = response.results;

      if (isFilterApplied) {
        setStudios(newStudios);
      } else if (currentPage === 1) {
        setStudios(newStudios);
      } else {
        setStudios((prevStudios) => [
          ...prevStudios,
          ...newStudios,
        ]);
      }
      const totalRecords = response.count;
      const loadedRecords = currentPage * itemsPerPage;
      setHasMore(totalRecords > loadedRecords);
    } catch (error) {
      console.error("Stüdyolar alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (currentPage !== 1) {
      getStudios();
    }
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    getStudios();
    getCities();
  }, [city]);
  return (
    <>
    <Seo 
        title="Prova ve kayıt stüdyoları" 
        description="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın." 
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Prova ve kayıt stüdyoları - muzisyen.app"
        ogDescription="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
        twitterCard="summary_large_image"
     />
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <div className="page-title m-b">
              <h1 className="inline m-a-0">Stüdyolar</h1>
              {/* <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Sıralama
                </button>
                <div className="dropdown-menu">
                  <a href="#" className="dropdown-item active">
                    En yeni
                  </a>
                  <a href="#" className="dropdown-item">
                    Puana Göre
                  </a>
                  <a href="#" className="dropdown-item">
                    İsme Göre
                  </a>
                </div>
              </div> */}
              <div className="dropdown inline">
                <button
                  className="btn btn-sm no-bg h4 m-y-0 v-b dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  Şehir
                </button>
                <div className="dropdown-menu" style={{overflowY:"scroll", maxHeight:500}}>
                  <Link className="dropdown-item active" to={"/app/studyolar"}>
                    Tümü
                  </Link>
                  {cities.map((item) => (
                    <Link
                      key={item.id}
                      className="dropdown-item"
                      to={"/app/studyolar?city=" + item.id}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div
              data-ui-jp="jscroll"
            >
              <div className="row row-lg">
                {studios.map((item, index) => (
                  <div className="col-xs-4 col-sm-4 col-md-3" key={item.id}>
                    <div className="item">
                      <div className="item-media rounded ">
                        <Link
                          to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
                          className="item-media-content"
                          style={{
                            backgroundImage: `url('${item?.image?.image}')`,
                          }}
                        />
                      </div>
                      <div className="item-info text-center">
                        <div className="item-title text-ellipsis">
                          <Link to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}>
                            {item.name}
                          </Link>
                          <div className="text-sm text-muted">
                            {item?.town?.name + "/" + item?.city?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center">
                  {loading ? (
                    <Loading/>
                  ) : (
                    hasMore && (
                      <a onClick={handleLoadMore}>
                        Daha Fazla Göster
                      </a>
                    )
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Studios;
