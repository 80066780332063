import axios from "axios";
import BASE_URL, { PUBLIC_KEY, SECRET_KEY } from "./constants";
import store from "../store";
import { setAccessToken } from "../store/authSlice";
import { toast } from "react-hot-toast";

const api = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: "Bearer " + store.getState().auth.accessToken?.access_token },
});

export const get = async (url, params) => {
  const response = await api.get(url, { params });
  return response?.data;
};

export const getById = async (id, url, params) => {
  const response = await api.get(url + "/" + id, { params });
  return response.data;
};

export const post = async (url, data) => {
  const response = api.post(url, data);
  return response;
};

export const getTokenApi = async (url, data) => {
  const api2 = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: PUBLIC_KEY,
      password: SECRET_KEY,
    },
  });
  const response = await api2.post(url, data).catch((error) => {
    if (error?.response?.status === 400) {
      if (error.response.data.error_description !== undefined) {
        toast.error(error.response.data.error_description, {duration: 7000, position: 'top-right'});
      }
      
      return Promise.resolve();
    }
    return Promise.reject(error);
  });
  return response;
};

// Genel PUT isteği yapmak için:
export const put = (url, data) => {
  const response = api.put(url, data);
  return response;
};

// Genel DELETE isteği yapmak için:
export const del = (url, id) => {
  const response = api.delete(url + "/" + id + "/");
  return response;
};

// Genel PATCH isteği yapmak için:
export const patch = async (url, data) => {
  const response = api.patch(url, data);
  return response;
};

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response?.status === 401) {
      const originalRequest = error.config;
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        if(store.getState().auth.accessToken?.refresh_token !== null){
          const response = await getTokenApi(`/o/token/`, `grant_type=refresh_token&refresh_token=${store.getState().auth.accessToken?.refresh_token}`);
        
          if(response !== undefined){
            store.dispatch(setAccessToken(response.data));
            return Promise.resolve();
          }
          return Promise.resolve();
        }
        else{
          store.dispatch(setAccessToken(false));
          return Promise.resolve();
        }
      } else {
        store.dispatch(setAccessToken(false));
        return Promise.resolve();
      }
      return Promise.resolve();
    }
    if (error.response?.status === 400) {
      if(error?.response?.data?.audio !== undefined){
        toast.error(error.response.data.audio[0]);
      }
      else if(error?.response?.data?.image !== undefined){
        toast.error(error.response.data.image[0]);
      }
      return Promise.resolve();
    }
    if (error.response?.status === 500) {
      toast.error("Bir hata oluştu.");
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + store.getState().auth.accessToken?.access_token;
  return config;
});
export const postContact = async (url, data) => {
  const api = axios.create({
    baseURL: BASE_URL,
    // headers: { Authorization: "Bearer i8dgRII0uCQwPPMHEVljgLwNxYqVmz" },
  });
  const response = await api.post(url, data).catch((error) => {
    if (error?.response?.status === 400) {
      toast.error("Bir hata oluştu. info@muzisyen.app adresinden ulaşabilirsiniz.");
      return Promise.resolve();
    }
    return Promise.reject(error);
  });
  return response;
};
