import DashboardSidebar from "./DashboardSidebar.js";
import DashboardShare from "./DashboardShare.js";
import DashboardSearch from "./DashboardSearch.js";
import Switcher from "./Switcher.js";
import DashboardRightSidebar from "./DashboardRightSidebar.js";
import { Link, Outlet, useLocation } from "react-router-dom";
import Player from "../../pages/app/Shared/Player.js";
import { useEffect } from "react";
import { ROUTES_URL } from "../../../utils/constants.js";
import { useDispatch } from "react-redux";
import { setType } from "../../../store/bestSidebarSlice.js";

const DashboardLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const finalPath = location.pathname.replace("/app", "").replace("/", "");
    switch (finalPath) {
      case "":
        dispatch(setType("STUDIOS"));
        break;
      case ROUTES_URL.TRACKS:
        dispatch(setType("TRACKS"));
        break;
      case "ilanlar":
        dispatch(setType("ANNOUNCEMENTS"));
        break;
      case ROUTES_URL.STUDIOS:
        dispatch(setType("STUDIOS"));
        break;
      default:
        dispatch(setType("STUDIOS"));
        break;
    }
    
  }, [location, dispatch]);
  return (
    <div>
      <div className="app dk" id="app">
        <DashboardSidebar />

        <div
          id="content"
          className="app-content white bg box-shadow-z2"
          role="main"
        >
          <div className="app-header hidden-lg-up white lt box-shadow-z1">
            <div className="navbar">
              <Link to="/app" className="navbar-brand md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width={32}
                  height={32}
                >
                  <circle cx={24} cy={24} r={24} fill="rgba(255,255,255,0.2)" />
                  <circle
                    cx={24}
                    cy={24}
                    r={22}
                    fill="#1c202b"
                    className="brand-color"
                  />
                  <circle cx={24} cy={24} r={10} fill="#ffffff" />
                  <circle
                    cx={13}
                    cy={13}
                    r={2}
                    fill="#ffffff"
                    className="brand-animate"
                  />
                  <path d="M 14 24 L 24 24 L 14 44 Z" fill="#FFFFFF" />
                  <circle cx={24} cy={24} r={3} fill="#000000" />
                </svg>
                <img src="/images/logo.png" alt="." className="hide" />
                <span className="hidden-folded inline">muzisyen.app</span>
              </Link>
              <ul className="nav navbar-nav pull-right">
                <li className="nav-item">
                  <a
                    data-toggle="modal"
                    data-target="#aside"
                    className="nav-link"
                  >
                    <i className="material-icons">menu</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <Player />

          <div className="app-body" id="view">
            <div className="page-content">
              <div className="row-col">
                <Outlet />
                <DashboardRightSidebar />
              </div>
            </div>
          </div>
        </div>
        <DashboardSearch />
        <DashboardShare />
        <Switcher />
      </div>
    </div>
  );
};
export default DashboardLayout;
