import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { get } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import LikeButton from "../../app/Shared/LikeButton";
import ActionButton from "../../app/Shared/ActionButton";
import { createSlug } from "../../../../utils/helpers";

const UserAnnouncement = () => {
  const params = useParams();
  const [announcements, setAnnouncements] = useState([]);
  const getAnnouncements = async () => {
    const response = await get(
      ENDPOINTS.ANNOUNCEMENTS + "?user__user__username=" + params.username + "&is_published=1"
    );
    setAnnouncements(response.results);
  };
  useEffect(() => {
    getAnnouncements();
  }, []);
  return (
    <>
      <div className="row m-b">
        {announcements.length > 0 ? (
 announcements.map((announcement, index) => (
  <div className="col-xs-4 col-sm-4 col-md-2" key={index}>
    <div className="item r" data-id="item-2" data-src="">
      <div className="item-media ">
        <Link
          to={`/app/ilan-detay/${announcement.id}/${createSlug(announcement?.title)}`}
          className="item-media-content"
          style={{ backgroundImage: `url('${announcement?.image?.image}')` }}
        />
      </div>
      <div className="item-info">
        <div className="item-overlay bottom text-right">
         <LikeButton type="ANNOUNCEMENT" id={announcement?.id} />
        </div>
        <div className="item-title text-ellipsis">
          <Link to={`/app/ilan-detay/${announcement.id}/${createSlug(announcement?.title)}`}>
            {announcement.title}
          </Link>
        </div>
        <div className="item-author text-sm text-ellipsis hide">
          <a href="artist.detail.html" className="text-muted">
            {announcement.title}
          </a>
        </div>
        <div className="item-meta text-sm text-muted">
          <span className="item-meta-stats text-xs ">
            <i className="fa fa-user text-muted" />{" "}
            {announcement?.user?.username}
          </span>
        </div>
        <div className="item-author text-sm text-ellipsis">
          {announcement?.short_description}
        </div>
        
      </div>
    </div>
  </div>
))
        ):(
          <p className="text-muted">Hiç ilan girilmemiş</p>
        )}
       
      </div>
    </>
  );
};

export default UserAnnouncement;
