import React, { useEffect, useState } from "react";
import Seo from "../../../shared/ui/Seo";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, getById, patch, post } from "../../../../utils/api";
import { createSlug, momentTime } from "../../../../utils/helpers";
import {
  DEFAULT_AVATAR,
  DEFAULT_AVATAR2,
  ENDPOINTS,
} from "../../../../utils/constants";
import { useAppSelector } from "../../../../store";
import { toast } from "react-hot-toast";

const QuestionDetail = () => {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { id, slug } = useParams();
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState([]);
  const [answerText, setAnswerText] = useState("");
  const getQuestion = async () => {
    const response = await getById(id, ENDPOINTS.QUESTIONS);
    const responseSlug = createSlug(response?.subject);
    if (responseSlug !== slug) {
      window.location.href = "/";
    }
    setQuestion(response);
  };
  const getAnswers = async () => {
    const response = await get(
      ENDPOINTS.ANSWERS +
        "?question__id=" +
        id +
        "&is_published=1&ordering=-create_time"
    );
    setAnswers(response?.results);
  };
  const handleAnswerTextChange = (e) => {
    setAnswerText(e.target.value);
  };
  const submitAnswer = async (e) => {
    e.preventDefault();
    if (answerText.length < 5) {
      toast.error("Cevap en az 5 karakter olmalıdır.");
      return false;
    }
    var formData = new FormData();
    formData.append("question", id);
    formData.append("answer_text", answerText);
    formData.append("user", currentUser?.id);
    formData.append("is_published", 1);
    const response = await post(ENDPOINTS.ANSWERS + "/", formData);
    if (response?.status === 201) {
      toast.success("Cevabınız başarıyla gönderildi.");
    }
    getAnswers();
    setAnswerText("");
  };
  const submitCloseQuestion = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("status", "Kapalı");
    const response = await patch(
      ENDPOINTS.QUESTIONS + "/" + id + "/",
      formData
    );
    if (response?.status === 200) {
      toast.success("Soru başarıyla kapatıldı.");
      navigate("/app/soru-cevap");
    }
  };
  const removeAnswer = async (e,id2) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("is_published", 0);
    if (id2) {
      const response = await patch(
        ENDPOINTS.ANSWERS + "/" + id2 + "/",
        formData);
      if (response?.status === 200) {
        toast.success("Cevap başarıyla silindi.");
        getAnswers();
      }
    } else {
      toast.error("Silinemedi.");
    }
  };
  useEffect(() => {
    getQuestion();
    getAnswers();
  }, [id, slug]);

  return (
    <>
      <Seo
        title="Soru Cevap aklına takılan soruyu sor"
        description="En güncel müzik ilanları burada. Siz de ilan oluşturabilir veya var olan ilanlara başvurabilirsiniz."
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Müzik İlanları - muzisyen.app"
        ogDescription="En güncel müzik ilanlarına buradan ulaşabilirsiniz."
        twitterCard="summary_large_image"
      />
      <div className="row-col">
        <div className="padding">
          <div className="page-title m-b">
            <h1 className="inline m-a-0">Cevap</h1>
          </div>
          <div className="row-col">
            <div className="col-sm-8">
              <div className="panel panel-white post panel-shadow">
                <div className="post-heading">
                  <div className="pull-left image">
                    <img
                      src={question?.user?.avatar?.image || DEFAULT_AVATAR2}
                      className="img-circle avatar"
                      alt="user profile image"
                    />
                  </div>
                  <div className="pull-left meta">
                    <div className="title h5">
                      <a href="#">
                        <b>{question?.subject}</b>
                      </a>
                    </div>
                    <h6 className="text-muted time">
                      {momentTime(question?.create_time)}
                    </h6>
                  </div>
                </div>
                <div className="post-description">
                  <p>{question?.question_text}</p>
                  <div className="stats">
                    <Link
                      to={`/app/kullanici-detay/${question?.user?.username}`}
                      className="btn btn-default stat-item"
                    >
                      <i className="fa fa-user icon" />
                      {question?.user?.username}
                    </Link>
                    <a href="#" className="btn btn-default stat-item">
                      <i className="fa fa-share icon" />
                      {question?.category?.name}
                    </a>
                    {currentUser?.id === question?.user?.id && (
                      <a
                        onClick={submitCloseQuestion}
                        className="btn btn-default stat-item"
                        style={{ color: "red" }}
                      >
                        <i className="fa fa-close icon" />
                        Kapat
                      </a>
                    )}
                  </div>
                </div>
                <div className="post-footer">
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      placeholder="Bir cevap yazın..."
                      name="answer_text"
                      onChange={handleAnswerTextChange}
                      value={answerText}
                    ></textarea>
                    <span className="input-group-addon">
                      <a onClick={submitAnswer}>
                        <i className="fa fa-edit" />
                      </a>
                    </span>
                  </div>
                  <ul className="comments-list">
                    {answers?.map((answer) => (
                      <li className="comment">
                        <Link
                          to={`/app/kullanici-detay/${answer?.user?.username}`}
                          className="pull-left"
                          href="#"
                        >
                          <img
                            className="avatar"
                            src={answer?.user?.avatar?.image || DEFAULT_AVATAR2}
                            alt="avatar"
                          />
                        </Link>
                        <div className="comment-body">
                          <div className="comment-heading">
                            <h4 className="user">
                              {" "}
                              <Link
                                to={`/app/kullanici-detay/${answer?.user?.username}`}
                              >
                                {answer?.user?.username} 
                               
                              </Link>
                              {answer?.user?.id === currentUser?.id && (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    <a onClick={(e) => removeAnswer(e,answer?.id)}>(Sil)</a>
                                  </span>
                                )}
                            </h4>
                            <h5 className="time">
                              {momentTime(answer?.create_time)}
                            </h5>
                          </div>
                          <p>{answer?.answer_text}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionDetail;

//commentlist içiçe cevap şablonu
// <ul className="comments-list">
// <li className="comment">
//   <a className="pull-left" href="#">
//     <img
//       className="avatar"
//       src="https://bootdey.com/img/Content/user_1.jpg"
//       alt="avatar"
//     />
//   </a>
//   <div className="comment-body">
//     <div className="comment-heading">
//       <h4 className="user">Gavino Free</h4>
//       <h5 className="time">5 minutes ago</h5>
//     </div>
//     <p>Sure, oooooooooooooooohhhhhhhhhhhhhhhh</p>
//   </div>
//   <ul className="comments-list">
//     <li className="comment">
//       <a className="pull-left" href="#">
//         <img
//           className="avatar"
//           src="https://bootdey.com/img/Content/user_3.jpg"
//           alt="avatar"
//         />
//       </a>
//       <div className="comment-body">
//         <div className="comment-heading">
//           <h4 className="user">Ryan Haywood</h4>
//           <h5 className="time">3 minutes ago</h5>
//         </div>
//         <p>Relax my friend</p>
//       </div>
//     </li>
//     <li className="comment">
//       <a className="pull-left" href="#">
//         <img
//           className="avatar"
//           src="https://bootdey.com/img/Content/user_2.jpg"
//           alt="avatar"
//         />
//       </a>
//       <div className="comment-body">
//         <div className="comment-heading">
//           <h4 className="user">Gavino Free</h4>
//           <h5 className="time">3 minutes ago</h5>
//         </div>
//         <p>Ok, cool.</p>
//       </div>
//     </li>
//   </ul>
// </li>
// </ul>
