import React, { useEffect, useState } from "react";
import { get } from "../../../../utils/api";
import BASE_URL, {
  COMMENT_TYPES,
  DEFAULT_AVATAR2,
} from "../../../../utils/constants";
import { Link } from "react-router-dom";
import { momentTime } from "../../../../utils/helpers";
import CommentForm from "./CommentForm";
import Loading from "../../../shared/ui/Loading";
import { useAppSelector } from "../../../../store";

const Comment = ({ id, type }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  // const { type } = useAppSelector((state) => state.comment);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const response = await get(
      COMMENT_TYPES[type] +
        "?" +
        type.toLowerCase() +
        "__id=" +
        id +
        "&is_published=1"
    );
    setDatas(response.results);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [type, id]);
  return (
    <>
      <div className="row-col">
        <div className="col-lg-9 b-r no-border-md">
          <div className="padding">
            <h5 className="m-b">Yorumlar</h5>
            {loading ? (
              <Loading />
            ) : currentUser ? (
              <div className="streamline m-b m-l">
                {datas.length === 0 ? (
                  <p>İlk yorum yazan sen ol :)</p>
                ) : (
                  datas.map((item) => (
                    <div className="sl-item" key={item.id}>
                      <div className="sl-left">
                        <img
                          src={item?.user?.avatar?.image || DEFAULT_AVATAR2}
                          alt="."
                          className="img-circle"
                        />
                      </div>
                      <div className="sl-content">
                        <div className="sl-author m-b-0">
                          <Link
                            to={`/app/kullanici-detay/${item?.user?.username}`}
                          >
                            {item?.user?.username}{" "}
                          </Link>
                          <span className="sl-date text-muted">
                            {momentTime(item.create_time)}
                          </span>
                        </div>
                        <div>
                          <p>{item.comment_text}</p>
                        </div>
                        {/* <div className="sl-footer">
              <a href="#" data-toggle="collapse" data-target="#reply-1">
                <i className="fa fa-fw fa-mail-reply text-muted" />{" "}
                Reply
              </a>
            </div> */}
                        <div className="box collapse m-a-0 b-a" id="reply-1">
                          <form>
                            <textarea
                              className="form-control no-border"
                              rows={3}
                              placeholder="Type something..."
                              defaultValue={""}
                            />
                          </form>
                          <div className="box-footer clearfix">
                            <button className="btn btn-info pull-right btn-sm">
                              Post
                            </button>
                            <ul className="nav nav-pills nav-sm">
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  <i className="fa fa-camera text-muted" />
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  <i className="fa fa-video-camera text-muted" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <p>
                Yorumları okumak için <Link to={"/giris-yap"}> giriş </Link>{" "}
                yapınız.
              </p>
            )}
            <CommentForm id={id} type={type} onClick={getData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
