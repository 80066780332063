import axios from "axios";
import { get, post, put, del } from "../utils/api";
import BASE_URL, { ENDPOINTS } from "../utils/constants";

// GET isteği örneği:
export const fetchUsers = async () => {
  const users = await get(ENDPOINTS.USERS);
  return users;
};
export const fetchUserByUsername = async (username) => {
  const users = await get(ENDPOINTS.USERS + "?user__username=" + username);
  return users;
};
export const fetchUserByEmail = async (email) => {
  const users = await get(ENDPOINTS.USERS + "?user__email=" + email);
  return users;
};
// POST isteği örneği:
export const createUser = async (data) => {
  const api = axios.create({
    baseURL: BASE_URL,
  });

  try {
    const response = await api.post(ENDPOINTS.SIGNUP + "/", data);
    const result = {
      status : "success",
      data : response?.data
    };
    return result;
  } catch (error) {
    if (error?.response?.status === 400) {
      const result = {
        error: error?.response?.data,
        status: "error"
      }
      return result;
    }
    return Promise.reject(error);
  }
};
// export const createUser = async (data) => {
//   const api = axios.create({
//     baseURL: BASE_URL,
//   });
//   api
//     .post(ENDPOINTS.SIGNUP + "/", data)
//     .then((response) => {
//       return response?.data;
//     })
//     .catch((error) => {
//       if (error?.response?.status === 400) {
//         console.log("error.response.data", JSON.stringify(error.response.data));
//         Promise.resolve();
//         return error?.response?.data;
//       }
//       return Promise.reject(error);
//     });
// };
