import React from "react";
import MainHeader from "./MainHeader";
import MainSlider from "./MainSlider";
import MainFooter from "./MainFooter";
import { Outlet } from "react-router-dom/dist";

function MainLayout() {
  return (
    <div className="app dk" id="app">
      <div id="content" className="app-content" role="main">
        <MainHeader />

        <div className="app-body">
          <MainSlider />
          <Outlet />
        </div>

        <MainFooter />
      </div>
    </div>
  );
}

export default MainLayout;
