import React from "react";
import { useAppSelector } from "../../../store";
import { Link } from "react-router-dom";

function MainHeader() {
  const { accessToken } = useAppSelector((state) => state.auth);
  return (
    <div className="app-header navbar-md black box-shadow-z1">
      <div className="navbar" data-pjax>
        <a
          data-toggle="collapse"
          data-target="#navbar"
          className="navbar-item pull-right hidden-md-up m-r-0 m-l"
        >
          <i className="material-icons">menu</i>
        </a>
        {/* brand */}
        <a href="/" className="navbar-brand md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width={32}
            height={32}
          >
            <circle cx={24} cy={24} r={24} fill="rgba(255,255,255,0.2)" />
            <circle
              cx={24}
              cy={24}
              r={22}
              fill="#1c202b"
              className="brand-color"
            />
            <circle cx={24} cy={24} r={10} fill="#ffffff" />
            <circle
              cx={13}
              cy={13}
              r={2}
              fill="#ffffff"
              className="brand-animate"
            />
            <path d="M 14 24 L 24 24 L 14 44 Z" fill="#FFFFFF" />
            <circle cx={24} cy={24} r={3} fill="#000000" />
          </svg>
          <img src="/images/logo.png" alt="." className="hide" />
          <span className="hidden-folded inline">muzisyen.app</span>
        </a>
        
       
        <div
          className="collapse navbar-toggleable-sm l-h-0 text-center"
          id="navbar"
        >
          
          {/* link and dropdown */}
          <ul className="nav navbar-nav nav-md inline text-primary-hover">
            <li className="nav-item">
              <Link to={"/"} className="nav-link">
                <span className="nav-text">Anasayfa</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/hakkimizda"} className="nav-link">
                <span className="nav-text">Hakkımızda</span>
              </Link>
            </li>
           
            <li className="nav-item">
              <Link to={"/iletisim"} className="nav-link">
                <span className="nav-text">İletişim</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/app"} className="nav-link">
                <span className="btn btn-sm rounded dark">
                <i className="fa fa-search-plus" /> 
                 {" "} Keşfet</span>
              </Link>
            </li>
          </ul>
          
          {accessToken ? (<ul className="nav navbar-nav pull-right">
            <li className="nav-item">
              <Link to={"/app/hesabim"} className="nav-link">
                Hesabım
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/app"} className="nav-link">
                <span className="btn btn-sm rounded primary _600">
                  Uygulamaya Git
                </span>
              </Link>
            </li>
          </ul>) : ( <ul className="nav navbar-nav pull-right">
            <li className="nav-item">
              <Link to={"/kayit-ol"} className="nav-link">
                Kayıt Ol
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/giris-yap"} className="nav-link">
                <span className="btn btn-sm rounded primary _600">
                  Giriş Yap
                </span>
              </Link>
            </li>
          </ul>)
          }
          {/* / link and dropdown */}
        </div>
        {/* / navbar collapse */}
      </div>
    </div>
  );
}

export default MainHeader;
