import React, { useState } from "react";
import Swal from "sweetalert2";
import { get, post } from "../../../../utils/api";
import { ENDPOINTS } from "../../../../utils/constants";
import { useAppSelector } from "../../../../store";

const SendMessage = ({ send, type = null }) => {
  const currentUser = useAppSelector((state) => state?.auth?.currentUser);
  const handleSubmit = async () => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Mesajınız",
      inputPlaceholder: "Mesajınızı giriniz...",
      inputAttributes: {
        "aria-label": "Mesajınızı giriniz",
      },
      confirmButtonText: 'Gönder <i class="fa fa-arrow-right"></i>',
      cancelButtonText: 'Vazgeç <i class="fa fa-times"></i>',
      showCancelButton: true,
    });
    if (text) {
      const user = await get(ENDPOINTS.USERS + "?user__username=" + send);
      if (user.results.count === 0) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Kullanıcı bulunamadı",
        });
        return false;
      }
      const insertData = {
        sender_user: currentUser.id,
        recipient_user: user.results[0].id,
        message: text,
        subject: "Mesaj",
      };
      const response = await post(ENDPOINTS.USER_MESSAGE + "/", insertData);
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Mesajınız gönderildi",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  return (
    <>
      <div>
        {type === "basic" ? (
          <a onClick={handleSubmit}>Mesaj Gönder</a>
        ) : (
          <button className="btn btn-sm black rounded" onClick={handleSubmit}>
            Mesaj Gönder
          </button>
        )}
      </div>
    </>
  );
};

export default SendMessage;
