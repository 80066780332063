import classNames from "classnames";
import { useEffect, useState } from "react";
import { ENDPOINTS, SIDEBAR_TYPES } from "../../../../utils/constants";
import { get } from "../../../../utils/api";
import { Link } from "react-router-dom";
import Loading from "../../../shared/ui/Loading";
import TracksSmall from "../Tracks/TracksSmall";

const BestTracks = () => {
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.TRACK_LIKE_BEST + "?limit=3");
    setTracks(response);
    setLoading(false);
  };
  useEffect(() => {
    getData();

  }, []);

  return (
    <>
      <h6 className="text text-muted">En sevilen şarkılar</h6>
      {loading ? (
        <Loading />
      ) : (
        <>
        
          <div className="row item-list item-list-sm m-b">
            {tracks?.map((track, index) => (
              <TracksSmall key={index} track={track?.tracks} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default BestTracks;
