import React, { useState } from "react";
import { RECAPTCHA_SITE_KEY } from "../../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { createUser } from "../../../services/userService";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-hot-toast";
import { get, post } from "../../../utils/api";
import Seo from "../../shared/ui/Seo";

function Signup() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_again, setPasswordAgain] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleUsernameChange = (e) => {
    const regex = /^[a-zA-Z0-9_]*$/;
    if (regex.test(e.target.value)) {
      setUsername(e.target.value);
    } else {
      toast.error("Kullanıcı adı sadece harf, rakam ve alt çizgi içerebilir", {
        duration: 3000,
      });
      return false;
    }
  };
  const handleUsernameBlur = async (e) => {
    const username = e.target.value;
    const response = await post(`users/check-username/`, {
      username: username,
    });
    if (response?.data?.available === false) {
      toast.error("Bu kullanıcı adı zaten kullanılıyor", { duration: 3000 });
      setIsUsernameValid(false);
    } else {
      setIsUsernameValid(true);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordAgainChange = (e) => {
    setPasswordAgain(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    setIsVerified(false);
    e.preventDefault();
    if (isVerified) {
      if (password !== password_again) {
        swal.fire({
          position: "center",
          icon: "error",
          title: "Şifreler eşleşmiyor",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return;
      }
      if (password.length < 6 || !/^[a-zA-Z0-9]+$/.test(password)) {
        toast.error(
          "Parolanız en az 6 karakterden ve sadece harf ve rakamlardan oluşmalıdır",
          { duration: 3000 }
        );
        setLoading(false);
        return false;
      }
      if (isUsernameValid === false) {
        toast.error("Bu kullanıcı adı zaten kullanılıyor", { duration: 3000 });
        setLoading(false);
        return false;
      }
      // ReCAPTCHA verified, continue with form submission
      const signupData = {
        first_name: firstName,
        last_name: lastName,
        username: username,
        email: email,
        password: password,
      };
      var response = await createUser(signupData);
      if (
        response.status === "error" &&
        JSON.stringify(response.error).includes("benzersiz")
      ) {
        swal.fire({
          position: "center",
          icon: "error",
          title: "Bu email sistemde kayıtlı",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        setIsVerified(true);
        return;
      } else if (
        response.status === "error" &&
        JSON.stringify(response.error).includes("password")
      ) {
        swal.fire({
          position: "center",
          icon: "error",
          title: "Parolanız en az 8 karakterden oluşmalıdır",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        setIsVerified(true);
        return;
      } else if (response.status === "success") {
        toast.success("Başarıyla kayıt oldunuz. Lütfen mail adresinizi onaylayın.", { position:"top-right", duration: 6000 });
        navigate("/giris-yap");
      }
    } else {
      toast.error("Ben robot değilim'i onaylayın");
      setIsVerified(true);
    }
    setLoading(false);
  };

  const handleRecaptchaVerify = (token) => {
    //todo: you do it make server side after
    setIsVerified(true);
  };

  const handleRecaptchaExpire = () => {
    setIsVerified(false);
  };
  return (
    (
      <>
      <Seo 
        title="Kayıt Ol" 
        description="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!" 
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Kayıt Ol - muzisyen.app"
        ogDescription="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        twitterCard="summary_large_image"
     />
        <div className="text-center m-t-lg">
          <h3>Kayıt Ol</h3>
        </div>
        <div className="b-t">
          <div className="center-block w-xxl w-auto-xs p-y-md text-center">
            <div className="p-a-md">
              {/* <div>
              <a href="#" className="btn btn-block indigo text-white m-b-sm">
                <i className="fa fa-facebook pull-left" />
                Facebook ile Kayıt Ol
              </a>
              <a href="#" className="btn btn-block red text-white">
                <i className="fa fa-google-plus pull-left" />
                Google ile Kayıt Ol
              </a>
            </div>
            <div className="m-y text-sm">VEYA</div> */}
              <form name="form" onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input
                    value={firstName}
                    onChange={handleFirstNameChange}
                    type="text"
                    className="form-control"
                    placeholder="Ad"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    value={lastName}
                    onChange={handleLastNameChange}
                    type="text"
                    className="form-control"
                    placeholder="Soyad"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    value={username}
                    onChange={handleUsernameChange}
                    onBlur={handleUsernameBlur}
                    type="text"
                    className="form-control"
                    placeholder="Kullanıcı adı"
                    required
                  />
                  {isUsernameValid !== "" &&
                    (isUsernameValid ? (
                      <i
                        className="fa fa-check errspan"
                        style={{ color: isUsernameValid ? "green" : "red" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-times errspan"
                        style={{ color: isUsernameValid ? "green" : "red" }}
                      ></i>
                    ))}
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    onChange={handleEmailChange}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                    className="form-control"
                    placeholder="Şifre"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    value={password_again}
                    onChange={handlePasswordAgainChange}
                    type="password"
                    className="form-control"
                    placeholder="Tekrar Şifre"
                    required
                  />
                </div>
                <div className="form-group">
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaVerify}
                    onExpired={handleRecaptchaExpire}
                  />
                </div>
                <div className="m-b-md text-sm">
                  <span className="text-muted">
                    Kayıt olarak şunları kabul ediyorum;
                  </span>
                  <Link to={"/kullanim-sartlari"}>Kullanım Şartları</Link>
                  <span className="text-muted"> ve </span>
                  <Link to={"/gizlilik-politikasi"}>Gizlilik Politikası</Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-lg black p-x-lg"
                  disabled={!isVerified}
                >
                  Kayıt Ol {loading && (<span>...</span>)}
                </button>
                
              </form>
              <div className="p-y-lg text-center">
                <div>
                  Zaten bir hesabın var mı? 
                  <Link to={"/giris-yap"} className="text-primary _600">
                    Giriş Yap
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Signup;
