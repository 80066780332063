import React from "react";

const YouTubeEmbed = ({ youtubeLink }) => {
  const videoId = extractVideoId(youtubeLink);

  return (
    <div className="youtube-video-container">
      <iframe
        width="250"
        height="165"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const extractVideoId = (youtubeLink) => {
  // YouTube video linkinden video ID'sini çıkartır
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([\w-]{10,})/;
  const match = youtubeLink.match(regex);
  return match ? match[1] : null;
};

export default YouTubeEmbed;