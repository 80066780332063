import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { get, post } from "../../../utils/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Seo from "../../shared/ui/Seo";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [new_password, setNewPassword] = useState("");
  const [new_password_confirm, setNewPasswordConfirm] = useState("");
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleNewPasswordConfirmChange = (event) => {
    setNewPasswordConfirm(event.target.value);
  };
  const handleSubmit = async () => {
    const reset_token = params.reset_token;

    if (reset_token && new_password === new_password_confirm) {
      const response = await post("/users/reset-password/confirm/", {
        reset_token,
        new_password,
        new_password_confirm,
      });
      if (response?.status === 200) {
        toast.success("Şifreniz başarıyla değiştirildi.");
        navigate("/giris-yap");
      } else {
        toast.error("Şifre değiştirilemedi");
      }
    } else {
      toast.error("Şifreler uyuşmuyor.");
    }
  };
  return (
    <>
    <Seo
        title="Şifreni Sıfırla" 
        description="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!" 
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Şifreni Sıfırla - muzisyen.app"
        ogDescription="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        twitterCard="summary_large_image"
     />
      <div className="text-center m-t-lg">
        <h3>Şifreni Sıfırla</h3>
      </div>
      <div className="b-t">
        <div className="center-block w-xxl w-auto-xs p-y-md text-center">
          <div className="p-a-md">
            <div className="form-group">
              <input
                id="new_password"
                type="password"
                className="form-control"
                placeholder="Yeni şifreniz"
                value={new_password}
                onChange={handleNewPasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                id="new_password_confirm"
                type="password"
                className="form-control"
                placeholder="Yeni şifreniz (tekrar)"
                value={new_password_confirm}
                onChange={handleNewPasswordConfirmChange}
                required
              />
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-lg black p-x-lg"
            >
              Gönder
            </button>
            <div className="m-y">
              <Link to={"/giris-yap"} className="_600">
                Vazgeç
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
