import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { useLocation } from "react-router-dom/dist";

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  const { accessToken, currentUser } = useAppSelector((state) => state.auth);
  if (!currentUser && !accessToken)
    return (
      <Navigate
        to="/giris-yap"
        replace={true}
        state={{ returnUrl: location.pathname + location.search }}
      />
    );

  return children;
};

export default PrivateRoute;
