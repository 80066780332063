import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { post } from "../../../../utils/api";
import { useAppSelector } from "../../../../store";
import error from "../../../../utils/error";
import { ENDPOINTS, GENRES } from "../../../../utils/constants";
import { TrackSchema } from "../../../../validations/TrackValidation";
import Seo from "../../../shared/ui/Seo";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const TrackAdd = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [genre, setGenre] = useState([]);
  const navigate = useNavigate();
  const getGenres = () => {
    setGenre(GENRES);
  };
  useEffect(() => {
    getGenres();
  }, []);
  const handleYoutubeDownloader = async (e) => {
    e.preventDefault();
    try {
      const { value: url } = await Swal.fire({
        input: "url",
        inputLabel: "Youtube'dan getir, ",
        inputPlaceholder: "Youtube linkini giriniz...",
        footer: '<span class="text-muted">ÖNEMLİ! Yeni açılacak sayfada sağ tık-->farklı kaydet yapıp bilgisayarınıza indirdikten sonra dosyanın .m4a uzantısını .mp3 olarak değiştirip buraya yükleyebilirsiniz.</span>',
        inputAttributes: {
          "aria-label": "Youtube linkini giriniz...",
        },
        confirmButtonText: 'Getir <i class="fa fa-download"></i>',
        cancelButtonText: 'Vazgeç <i class="fa fa-times"></i>',
        showCancelButton: true,
      });
      console.log("url", url);
      if (url) {
        toast.success("getiriliyor..");
        let formData = new  FormData();
        formData.append("url", url);
        const result= await post(ENDPOINTS.DOWNLOADER + "/",  formData);
        console.log("result", result);
        if (result.status === 200 && result.data.url !== null) {
          window.open(result.data.url, '_blank');
        }
      }
   
    } catch (e) {
      error(e);
    }
  }
  const formik = useFormik({
    initialValues: {
      user: currentUser?.id,
      name: "",
      genre: 0,
      description: "",
      source: "",
      cover: null,
    },
    validationSchema: TrackSchema,
    onSubmit: async (values) => {
      const toastId = toast.loading("Yükleniyor...");
      try {
        setLoading(true);
        const formData = new FormData();
        const formDataImage = new FormData();
        const formDataAudio = new FormData();
        formData.append("name", values.name);
        formData.append("genre", values.genre);
        formData.append("description", values.description);

        formData.append("user", currentUser?.id);

        formDataImage.append("image", values.cover);
        const response = await post(ENDPOINTS.IMAGES + "/", formDataImage);
        toast.success("Resim yükleniyor..", { id: toastId });
        if (response && response.status === 201) {
          formData.append("cover", response.data.id);
          toast.success("Resim başarıyla yüklendi.. Devam ediliyor..", {
            id: toastId,
          });
        } else {
          toast.error("Resim yüklenirken hata oluştu", { id: toastId });
          setLoading(false);
          return false;
        }

        formDataAudio.append("audio", values.source);
        toast.success("Şarkı yükleniyor..", { id: toastId });
        const responseAudio = await post(ENDPOINTS.AUDIOS + "/", formDataAudio);

        if (responseAudio && responseAudio.status === 201) {
          formData.append("source", responseAudio.data.id);
          toast.success("Şarkınız başarıyla yüklendi.. Devam ediliyor..", {
            id: toastId,
            duration: 5000,
          },);
        } else {
          toast.error("Şarkı yüklenirken hata oluştu", { id: toastId });
          setLoading(false);
          return false;
        }
        const result = await post(ENDPOINTS.TRACKS + "/", formData);
        if (result && result.status === 201) {
          toast.success(
            "Başarıyla eklendi, onaylandıktan sonra yayına alınacak.",
            { id: toastId }
          );
          navigate("/app/hesabim?tab=track");
        }
      } catch (e) {
        toast.error("Bir hata oluştu, bize info@muzisyen.app mail adresinden ulaşabilirsiniz.", { id: toastId });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <>
      <Seo
        title="Şarkı Ekle"
        description="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
        keywords="müzik haberleri, konserler, etkinlikler, müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Şarkı Ekle - muzisyen.app"
        ogDescription="Müzik prova ve kayıt stüdyolarını görüntüleyin, randevu oluşturun online kiralama yapın."
        twitterCard="summary_large_image"
      />
      <div className="padding p-b-0">
        <div className="page-title m-b">
          <h1 className="inline m-a-0">Şarkı Ekle</h1>
        </div>
        <div className="row row-sm item-masonry item-info-overlay">
          <div className="col-sm-9 m-b-sm">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Adı
                </div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    name="name"
                    className="form-control"
                  />
                  {error("name", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Türü
                </div>
                <div className="col-sm-9">
                  <select
                    name="genre"
                    value={formik.values.genre}
                    onChange={formik.handleChange}
                    className="form-control"
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Seçiniz</option>
                    {genre.map((item) => (
                      <option key={item?.id} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {error("genre", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Açıklama
                </div>
                <div className="col-sm-9">
                  <textarea
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    name="description"
                    className="form-control"
                  ></textarea>
                  {error("description", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Şarkını Yükle (.mp3,.wav)
                  <button onClick={handleYoutubeDownloader} className="btn btn-sm btn-danger m-l">
                    <i className="fa fa-youtube"></i>
                  </button>
                </div>
                <div className="col-sm-9">
                  <input
                    onChange={(event) => {
                      formik.setFieldValue(
                        "source",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                    name="source"
                    accept="audio/mp3, audio/wav"
                    type="file"
                    className="form-control"
                  />
                  {error("source", formik)}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 form-control-label text-muted">
                  Resim
                </div>
                <div className="col-sm-9">
                  <input
                    accept="image/*"
                    type="file"
                    name="cover"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "cover",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  {error("cover", formik)}
                </div>
              </div>

              <button
                disabled={loading}
                className="btn btn-md rounded primary"
                type="submit"
              >
                Şarkını Ekle
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackAdd;
