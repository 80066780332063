import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  TumblrShareCount,
  TelegramIcon,
  LinkedinIcon,
  TumblrShareButton,
} from "react-share";

function DashboardShare() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.origin + location.pathname);
  }, [location]);
  return (
    <div id="share-modal" className="modal fade animate">
      <div className="modal-dialog">
        <div className="modal-content fade-down">
          <div className="modal-header">
            <h5 className="modal-title">Paylaş</h5>
          </div>
          <div className="modal-body p-lg">
            <div id="share-list" className="m-b text-center">
              <div
                className="btn btn-icon btn-social rounded btn-social-colored indigo"
                title="Facebook"
              >
                <FacebookShareButton
                  url={currentUrl}
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-facebook" />
                  <i className="fa fa-facebook" />
                </FacebookShareButton>
              </div>

              <div
                className="btn btn-icon btn-social rounded btn-social-colored light-blue"
                title="Twitter"
              >
                <TwitterShareButton
                  url={currentUrl}
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-twitter" />
                  <i className="fa fa-twitter" />
                </TwitterShareButton>
              </div>

              <div
                className="btn btn-icon btn-social rounded btn-social-colorgreen green-600"
                title="WhatsApp"
              >
                <WhatsappShareButton
                  url={currentUrl}
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-whatsapp" />
                  <i className="fa fa-whatsapp" />
                </WhatsappShareButton>
              </div>

              <div
                className="btn btn-icon btn-social rounded btn-social-colored red-700"
                title="Pinterest"
              >
                <PinterestShareButton
                  url={currentUrl}
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-pinterest" />
                  <i className="fa fa-pinterest" />
                </PinterestShareButton>
              </div>
              <div
                className="btn btn-icon btn-social rounded btn-social-colored blue-grey-600"
                title="Tumblr"
              >
                <TumblrShareButton url={currentUrl} style={{ display: "flex" }}>
                  <i className="fa fa-tumblr" />
                  <i className="fa fa-tumblr" />
                </TumblrShareButton>
              </div>
            </div>
            <div>
              <input
                className="form-control"
                defaultValue={currentUrl}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DashboardShare;
