import { useEffect, useState } from "react";
import Tracks from "../Tracks/Tracks";
import { ENDPOINTS } from "../../../../utils/constants";
import { get } from "../../../../utils/api";
import { Link } from "react-router-dom";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import Loading from "../../../shared/ui/Loading";
import { createSlug } from "../../../../utils/helpers";
import Seo from "../../../shared/ui/Seo";
import { useAppSelector } from "../../../../store";

const Dashboard = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [tracks, setTracks] = useState([]);
  const [bestTracks, setBestTracks] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [lastNews, setLastNews] = useState([]);
  const [lastEvents, setLastEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.PRODUCTS + "?is_featured=1&is_published=1&ordering=-id&limit=2"
    );
    setProducts(response?.results);
    setLoading(false);
  };
  const getTracks = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.TRACKS + "?ordering=-id&is_published=1&limit=18"
    );
    setTracks(response?.results);
    setLoading(false);
  };
  const getBestTracks = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.TRACK_LIKE_BEST + "?limit=12");
    setBestTracks(response);
    setLoading(false);
  };

  const getLastNews = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.NEWS +
        "?is_featured=true&is_published=1&ordering=-create_time&limit=1"
    );
    setLastNews(response?.results[0]);
    setLoading(false);
  };
  const getAnnouncements = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.ANNOUNCEMENTS + "?ordering=-id&is_published=1&limit=8"
    );
    setAnnouncements(response?.results);
    setLoading(false);
  };
  const getLastEvents = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.EVENTS + "?is_featured=true&is_published=1&ordering=-id&limit=2"
    );
    setLastEvents(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getTracks();
    getAnnouncements();
    getLastNews();
    getProducts();
    getLastEvents();
    getBestTracks();
  }, []);

  return (
    <>
      <Seo
        title="Hoşgediniz"
        description="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="muzisyen.app"
        ogDescription="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        twitterCard="summary_large_image"
      />
      <div>
        <div className="padding p-b-0">
          <div className="page-title m-b">
            <h1 className="inline m-a-0">Keşfet</h1>
          </div>
          <div className="row row-sm item-masonry item-info-overlay">
            {loading ? (
              <Loading />
            ) : (
              <div className="col-sm-6 text-white m-b-sm">
                <div>
                  <div className="item r" data-id="item-115" data-src="">
                    <div className="item-media primary">
                      <Link
                        to={`/app/haber-detay/${lastNews?.id}/${createSlug(
                          lastNews?.title
                        )}`}
                        className="item-media-content"
                        style={{
                          backgroundImage: `url('${lastNews?.image?.image}')`,
                        }}
                      />
                    </div>
                    <div className="item-info">
                      <div className="item-overlay bottom text-right">
                        <LikeButton id={lastNews?.id} type="NEWS" />
                      </div>
                      <div className="item-title text-ellipsis">
                        <Link
                          to={`/app/haber-detay/${lastNews?.id}/${createSlug(
                            lastNews?.title
                          )}`}
                        >
                          {lastNews?.title}
                        </Link>
                      </div>
                      <div className="item-author text-sm text-ellipsis">
                        <Link
                          to={`/app/haber-detay/${lastNews?.id}/${createSlug(
                            lastNews?.title
                          )}`}
                          className="text-muted"
                        >
                          {lastNews?.short_description}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {loading ? (
              <Loading />
            ) : (
              lastEvents?.map((item) => (
                <div className="col-sm-3 col-xs-6" key={item.id}>
                  <div className="item r">
                    <div className="item-media ">
                      <Link
                        to={`/app/etkinlik-detay/${item?.id}/${createSlug(
                          item?.title
                        )}`}
                        className="item-media-content"
                        style={{
                          backgroundImage: `url('${item?.image?.image}')`,
                        }}
                      />
                    </div>
                    <div className="item-info">
                      <div className="item-title text-ellipsis">
                        <Link
                          to={`/app/etkinlik-detay/${item.id}/${createSlug(
                            item?.title
                          )}`}
                        >
                          {item?.title}
                        </Link>
                      </div>
                      <div className="item-author text-sm text-ellipsis ">
                        <Link
                          to={`/app/etkinlik-detay/${item.id}/${createSlug(
                            item?.title
                          )}`}
                          className="text-muted"
                        >
                          {item?.event_place}
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                </div>
              ))
            )}

            {loading ? (
              <Loading />
            ) : (
              products?.map((item) => (
                <div className="col-sm-3 col-xs-6" key={item.id}>
                  <div className="item r">
                    <div className="item-media ">
                      <Link
                        to={`/app/urun-detay/${item?.id}/${createSlug(
                          item?.name
                        )}`}
                        className="item-media-content"
                        style={{
                          backgroundImage: `url('${item?.image?.image}')`,
                        }}
                      />
                      <div class="item-overlay center">
                        <Link to={`/app/urun-detay/${item?.id}/${createSlug(
                          item?.name
                        )}`}>
                          <i class="fa fa-eye fa-3x"></i>
                          <div class="text-sm text-ellipsis">
                           <strong> {item?.price} TL</strong>
                          </div>

                        </Link>
                      </div>
                    </div>
                    <div className="item-info">
                    <div className="item-overlay bottom text-right">
                        <LikeButton id={item?.id} type="PRODUCTS" />
                      </div>
                      <div className="item-title text-ellipsis">
                        <Link
                          to={`/app/urun-detay/${item.id}/${createSlug(
                            item?.name
                          )}`}
                        >
                          {item?.name}
                        </Link>
                      </div>
                      <div className="item-author text-sm text-ellipsis ">
                        <Link
                          to={`/app/urun-detay/${item.id}/${createSlug(
                            item?.name
                          )}`}
                          className="text-muted"
                        >
                          {item?.short_description}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          currentUser ? (
          <div className="row-col">
            <div className="col-lg-9 b-r no-border-md">
              <div className="padding">
                <h2 className="widget-title h4 m-b">En sevilen şarkılar</h2>
                <div className="row">
                  {bestTracks?.map((track, index) => (
                    <Tracks key={index} track={track?.tracks} />
                  ))}
                </div>
                <h2 className="widget-title h4 m-b">Yeni eklenen şarkılar</h2>
                <div className="row">
                  {tracks?.map((track, index) => (
                    <Tracks key={index} track={track} />
                  ))}
                </div>
                <h2 className="widget-title h4 m-b">Yeni eklenen ilanlar</h2>
                <div className="row item-list item-list-md m-b">
                  {announcements?.map((announcement, index) => (
                    <div className="col-sm-6" key={index}>
                      <div className="item r">
                        <div className="item-media ">
                          <Link
                            to={`/app/ilan-detay/${
                              announcement.id
                            }/${createSlug(announcement?.title)}`}
                            className="item-media-content"
                            style={{
                              backgroundImage: `url('${announcement?.image?.image}')`,
                            }}
                          ></Link>
                        </div>
                        <div className="item-info">
                          <div className="item-overlay bottom text-right">
                            <LikeButton
                              id={announcement?.id}
                              type="ANNOUNCEMENT"
                            />
                          </div>
                          <div className="item-title text-ellipsis">
                            <Link
                              to={`/app/ilan-detay/${
                                announcement.id
                              }/${createSlug(announcement?.title)}`}
                            >
                              {announcement?.title}
                            </Link>
                          </div>
                          <div className="item-author text-sm text-ellipsis ">
                            <Link
                              to={`/app/ilan-detay/${
                                announcement.id
                              }/${createSlug(announcement?.title)}`}
                              className="text-muted text-ellipsis"
                            >
                              {announcement?.short_description}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>) : (
            <div className="row-col">
           <div className="padding text-center">
            <div className="col-md-12">
              <Link to={"/giris-yap"}><span class="label label-md danger pos-rlt m-r-xs"><b class="arrow left b-danger"></b>Giriş yapmadığınız için kısıtlı bir versiyonu kullanıyorsunuz. Tüm özellikleri kullanabilmek için giriş yapınız.</span></Link>
            </div></div></div>
          )
        )}
      </div>
    </>
  );
};
export default Dashboard;
