import classNames from "classnames";
import { useEffect, useState } from "react";
import { SIDEBAR_TYPES } from "../../../../utils/constants";
import { get } from "../../../../utils/api";
import { useAppSelector } from "../../../../store";
import Loading from "../../../shared/ui/Loading";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../utils/helpers";

const BestSidebar = () => {
  const { type } = useAppSelector((state) => state.bestSidebar);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const response = await get(SIDEBAR_TYPES[type] + "?ordering=-id&is_published=1&limit=3");
    setData(response);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [type]);
  const content = {
    ANNOUNCEMENTS: {
      title: "Öne Çıkan İlanlar",
      body: data?.results?.map((item) => (
        <div className="col-xs-12" key={item?.id}>
          <div
            className="item r"
            data-id="item-6"
            data-src=""
          >
            <div className="item-media ">
              <Link
                to={`/app/ilan-detay/${item.id}/${createSlug(item?.title)}`}
                className="item-media-content"
                style={{
                  backgroundImage: `url('${item?.image?.image}')`,
                }}
              />
            </div>
            <div className="item-info">
              <div className="item-title text-ellipsis">
                <Link
                  href="track.detail.html"
                  style={{ textOverflow: "ellipsis" }}
                >
                  {item?.title}
                </Link>
              </div>
              <div className="item-author text-sm text-ellipsis ">
                <Link
                  to={`/app/kullanici-detay/${item?.user?.username}`}
                  className="text-muted"
                  style={{ textOverflow: "ellipsis" }}
                >
                  {item?.user?.username}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )),
    },
    STUDIOS: {
      title: "Öne Çıkan Stüdyolar",
      body: data?.results?.map((item) => (
        <div className="col-xs-12" key={item?.id}>
          <div
            className="item r"
            data-id="item-6"
            data-src=""
          >
            <div className="item-media ">
              <Link
                to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
                className="item-media-content"
                style={{
                  backgroundImage: `url('${item?.image?.image}')`,
                }}
              />
            </div>
            <div className="item-info">
              <div className="item-title text-ellipsis">
                <Link
                  to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
                  style={{ textOverflow: "ellipsis" }}
                >
                  {item?.name}
                </Link>
              </div>
              <div className="item-author text-sm text-ellipsis ">
                <Link
                  to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
                  className="text-muted"
                  style={{ textOverflow: "ellipsis" }}
                >
                  {item?.city?.name} / {item?.town?.name}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )),
    },
  };

  const defaultContent = {
    title: "Öne Çıkan Stüdyolar",
    body: data?.results?.map((item) => (
      <div className="col-xs-12" key={item?.id}>
        <div
          className="item r"
          data-id="item-6"
        >
          <div className="item-media ">
            <Link
              to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
              className="item-media-content"
              style={{
                backgroundImage: `url('${item?.image?.image}')`,
              }}
            />
          </div>
          <div className="item-info">
            <div className="item-title text-ellipsis">
              <Link
                to={`/app/studyo-detay/${item.id}/${createSlug(item?.name)}`}
                style={{ textOverflow: "ellipsis" }}
              >
                {item?.name}
              </Link>
            </div>
            <div className="item-author text-sm text-ellipsis ">
              <Link
                to={`/app/studyo-detay/${item.id}//${createSlug(item?.name)}`}
                className="text-muted"
                style={{ textOverflow: "ellipsis" }}
              >
                {item?.city} / {item?.town}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )),
  };

  return (
    <>
      <h6 className="text text-muted">
        {content?.[type]?.title || defaultContent.title}
      </h6>
      <div className="row item-list item-list-sm m-b">
        {!loading ? (content?.[type]?.body || defaultContent.body) : <Loading />}
      </div>
    </>
  );
};

export default BestSidebar;
