import React, { useEffect, useState } from "react";
import { getToken } from "../../../services/authService";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setAccessToken, setCurrentUser } from "../../../store/authSlice";
import { useLocation } from "react-router-dom/dist";
import {
  fetchUserByEmail,
  fetchUserByUsername,
} from "../../../services/userService";
import { toast } from "react-hot-toast";
import Seo from "../../shared/ui/Seo";
import { checkInput } from "../../../utils/helpers";

function Login() {
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.auth);
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleKeyDownLogin = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Kullanıcı adı ve şifre boş bırakılamaz");
      return;
    }
    const loginType = checkInput(username);
    var token = await getToken(username, password);
    if (token) {
      var userId=0;
      dispatch(setAccessToken(token));
      if (loginType === "email") {
        const user = await fetchUserByEmail(username);
        dispatch(setCurrentUser(user.results[0]));
        userId=user.results[0].id;
      } else {
        const user = await fetchUserByUsername(username);
        dispatch(setCurrentUser(user.results[0]));
        userId=user.results[0].id;
      }
      window.OneSignal = window.OneSignal || [];

      window.OneSignal.push(function () {
        window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          if (isEnabled) {
            window.OneSignal.setExternalUserId(userId);
          } else {
            console.log("Push notifications are not enabled yet.");
          }
        });
      });

      // setAuth({auth: true, token: localStorage.getItem("access_token")});
    }
  };
  if (accessToken) {
    return (
      <Navigate to={location?.state?.returnUrl || "/app"} replace={true} />
    );
  }
  return (
    <>
      <Seo
        title="Giriş yap"
        description="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        keywords="müzik, ilanlar, enstrüman, şarkıcı, gitarist, baterist,müzisyen, garaj, grup, müzisyen arayanlar, müzisyen arıyorum, müzisyen arayanlar, müzisyen ilanları, müzisyen ilanı, müzisyen ilanı ver, müzisyen ilanı ara, müzisyen ilanı bul, müzisyen ilanı arayanlar, müzisyen ilanı arıyorum"
        ogTitle="Giriş yap - muzisyen.app"
        ogDescription="Türkiye'nin en büyük müzik platformu muzisyen.app'e sende katıl!"
        twitterCard="summary_large_image"
      />
      <div className="text-center m-t-lg">
        <h3>Kullanmaya Başla</h3>
      </div>
      <div className="b-t">
        <div className="center-block w-xxl w-auto-xs p-y-md text-center">
          <div className="p-a-md">
            {/* <div>
                <a href="#" className="btn btn-block indigo text-white m-b-sm">
                  <i className="fa fa-facebook pull-left" />
                  Facebook ile Giriş Yap
                </a>
                <a href="#" className="btn btn-block red text-white">
                  <i className="fa fa-google-plus pull-left" />
                  Google ile Giriş Yap
                </a>
              </div>
              <div className="m-y text-sm">VEYA</div> */}
            {/* <form name="form"> */}
            <div className="form-group">
              <input
                id="username"
                type="text"
                className="form-control"
                placeholder="Kullanıcı adı veya e-posta adresi"
                onKeyDown={handleKeyDownLogin}
                value={username}
                onChange={handleUsernameChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                id="passsword"
                type="password"
                className="form-control"
                placeholder="Şifre"
                onKeyDown={handleKeyDownLogin}
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="m-b-md">
              <label className="md-check">
                <input type="checkbox" />
                <i className="primary" /> Beni hatırla
              </label>
            </div>
            <button
              type="submit"
              onClick={handleLogin}
              className="btn btn-lg black p-x-lg"
            >
              Giriş Yap
            </button>
            {/* </form> */}
            <div className="m-y">
              <Link to={"/sifremi-unuttum"} className="_600">
                Şifremi Unuttum?
              </Link>
            </div>
            <div>
              Hesabınız yok mu?
              <Link to={"/kayit-ol"} className="text-primary _600">
                Kayıt Ol
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
