import PrivateRoute from "./components/shared/PrivateRoute";
import Login from "./components/pages/account/Login";
import Signup from "./components/pages/account/Signup";
import Announcement from "./components/pages/app/Announcement/Announcement";
import AnnouncementDetail from "./components/pages/app/Announcement/AnnouncementDetail";
import Dashboard from "./components/pages/app/Dashboard/Dashboard";
import StudioDetail from "./components/pages/app/Studios/StudioDetail";
import Studios from "./components/pages/app/Studios/Studios";
import TrackDetail from "./components/pages/app/Tracks/TrackDetail";
import About from "./components/pages/common/About";
import Contact from "./components/pages/common/Contact";
import Home from "./components/pages/common/Home";
import DashboardLayout from "./components/shared/layouts/DashboardLayout";
import MainLayout from "./components/shared/layouts/MainLayout";
import { ROUTES_URL } from "./utils/constants";
import Profile from "./components/pages/account/Profile/Profile";
import News from "./components/pages/app/News/News";
import NewsDetail from "./components/pages/app/News/NewsDetail";
import Events from "./components/pages/app/Events/Events";
import EventDetail from "./components/pages/app/Events/EventDetail";
import VerifyEmail from "./components/pages/account/VerifyEmail";
import ForgotPassword from "./components/pages/account/ForgotPassword";
import ResetPassword from "./components/pages/account/ResetPassword";
import AnnouncementAdd from "./components/pages/app/Announcement/AnnouncementAdd";
import TrackAdd from "./components/pages/app/Tracks/TrackAdd";
import NewsAdd from "./components/pages/app/News/NewsAdd";
import User from "./components/pages/app/Users/User";
import Messages from "./components/pages/app/Users/Messages";
import TrackList from "./components/pages/app/Tracks/TrackList";
import PrivacyPolicy from "./components/pages/common/PrivacyPolicy";
import TermsOfUse from "./components/pages/common/TermsOfUse";
import Products from "./components/pages/app/Products/Products";
import ProductAdd from "./components/pages/app/Products/ProductAdd";
import ProductDetail from "./components/pages/app/Products/ProductDetail";
import UserList from "./components/pages/app/Users/UserList";
import Questions from "./components/pages/app/QA/Questions";
import QuestionDetail from "./components/pages/app/QA/QuestionDetail";
// import Sitemap from "./Sitemap";
// import history from "./libs/history";
// import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
/** @type {import('react-router-dom/dist').RouteObject[]} */
const Routes = [
  // {
  //   path: ROUTES_URL.SITEMAP,
  //   element: <Sitemap />,
  // },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: ROUTES_URL.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: ROUTES_URL.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: ROUTES_URL.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES_URL.VERIFY_EMAIL,
        element: <VerifyEmail />,
      },
      {
        path: ROUTES_URL.SIGNUP,
        element: <Signup />,
      },
      {
        path: ROUTES_URL.ABOUT,
        element: <About />,
      },
      {
        path: ROUTES_URL.CONTACT,
        element: <Contact />,
      },
      {
        path: ROUTES_URL.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: ROUTES_URL.TERMS_OF_USE,
        element: <TermsOfUse />,
      },
    ],
  },
  {
    path: ROUTES_URL.DASHBOARD,
    element: <DashboardLayout />,
    children: [
      {
        private: false,
        path: "",
        element: <Dashboard />,
      },
      {
        private: true,
        path: ROUTES_URL.TRACKS,
        element: <TrackList />,
      },
      {
        path: ROUTES_URL.ANNOUNCEMENTS,
        element: <Announcement />,
        private: false,
      },
      {
        path: ROUTES_URL.ANNOUNCEMENT_ADD,
        element: <AnnouncementAdd />,
        private: true,
      },
      {
        path: ROUTES_URL.STUDIOS,
        element: <Studios />,
        private: false,
      },
      {
        path: ROUTES_URL.STUDIO_DETAIL,
        element: <StudioDetail />,
        private: false,
      },
      {
        path: ROUTES_URL.ANNOUNCEMENT_DETAIL,
        element: <AnnouncementDetail />,
        private: false,
      },
      {
        path: ROUTES_URL.TRACK_DETAIL,
        element: <TrackDetail />,
        private: true,
      },
      {
        path: ROUTES_URL.TRACK_ADD,
        element: <TrackAdd />,
        private: true,
      },
      {
        path: ROUTES_URL.NEWS,
        element: <News />,
        private: false,
      },
      {
        path: ROUTES_URL.NEWS_ADD,
        element: <NewsAdd />,
        private: true,
      },
      {
        path: ROUTES_URL.NEWS_DETAIL,
        element: <NewsDetail />,
        private: false,
      },
      {
        path: ROUTES_URL.PROFILE,
        element: <Profile />,
        private: true,
      },
      {
        path: ROUTES_URL.USER_DETAIL,
        element: <User />,
        private: true,
      },
      {
        path: ROUTES_URL.USER_LIST,
        element: <UserList />,
        private: true,
      },
      {
        path: ROUTES_URL.USER_MESSAGES,
        element: <Messages />,
        private: true,
      },
      {
        path: ROUTES_URL.EVENTS,
        element: <Events />,
        private: false,
      },
      {
        path: ROUTES_URL.EVENT_DETAIL,
        element: <EventDetail />,
        private: false,
      },
      {
        path: ROUTES_URL.PRODUCTS,
        element: <Products />,
        private: false,
      },
      {
        path: ROUTES_URL.PRODUCT_ADD,
        element: <ProductAdd />,
        private: true,
      },
      {
        path: ROUTES_URL.PRODUCT_DETAIL,
        element: <ProductDetail />,
        private: false,
      },
      {
        path: ROUTES_URL.QA,
        element: <Questions />,
        private: false,
      },
      {
        path: ROUTES_URL.QA_DETAIL,
        element: <QuestionDetail />,
        private: false,
      },
    ],
  },
];

const prepareRoutes = (routes) => {
  return routes.map((route) => {
    if (route?.children) {
      return {
        ...route,
        children: prepareRoutes(route.children),
      };
    }

    if (route?.private) {
      return {
        ...route,
        element: <PrivateRoute>{route.element}</PrivateRoute>,
      };
    }
    // <HistoryRouter history={history}></HistoryRouter>
    return route;
  });
};

const finalRoutes = prepareRoutes(Routes);

export default finalRoutes;
