import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  pause,
  play,
  setTrack,
  setTrackLoading,
  toggle,
  toggleMute,
} from "../../../../store/playerSlice";
import classNames from "classnames";
import formatTime from "../../../../utils/formatTime";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import LikeButton from "./LikeButton";
import { DEFAULT_AVATAR } from "../../../../utils/constants";
import { createSlug } from "../../../../utils/helpers";

const Player = () => {
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [notFormatcurrentTime, setNotFormatCurrentTime] = useState(0);
  const [notFormatDuration, setNotFormatDuration] = useState(0);
  const { isPlaying, track, isMute } = useAppSelector((state) => state.player);
  const [isRepeat, setIsRepeat] = useState(false);
  const [volume, setVolume] = useState(1); // Ses seviyesi
  const [isVolumeVisible, setIsVolumeVisible] = useState(false);
  const dispatch = useAppDispatch();
  const audioRef = useRef(new Audio());
  const handleRepeat = () => {
    setIsRepeat(!isRepeat);
  };

  useEffect(() => {
    audioRef.current.oncanplaythrough = () => {
      dispatch(setTrackLoading(false));
      dispatch(play());
      setDuration(formatTime(audioRef.current.duration));
      setNotFormatDuration(audioRef.current.duration);
    };
    audioRef.current.ontimeupdate = (e) => {
      setCurrentTime(formatTime(e.target.currentTime));
      setNotFormatCurrentTime(e.target.currentTime);
    };

    audioRef.current.onended = () => {
      if (isRepeat) {
        let playPromise = audioRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      } else {
        dispatch(pause());
        dispatch(setTrack(false));
        setDuration(formatTime(0));
        setNotFormatCurrentTime(0);
        setCurrentTime(formatTime(0));
      }
    };
  }, [dispatch, isRepeat]);

  const handleClick = () => {
    dispatch(toggle());
  };

  const handleMuteClick = () => {
    dispatch(toggleMute());
  };

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      if (!isMute) {
        audioRef.current.muted = true;
      } else {
        audioRef.current.muted = false;
      }
    }
  }, [isMute]);

  useEffect(() => {
    if (track) dispatch(pause());
    console.log(track?.youtube_url);
    if (track?.youtube_url === null || track?.youtube_url === "") audioRef.current.src = track?.source?.audio;
    else audioRef.current.src = track?.youtube_url;
    dispatch(setTrackLoading(true));
  }, [track, dispatch]);

  const seekAudio = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const clickedPercentage = x / rect.width;
    const newTime = clickedPercentage * audioRef.current.duration;

    if (Number.isFinite(newTime)) {
      audioRef.current.currentTime = newTime;
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const toggleVolumeVisibility = () => {
    setIsVolumeVisible(!isVolumeVisible);
  };

  const handleVolumeMouseDown = () => {
    setIsVolumeVisible(true);
    document.addEventListener("mousemove", handleVolumeMouseMove);
    document.addEventListener("mouseup", handleVolumeMouseUp);
  };

  const handleVolumeMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const clickedPercentage = y / rect.height;
    const newVolume = 1 - clickedPercentage;

    if (newVolume >= 0 && newVolume <= 1) {
      setVolume(newVolume);
      audioRef.current.volume = newVolume;
    }
  };

  const handleVolumeMouseUp = () => {
    setIsVolumeVisible(false);
    document.removeEventListener("mousemove", handleVolumeMouseMove);
    document.removeEventListener("mouseup", handleVolumeMouseUp);
  };
  return (
    <>
      <div className="app-footer app-player grey bg">
        <div
          className="playlist mep-tracks-count-3 has-artwork is-tracklist-closed"
          style={{ width: "100%" }}
        >
          <span className="mejs-offscreen">Audio Player</span>
          <div
            id="mep_0"
            className="mejs-container mejs-audio"
            tabIndex={0}
            role="application"
            aria-label="Audio Player"
            style={{ width: "100%", height: "40px" }}
          >
            <div className="mejs-inner">
              <div className="mejs-mediaelement">
                <audio src="" />
              </div>
              <div className="mejs-layers">
                <div
                  className="mejs-poster mejs-layer"
                  style={{ display: "none", width: "100%", height: "40px" }}
                />
                {track ? (
                  <div className="mejs-track-actions">
                    <div className="btn btn-sm no-bg btn-icon">
                      {/* <LikeButton id={track?.id} type="TRACK"/> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Link
                  className="mejs-track-artwork circle"
                  style={{
                    backgroundImage: `url(${
                      track?.cover?.image || DEFAULT_AVATAR
                    })`,
                  }}
                />
                <div className="mejs-track-details">
                  <span className="mejs-track-title">
                    <Link
                      to={`/app/sarki-detay/${track.id}/${createSlug(
                        track?.name
                      )}`}
                    >
                      {track?.name}
                    </Link>
                  </span>
                  <span className="mejs-track-author">
                    <Link to={`/app/kullanici-detay/${track?.user?.username}`}>
                      {track?.user?.username}
                    </Link>
                  </span>
                </div>
                <div className="mejs-track-source" style={{ display: "none" }}>
                  <i />
                </div>
                <div
                  className="mejs-overlay-error mejs-layer"
                  style={{ width: "100%", height: "40px" }}
                >
                  {/* <div className="mejs-error">
                Oh snap, there was a playback error!
              </div> */}
                </div>
              </div>
              <div className="mejs-controls">
                <div className="mejs-button mejs-previous-button mejs-previous">
                  <button type="button" aria-controls="mep_0" title="Önceki" />
                </div>
                <div
                  className={classNames("mejs-button mejs-playpause-button", {
                    "mejs-play": !isPlaying,
                    "mejs-pause": isPlaying,
                  })}
                >
                  <button onClick={handleClick} type="button" />
                </div>
                <div className="mejs-button mejs-next-button mejs-next">
                  <button type="button" aria-controls="mep_0" title="Sonraki" />
                </div>
                <div
                  className="mejs-time-rail"
                  style={{ width: "100%" }}
                  onClick={seekAudio}
                >
                  <span
                    className="mejs-time-total mejs-time-slider"
                    aria-label="Time Slider"
                    aria-valuemin={0}
                    aria-valuemax={notFormatDuration}
                    aria-valuenow={notFormatcurrentTime}
                    aria-valuetext={currentTime}
                    role="slider"
                    tabIndex={0}
                    style={{ width: "100%" }}
                  >
                    <span
                      className="mejs-time-buffering"
                      style={{ display: "none" }}
                    />
                    <span
                      className="mejs-time-loaded"
                      style={{
                        width: `${
                          (notFormatcurrentTime / notFormatDuration) * 100
                        }%`,
                      }}
                    />
                    <span
                      className="mejs-time-current"
                      style={{
                        width: `${
                          (notFormatcurrentTime / notFormatDuration) * 100
                        }%`,
                      }}
                    />

                    <span className="mejs-time-handle" />
                    <span
                      className="mejs-time-float"
                      style={{ display: "none" }}
                    >
                      <span className="mejs-time-float-current">
                        {currentTime}
                      </span>
                      <span className="mejs-time-float-corner" />
                    </span>
                  </span>
                </div>
                <div className="mejs-time" role="timer" aria-live="off">
                  <span className="mejs-currenttime">{currentTime}</span>
                  <span className="mejs-time-separator"> / </span>
                  <span className="mejs-duration">{duration}</span>
                </div>
                <div
                  className={classNames("mejs-button mejs-volume-button", {
                    "mejs-mute": isMute,
                    "mejs-unmute": !isMute,
                  })}
                  onMouseEnter={() => setIsVolumeVisible(true)} // Mouse üzerine gelindiğinde volume çubuğunu görünür yap
                  onMouseLeave={() => setIsVolumeVisible(false)} // Mouse çıkışında volume çubuğunu gizle
                >
                  <button
                    onClick={handleMuteClick}
                    type="button"
                    aria-controls="mep_0"
                    title="Mute"
                    aria-label="Mute"
                  />
                  <a
                    href=""
                    className={classNames("mejs-volume-slider", {
                      "volume-visible": isVolumeVisible,
                    })}
                    aria-label="volumeSlider"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    aria-valuenow={Math.round(volume * 100)}
                    aria-valuetext={`${Math.round(volume * 100)}%`}
                    role="slider"
                    tabIndex="0"
                    style={{ display: "none" }}
                  >
                    <span className="mejs-offscreen">
                      Use Up/Down Arrow keys to increase or decrease volume.
                    </span>
                    <div className="mejs-volume-total" />
                    <div
                      className="mejs-volume-current"
                      style={{
                        height: `${volume * 100}px`,
                        top: `${100 - volume * 100}px`,
                      }}
                    />
                    <div
                      className="mejs-volume-handle"
                      style={{ top: `${100 - volume * 100}px` }}
                    />
                  </a>
                </div>
                <div
                  className={classNames(
                    "mejs-button mejs-repeat-button mejs-repeat",
                    {
                      "is-repeat": isRepeat,
                      "mejs-loop-on": isRepeat, // Eğer tekrar etme aktif ise CSS class'ını değiştir (sizin CSS sınıflarınıza bağlı)
                    }
                  )}
                >
                  <button
                    onClick={handleRepeat}
                    type="button"
                    aria-controls="mep_0"
                    title="Tekrar tekrar çal"
                  />
                </div>
                <div className="mejs-button mejs-shuffle-button mejs-repeat">
                  <button
                    type="button"
                    aria-controls="mep_0"
                    title="Karıştır"
                  />
                </div>
                <div className="mejs-button mejs-toggle-playlist-button mejs-toggle-playlist is-closed">
                  <button
                    type="button"
                    aria-controls="mep_0"
                    title="Toggle Playlist"
                  />
                </div>
              </div>
              <div className="mejs-clear" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;
