import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setTrack, toggle } from "../../../../store/playerSlice";
import LikeButton from "../Shared/LikeButton";
import ActionButton from "../Shared/ActionButton";
import { createSlug, momentTime } from "../../../../utils/helpers";
import Seo from "../../../shared/ui/Seo";
import { ENDPOINTS } from "../../../../utils/constants";

const Tracks = ({ track }) => {
  const location = useLocation();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const {
    isPlaying: trackIsPlaying,
    track: playerTrack,
    trackLoading,
  } = useAppSelector((state) => state.player);
  const dispatch = useAppDispatch();

  const isPlaying = trackIsPlaying && playerTrack?.id === track.id;

  const loading = trackLoading && playerTrack?.id === track.id;

  const handleClick = () => {
    dispatch(setTrack(track));
    if (playerTrack?.id === track.id) {
      dispatch(toggle());
    }
  };

  return (
    <>
      <div className="col-xs-4 col-sm-4 col-md-2">
        <div className="item r">
          <div className="item-media ">
            <span
              className="item-media-content"
              style={{
                backgroundImage: `url('${track?.cover?.image}')`,
              }}
            ></span>
            <div className="item-overlay center">
              <button
                className={classNames("btn-playpause ", {
                  "is-playing": isPlaying,
                  "is-buffering": loading,
                })}
                onClick={handleClick}
              >
                Play
              </button>
            </div>
        </div>
          <div className="item-info">
            <div className="item-overlay bottom text-right">
              {location.pathname !== "/app/hesabim" && (
                <LikeButton type="TRACKS" id={track?.id} />
              )}
            </div>
            <div className="item-title text-ellipsis">
              <Link
                to={`/app/sarki-detay/${track.id}/${createSlug(track?.name)}`}
              >
                {track.name}
              </Link>
            </div>

            <div className="item-author text-xs text-ellipsis ">
              <Link
                to={`/app/kullanici-detay/${track?.user?.username}`}
                className="text-muted"
              >
                <i className="fa fa-user text-muted"></i>
                {" " + track?.user?.username}
              </Link>
              <span className="item-meta-date text-xs text-muted m-l-1">
                <i className="fa fa-clock-o "></i>
                {" " + momentTime(track?.create_time)}
              </span>
            </div>
            <div className="item-except visible-list text-sm text-muted h-2x m-t-sm">
              {track?.description}
              <br />
              {location.pathname === "/app/hesabim" &&
                currentUser?.id === track?.user?.id && (
                  <>
                    <div className="item-meta">
                      <span className="item-meta-stats">
                        {track?.is_published === true ? (
                          <label className="label green">
                            Yayınlanmış &nbsp;
                            <ActionButton
                              endpoint={ENDPOINTS.TRACKS}
                              id={track?.id}
                            />
                          </label>
                        ) : (
                          <label className="label danger">Yayınlanmamış</label>
                        )}
                      </span>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracks;
